export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '1200,522,1047,512,1042,518,1019,496,990,512,869,526,863,552,920,568,945,587,1045,612,1056,614,1071,616,1171,620,1175,614,1198,614'
    }
  },
  name: 'Очередь 3',
  id: 7,
  imgForModal: {
    img: '/assets/area3-2.svg',
    viewBox: {
      x: '850',
      y: '488',
      width: '350.84',
      height: '138.94',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1032.5 512.3c-.3.4-.4.7-.7 1-1.7 1.6-3.4 3.3-5 4.9-.5.4-1.1.4-1.6-.1-2.4-2.5-4.9-5.1-7.3-7.6-.3-.3-.3-1 0-1.3 1.9-1.8 3.7-3.6 5.6-5.3.2-.2.6-.2.9.1 2.8 2.7 5.4 5.5 8.1 8.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1035.1 536.2c-.1 1.9-.2 3.7-.4 5.6 0 .2-.3.4-.5.5-.1.1-.2.1-.3.1-2.4-.1-4.9-.1-7.3-.2-.5 0-.9-.4-.9-.8.1-3.5.2-7 .3-10.5 0-.9.5-1.3 1.3-1.3 2.3.1 4.6.1 7 .2.6 0 1.1.6 1.1 1.2-.1 1.8-.2 3.5-.3 5.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1049.6 542.5c.1-2 .2-4 .4-5.9 0-.3.1-.7.2-1 .1-.5.5-.8 1-.8 2.2.1 4.4.3 6.6.4 1.3.1 1.6.3 1.5 1.7-.1 1.7-.2 3.5-.3 5.2-.1.8-.1 1.6-.2 2.4-.1 3.6 0 3.3-3.3 3.1-1.7-.1-3.5-.3-5.2-.4-.5 0-.8-.4-.8-.9.1-1.2.1-2.5.1-3.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1073.4 545.6c-.1 1.9-.2 3.7-.4 5.6 0 .4-.5 1-.8 1-2.4-.1-4.9-.1-7.3-.2-.6 0-1.1-.5-1.1-1.1 0-1.4 0-2.8.1-4.2.1-2.1.3-4.2.4-6.3 0-.5.4-1 .8-.9 2.4 0 4.9.1 7.3.1.6 0 1.1.6 1.1 1.1-.1 1.7-.2 3.3-.1 4.9-.1 0-.1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1087.6 546.3c-.1 1.9-.2 3.7-.3 5.6 0 .5-.5.9-.9.9-2.5 0-4.9-.1-7.4-.1-.4 0-.8-.6-.8-1 0-1.1 0-2.1 0-3.2.1-2.2.1-4.4.2-6.7.1-1.6.3-1.8 1.9-1.8 1.4 0 2.8.1 4.2.1 3.4.1 3.3-.1 3.1 3.3 0 1 .1 2 0 2.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1102.3 547.2c0 1.2-.1 2.3-.1 3.5-.1 3.1.1 2.9-3.2 2.9-1.7 0-3.5-.1-5.2-.2-.3 0-.9-.6-.8-1 .1-3.4.2-6.7.2-10.1 0-1.3.6-1.4 1.5-1.4 2.2 0 4.4.1 6.6.1.8 0 1.2.5 1.2 1.4-.1 1.5-.1 3.1-.2 4.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1107.2 547.7c.1-1.6.1-3.3.2-4.9.1-1 .5-1.4 1.6-1.3 2 0 4 .1 6 .1 1.6.1 2 .5 1.8 1.9-.4 2.8-.4 5.6-.5 8.4 0 .6-.1 1.2-.2 1.7 0 .2-.3.5-.5.5-2.7.1-5.4.3-8-.4-.3-.1-.6-.5-.6-.7-.1-1.8 0-3.5 0-5.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1131.2 548.5v1.7c0 1-.1 2.1-.1 3.1-.1 1.3-.3 1.5-1.8 1.5-1 0-2.1 0-3.1-.1-1.2-.1-2.3-.2-3.5-.3-.4 0-.9-.6-.9-1 0-3.6.1-7.2.6-10.8.1-.4.5-.7 1-.7 2.3.1 4.6.2 7 .2.1 0 .2 0 .3.1.4 0 .8.5.8 1 0 1.9-.1 3.6-.3 5.3.1 0 .1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1145.6 548.7v3.8c0 .6-.1 1.2-.2 1.7-.1.9-.5 1.2-1.4 1.3-2.3.1-4.7-.2-7-.5-.4-.1-1-.5-1-.9.2-3.6.4-7.2.6-10.8 0-.2.6-.6.8-.6 2.4.1 4.9.1 7.3.2.6 0 1 .5 1 1.2v4.6c.1 0 0 0-.1 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1160.2 549.7c-.1 1.9-.2 3.7-.3 5.6 0 .5-.4.9-.9.9-2-.1-4 0-5.9-.2-2.5-.2-2.5-.1-2.4-2.7.1-3 .4-6 .6-9.1 0-.5.5-.9.9-.9 2.6.2 5.1.3 7.7.5.2 0 .6.5.6.8-.1 1.7-.1 3.4-.3 5.1.1 0 .1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1174.1 550.9c0 1.3-.1 2.8-.1 4.4 0 1.7-.6 1.7-1.6 1.6-.3 0-.7 0-1 0-1.9-.1-3.7-.3-5.6-.4-.7 0-1-.4-1-1.1.2-3.5.3-7 .5-10.5 0-.6.6-1 1.2-1 1 .1 2.1.2 3.1.4 1.6.3 3.3-.4 4.8.6.4 1.6-.2 3.2-.2 4.8-.1.3-.1.6-.1 1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1180.3 551.4c.1-1.6.1-3.2.3-4.8.1-1.9.4-2.1 2.4-2 1.2.1 2.3.2 3.5.3 4 .2 3.6 0 3.4 3.7-.1 2.6-.3 5.1-.5 7.7-.1 1-.5 1.4-1.6 1.3-.9 0-1.9-.1-2.8-.1-3.5-.3-4.6-.5-4.7-.8-.4-1.5-.1-3 0-4.5 0-.3 0-.5 0-.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm890.4 540.8c-.7 2.8-1.6 5.6-2.2 8.5-.5 2.6-1 2.4-3.4 1.9-1.6-.4-3.2-.8-4.7-1.2-1.1-.3-1.5-.7-1.2-1.7.8-3.2 1.6-6.3 2.4-9.5.1-.3.3-.7.5-.9.1-.2.4-.3.6-.3 2.4.6 4.7 1.2 7.1 1.8 1 .3 1 .4.9 1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm906.3 549c-.1 0-.2 0-.3 0-1.6-.4-3.2-.7-4.8-1.1-4-1.2-3.5-.2-2.5-4.3.6-2.6 1.3-5.2 2-7.8.1-.4.7-.8 1.1-.7 2.5.6 5 1.2 7.4 1.9.2.1.5.6.4.9-.6 2.5-1.2 5-1.9 7.5-.3 1.1-.6 2.2-1 3.4.1 0-.2.1-.4.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm926.5 535.8v.3c-.9 3.5-1.8 7-2.7 10.4-.2.6-.6.9-1.3.7-1.4-.3-2.7-.6-4.1-.9-.9-.2-1.8-.5-2.7-.7-.6-.2-1-.7-.9-1.3.9-3.5 1.8-7 2.7-10.4.1-.4.7-.7 1.1-.6 2.5.7 4.9 1.3 7.4 2 .2.1.4.3.5.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm930.1 538.8c.6-.6.6-1.4.8-2.2.3-1.4.5-1.5 2.1-1.4h.3.3c1.4.9 3.2.8 4.7 1.4.3.1.7.1 1 .2.4.1.8.7.8 1s-.1.7-.1 1c-.8 3-1.5 6.1-2.3 9.1-.1.2-.2.4-.3.6-.2.3-.5.5-.9.4-1.6-.4-3.2-.7-4.7-1.2-3.6-1.1-3.6-.3-2.6-4.3.3-1.4 1-2.9.9-4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm954.7 542.5c-.5 1.9-1.1 4.3-1.7 6.8-.1.6-.3 1.1-.4 1.7-.5 1.9-.8 2-2.7 1.5-1.6-.4-3.1-.8-4.7-1.2-.4-.1-.9-.3-1.3-.5-.3-.2-.5-.5-.5-.8 0-.4.1-.9.2-1.4.7-2.9 1.5-5.8 2.2-8.8.2-.8.8-1.2 1.5-1 2.2.6 4.5 1.1 6.7 1.8.6.2.9.6.7 1.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm957.2 553.2c.9-3.5 1.7-6.8 2.6-10 .1-.4.7-.9 1-.8 2.5.6 5 1.1 7.4 2 .5.2.7.6.6 1.1-.1.3-.1.7-.2 1-.6 2.3-1.2 4.5-1.8 6.8-.2.8-.4 1.6-.6 2.4-.2.6-.7.9-1.4.7-2.1-.5-4.3-1-6.4-1.6-.8-.3-1.5-.8-1.2-1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm978.5 559.8c-1-.3-2.5-.6-4-1-.3-.1-.7-.2-1-.3-2.5-.7-2.5-.7-1.9-3 .7-2.9 1.5-5.9 2.3-8.8 0-.1.1-.2.1-.3.3-.2.6-.6.8-.5 2.5.6 4.9 1.3 7.4 2 .5.1.7.6.6 1.1 0 .3-.1.7-.2 1-.2.9-.5 1.8-.7 2.7-.5 2-1 4.1-1.6 6.1-.1.8-.6 1.2-1.8 1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm988.8 549.5h.4c2.4.6 4.7 1.2 7.1 1.9.7.2.9.6.8 1.3-.8 3.4-1.7 6.8-2.5 10.2-.1.4-.7.8-1.1.7-1.8-.4-3.6-.9-5.4-1.3-.7-.2-1.3-.3-2-.5-.2-.1-.3-.3-.4-.5s-.1-.5 0-.7c.8-3.4 1.7-6.8 2.6-10.2-.1-.4.2-.6.5-.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1010.9 556.6c-.1.2-.2.9-.4 1.6-.6 2.4-1.2 4.7-1.8 7.1-.1.4-.3.9-.5 1.3-.2.3-.5.5-.8.5-2.5-.4-5-1.2-7.4-1.8-.2-.1-.4-.5-.5-.8-.1-.2 0-.5.1-.7.8-3.3 1.6-6.5 2.5-9.8.2-.6.6-.9 1.3-.7 2.2.6 4.5 1.1 6.8 1.7.6.1.9.5.7 1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1024.8 559.1c0 .1.1.2 0 .3-.9 3.5-1.8 7-2.7 10.4-.1.5-.6.8-1 .7-1.7-.4-3.4-.8-5.1-1.2-.8-.2-1.6-.4-2.3-.7-.2-.1-.5-.6-.5-.8.4-2 .8-4.1 1.5-6.1.6-1.7.5-3.7 1.7-5.2 1.7.4 3.4.7 5.1 1.1 1 .2 2 .5 3 .9.1.2.2.5.3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1032.4 573.6c-1.5-.4-2.9-.8-4.4-1.2-.5-.1-.8-.6-.7-1 .9-3.5 1.8-7 2.7-10.4.1-.3.7-.7 1.1-.6 2.5.6 4.9 1.2 7.4 1.9.2.1.5.6.5.8-.1.9-.4 1.8-.6 2.7-.6 2.4-1.2 4.7-1.8 7.1-.1.4-.3.9-.6 1.3-.1.2-.4.3-.6.3-1-.4-2-.6-3-.9-.1 0 0 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1035.3 595.1c.5-1.9 1-3.8 1.6-5.7.2-.7.6-.9 1.3-.8h.3c2.2.6 4.5 1.1 6.7 1.7.4.1.7.7.6 1.1-.9 3.5-1.8 7-2.7 10.4-.1.5-.7.8-1.1.7-2.5-.7-4.9-1.3-7.4-2-.4-.1-.7-.6-.5-1.1.4-1.4.8-2.9 1.2-4.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1060.1 595.3c-.3 1.4-.6 3.6-1 5.8-.1.7-.3 1.4-.4 2-.5 2.8-.5 2.9-3.2 2.4-1.7-.3-3.4-.6-5.1-.9-.7-.1-1.1-.7-1-1.5.4-2.4.8-4.8 1.3-7.2.2-1 .4-2.1.6-3.1.1-.3.5-.5.8-.6s.7-.1 1 0c2.2.4 4.3.9 6.5 1.3.4.1.6.5.5 1.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1066.6 574.6c.2-2 .3-4 .5-5.9.1-.6.6-1 1.2-1 2.4.2 4.9.4 7.3.6.2 0 .3.3.5.5.1.1.1.2.1.3 0 3.4 0 6.8-.5 10.1 0 .2-.1.5-.1.7-.1.4-.5.8-1 .8-2.4-.2-4.9-.3-7.3-.5-.4 0-.7-.5-.7-1-.1-1.5-.1-3 0-4.6-.1 0-.1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1090.5 574.8c-.1 1.9-.1 3.7-.2 5.6 0 .6-.5 1-1.2 1-.5 0-.9 0-1.4-.1-1.7-.3-3.5-.4-5.2-.4-1.2 0-1.4-.3-1.4-1.5.1-3.4.3-6.8.5-10.2 0-.6.6-1 1.2-1 2.3.2 4.7.3 7 .5.7.1 1.1.4 1.1 1.1-.2 1.8-.3 3.4-.4 5-.1 0 0 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1105 575.2c-.1 1.6-.3 3.8-.5 6 0 .4-.6.8-1 .8-2.5-.2-4.9-.3-7.4-.5-.4 0-.8-.5-.8-1 .2-3.5.4-7 .6-10.5 0-.5.4-1 .8-1 2.6-.1 5.1 0 7.7.5.4.1.6.5.6.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1110 576.2c0-1.5 0-3 0-4.6 0-.3.1-.7.1-1 .1-.4.6-.8 1-.8 2.5-.1 4.9 0 7.3.5.5.1.8.5.8 1-.1 3.5-.2 7-.4 10.5 0 .3-.3.7-.7.7-2.7 0-5.4-.1-8-.2-.2 0-.6-.6-.5-.8.1-1.8.2-3.6.4-5.3-.1 0 0 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1124.4 577.1c.1-1.3.2-2.2.2-3.2.2-4.1-.4-3.6 3.9-3.4 1.5 0 3 .2 4.5.3.3 0 .7.2.7.6.1 3.6.1 7.2-.4 10.8 0 .2-.1.5-.2.7s-.3.4-.5.4c-.3.1-.7 0-1 0-1.5-.1-3-.2-4.5-.2-3 0-3-.1-2.8-2.9-.1-1.2 0-2.3.1-3.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1138.8 577.4c0-1.6 0-3.3 0-4.9 0-1.1.3-1.4 1.5-1.4 2.1 0 4.2.1 6.3.2 1.2.1 1.5.6 1.5 1.5 0 .8 0 1.6-.1 2.4-.1 2.6-.2 5.1-.3 7.7 0 .5-.5.9-.9.9-2.6-.1-5.1-.1-7.7-.2-.4 0-.8-.6-.8-1 .1-1 .2-2.1.2-3.1 0-.7.1-1.4.1-2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1153.4 578c.1-1.7.1-3.5.2-5.2 0-.6.4-1 1.1-1h.3c1.3 0 2.6 0 3.8.1 4.2.2 4-.3 3.8 4.1-.1 2.2-.1 4.4-.2 6.6 0 .5-.1.9-.2 1.3-.1.2-.3.4-.5.4-.8.1-1.6.1-2.4.1-2-.1-3.9.2-5.9-.3-.9-1.2-.3-2.5-.3-3.7 0-.8.2-1.6.3-2.4-.1 0 0 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1167.9 578.7c.1-1.7.2-3.5.3-5.2 0-.7.4-1 1.1-1 2.3 0 4.7.1 7 .2.6 0 1.1.5 1.1 1.1 0 1.6-.1 3.3-.1 4.9-.1 1.6-.2 3.3-.3 4.9-.1 1.4-.1 1.8-1.9 1.7-1.6-.1-3.3-.1-4.9-.1-2.6-.1-2.6-.1-2.5-2.9 0-.1 0-.2 0-.3 0-1.2.1-2.3.2-3.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1191.4 573.4c-.1 3.6-.1 7.2-.2 10.8 0 .6-.3 1.1-.4 1.7-2.3 0-4.6-.1-7-.1-.3 0-.7-.1-1-.1-.4-.1-.9-.6-.9-.9 0-.5 0-.9 0-1.4.1-1.5.2-3 .2-4.5.1-1.3.1-2.5.2-3.8.1-1.7.2-1.8 2.1-1.8 1.3 0 2.6 0 3.8.1 1.1-.2 2.1-.1 3.2 0z',
      },
    },
  ],
}
