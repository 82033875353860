<template>
  <div class="outer" id="cabins">
    <div class="right">
        <h1 data-aos="fade-up">Коттеджи</h1>
    </div>
    <div class="cabins">
      <!-- sold -->
      <template v-for="(cabin, index) in cabins" v-bind:key="index" >
        <div v-if="cabin.type != 'sold'" class="cabin" data-aos="zoom-in" @click="openCabin(cabin)">
          <div class="cabin-body">
            <div class="img-wrapper">
              <div class="img" v-bind:style="{ 'background-image': 'url(/assets/resized/cabins/' + cabin.img[0] + ')' }"></div>
            </div>
            <h5>{{cabin.label}}</h5>
            <ul>
              <li v-for="(item, i) in cabin.info" :key="i" v-html="item"></li>
            </ul>
          </div>
        </div>
      </template>
      
    </div>
    <CabinModal :cabin="cabin" @close="closeCabin"/>
  </div>
</template>

<script>
import CabinModal from './CabinModal.vue';
import cabinData from "../../public/assets/js/cabinData";

export default {
    name: 'Cabins',
    components: {
      CabinModal,
    },
    methods: {
      openCabin(cabin) {
        this.cabin = cabin;
      },
      closeCabin() {
        this.cabin = null;
      }
    },
    data () {
      return {
        cabin: null,
        cabins: cabinData,
      }
    }
}
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .outer {
    background: rgba(14, 10, 6, 0.99);
    padding: 80px 0;

    .right {
      display: flex;
      max-width: 1312px;
      margin: auto;

      h1 {
        font-size: 120px;
        margin: 0 0 80px 0;
        word-break: break-word;
        color: white;
      }

      @media only screen and (max-width: 1312px) {
        h1 {
          padding: 0 20px;
        }
      }

      @media only screen and (max-width: 900px) {
        h1 {
          font-size: 80px !important;
          margin: 0 0 60px 0;
        }
      }

      @media only screen and (max-width: 650px) {
        h1 {
          font-size: 45px !important;
          margin: 0 0 20px 0;
        }
      }
    }
  }

  

  @media only screen and (max-width: 840px) {
    .outer {
      padding: 0 0 0 0 !important;
    }
  }

  .cabins {
    display: grid;
    grid-gap: 24px;
    max-width: 1312px;
    margin: auto;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .cabin {
    width: 100%;
    // overflow: hidden;

    .cabin-body {
      position: relative;
      transition: transform ease-in-out .2s;
      background: white;
      overflow: hidden;
    }

    .img-wrapper {
      background: white;
      box-sizing: border-box;
      overflow: auto;
      max-width: 100%;
    }

    .img {
      width: 100%;
      padding-bottom: 56.25%;
      background-size: cover;
    }

    h5 {
      color: #128737;
      font-size: 22px;
      font-weight: 500;
      margin: 24px 24px 0 24px;
      min-height: 54px;
    }

    ul {
      padding: 24px;
    }
  }

  .cabin-body:hover {
    transform: scale(1.05);
  }
</style>
