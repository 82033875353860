<template>
    <div class="fixed-background"></div>
</template>

<script>
export default {
    name: 'YoullLikeIt',
}
</script>

<style lang="scss" scoped>
    .outer {
        display: block;
        .inner {
            max-width: 1312px;
            margin: auto;
            display: block;

            h1 {
                position: relative;
                top: -65px;
                color: white;
                float: left;
                font-size: 120px;
                line-height: 1;
                font-weight: 500;
                padding: 0;
                margin: 0;
            }
        }
    }
    .fixed-background {
        background-attachment: fixed;
        background-image: url("./../../public/assets/youll-like-it.jpg");
        background-position-x: 50%;
        background-position-y: 50%;
        background-size: cover;
        box-sizing: border-box;
        color: rgb(0, 0, 0);
        height: 480px;
        width: 100%;
    }

    @media only screen and (max-width: 800px) {
        .fixed-background {
            display: none !important;
        }
    }
    
    @media only screen and (max-width: 1000px) {
        h1 {
            font-size: 100px !important;
        }
    }
    
    @media only screen and (max-width: 650px) {
        h1 {
            font-size: 60px !important;
            top: -35px !important;
        }
    }
</style>