<template>
    <div class="popup-overlay" @click="TogglePopup()">
        <div class="popup-inner">
            <slot />
        </div>
    </div>
    <div class="close-button" @click="TogglePopup()">
        <div class="child"> </div>
    </div>
</template>

<script>
    export default {
        props: ['TogglePopup']
    }
</script>

<style lang="scss" scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.8);
    
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-inner {
        background: transparent;
    }
}
.close-button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 18px;
    color: white;

    .child {
        background-color: white;
        -webkit-mask: url('./../../public/assets/close.svg') no-repeat center;
        mask: url('./../../public/assets/close.svg') no-repeat center;
        -webkit-mask-size: cover;
        mask-size: cover;
        height: 30px;
        width: 30px;
    }

    .child::after {
        content: " ";
    }
}

// body, html {
//     overflow: hidden !important;
// }
</style>
