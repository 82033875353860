<template>
  <div class="outer">
    <div class="inner">
      <yandex-map 
        :coords="coords"
        :settings="settings"
        :zoom="zoom" 
        :behaviors="behaviors"
        :controls="controls"
        id="yandex-map"
        :scroll-zoom="false"
      >
        <ymap-marker v-for="(marker, i) in markers"
          :key="i"
          :coords="marker.coords"
          :marker-id="i" 
          :hint-content="marker.name" 
          :icon="markerIcon"
        />
          
        <ymap-marker 
          :coords="coords" 
          marker-id="1000" 
          hint-content="п. Озёрный" 
          :icon="ozerniyIcon"
        />

        <ymap-marker 
          :coords="[ozerniyCoords, []]" 
          marker-id="999" 
          hint-content="some hint 2" 
          marker-type="polygon"
          :marker-fill="{color: '#d2b28144'}"
          :marker-stroke="{color: '#d2b281', width: 2}"
        />
      </yandex-map>
    </div>
  </div>
</template>

<script>
import {
    yandexMap,
    ymapMarker,
} from 'vue-yandex-maps'

export default {
name: 'YandexMappp',
  components: {
    yandexMap,
    ymapMarker
  },
  data: () => ({
    settings: {
        apiKey: 'e08efe1e-56a9-42a7-9df9-7390c0060675',
        lang: 'ru_RU',
        enterprise: false,
        version: '2.1',
    },
    coords: [
        44.987909,
        34.039075,
    ],
    markers: [
      {name: 'ТЦ Южная галерея', coords: [44.986942, 34.086259]},
      {name: 'ТЦ Меганом', coords: [44.970013, 34.076857]},
      {name: 'Продуктовый супермаркет METRO', coords: [44.993028, 34.057299]},
      {name: 'Супермаркет Яблоко', coords: [45.008918, 34.026650]},
      {name: 'Супермаркет доброцен', coords: [44.992119, 34.089035]},
      {name: 'Супермаркет ПУД', coords: [45.015638, 34.022386]},
      {name: 'Продуктовый гипермаркет Ашан', coords: [44.985360, 34.086682]},
      {name: 'Поликлиника №5', coords: [45.010232, 34.024395]},
      {name: 'Больница им. Н. А. Семашко, многопрофильный республиканский медицинский центр', coords: [45.030239, 34.050354]},
      {name: 'Центр клинической онкологии и гематологии', coords: [44.979584, 34.071177]},
      {name: 'Школа №34', coords: [45.007178, 34.027075]},
      {name: 'МБОУ Молодежненская школа № 2', coords: [45.008340, 34.051444]},
      {name: 'Мирновская школа № 2', coords: [44.988637, 34.062116]},
      {name: 'МБОУ Средняя общеобразовательная школа № 22 МО ГО Симферополь и Республики Крым', coords: [45.014884, 34.058383]},
      {name: 'МКОУ Брединская ООШ №', coords: [44.980064, 34.062689]},
      {name: 'МБОУ Мирновская школа № 1', coords: [44.972403, 34.058131]},
      {name: 'МБОУ Лицей Симферопольского района', coords: [44.972305, 34.073275]},
      {name: 'Детский сад Золотой Ключик С. Мирное', coords: [44.972816, 34.059479]},
      {name: 'Детский сад Солнышко С. Мирное', coords: [44.989333, 34.063199]},
      {name: 'Детский сад Лесная Сказка пгт. Молодежное', coords: [45.005895, 34.054487]},
      {name: 'Ясли-сад № 8 Василек', coords: [45.013478, 34.057275]},
      {name: 'Детский сад Ляле пгт. Молодежное', coords: [45.010090, 34.067494]},
      {name: 'Детский сад Общеразвивающего Вида № 28 Лужок', coords: [44.992272, 34.095141]},
      {name: 'Детский сад', coords: [44.974869, 34.072109]},
      {name: 'Детский сад всезнайка', coords: [44.976525, 34.079609]},
      {name: 'Планета Детства Центр развития ребёнка детский сад', coords: [44.972057, 34.074755]},

    ],
    ozerniyCoords: [
      [44.99291065505027, 34.04070683775295],
      [44.99204277267958, 34.039236987212774],
      [44.992088451028934, 34.03745600042689],
      [44.99189812433145, 34.03739162741055],
      [44.9921569685038, 34.03438755331386],
      [44.991669731474055, 34.034065688232076],
      [44.99068763166236, 34.03465577421536],
      [44.987208273565315, 34.03439828214989],
      [44.986355531910036, 34.033904755691154],
      [44.98570073947105, 34.03336831388816],
      [44.98615757219934, 34.03465577421531],
      [44.981436790619945, 34.03489180860862],
      [44.98136064577428, 34.03581448850975],
      [44.986096661400204, 34.038711274245834],
      [44.99046684729939, 34.040685380080745],
      [44.99166973157174, 34.04064246473651],
      [44.99291065505027, 34.04070683775295],
    ],
    zoom: 13,
    behaviors: [
        // 'scrollZoom',
    ],
    controls: [
        'fullscreenControl',
        'geolocationControl',
        // 'routeEditor',
        // 'rulerControl',
        // 'typeSelector',
        'zoomControl',
        // 'routeButtonControl',
        // 'routePanelControl',
    ],
    markerIcon: {
      layout: 'default#imageWithContent',
      imageHref: '/assets/icons/map_green.svg',
      imageSize: [40, 40],
      imageOffset: [-20, -37],
    },
    
    ozerniyIcon: {
      layout: 'default#imageWithContent',
      imageHref: '/assets/icons/map_dark.svg',
      imageSize: [40, 40],
      imageOffset: [-20, -37],
      content: 'п. Озёрный',
      contentOffset: [-40, -35],
      contentLayout: '<div style="background: white; padding: 10px; width: 100px; border-radius: 10px; color: black; font-weight: bold;">$[properties.iconContent]</div>'
    },
  }),
  methods: {
  },
}

</script>


<style lang="scss" scoped>

  .outer {
      background: rgba(14, 10, 6, 0.99);
      padding-bottom: 80px;

      .inner {
          max-width: 1312px;
          margin: auto;
      }
  }

  .ymap-container {
    
    height: 90vh;
    width: 100%;
    overflow: hidden;
  }

  .ymaps-2-1-79-inner-panes {
    filter: grayscale(0.5);
    -ms-filter: grayscale(0.5);
    -webkit-filter: grayscale(0.5);
    -moz-filter: grayscale(0.5);
    -o-filter: grayscale(0.5);
  }
</style>
