export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '676,262,680,278,849,437,879,459,913,458,959,452,977,439,1000,415,1037,395,1090,373,1110,359,1114,327,1117,276,1059,301,853,303,794,285,681,246'
    }
  },
  name: 'Очередь 5',
  id: 9,
  imgForModal: {
    img: '/assets/area5-2.svg',
    viewBox: {
      x: '662',
      y: '240',
      width: '470.68',
      height: '231.39',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm740.2 305.1c-.1.2-.2.4-.3.6-2.5 2.6-4.9 5.3-7.4 7.9-.4.4-.9.4-1.2.1-.3-.2-.5-.4-.8-.7-.8-.7-1.6-1.4-2.3-2.2-.9-1-2.2-1.7-2.7-3 .8-1 1.7-1.9 2.5-2.9 1.8-1.9 3.5-3.7 5.3-5.6.2-.2.6-.4.9-.1 1.2 1 4.6 4.3 6 5.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm756 320.2c-.1.2-.1.5-.3.6-2.5 2.6-4.9 5.3-7.4 7.9-.3.4-.9.4-1.2 0-1.9-1.8-3.7-3.5-5.6-5.3-.2-.2-.2-.7 0-.9 1.2-1.3 2.3-2.6 3.6-3.8 1.6-1.5 2.7-3.5 4.7-4.6 1.8 1.3 3.3 3 4.9 4.4.4.4.8.8 1.2 1.3.1.1 0 .3.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm764 344.6c-.1-.1-.2-.1-.3-.2-.4-.3-.7-.6-1.1-.9-4.4-4.1-4.9-4.6-4.7-5 1.1-1.9 2.8-3.4 4.4-4.9 1.3-1.3 2.2-3 4-3.8 1.6 1 2.8 2.4 4.2 3.7.7.6 1.3 1.3 2 2 .1.1.1.4.1.6 0 .1-.1.2-.2.3-2.5 2.6-4.9 5.3-7.4 7.9-.4.1-.7.1-1 .3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm784.9 347.4c0 .1-.1.2-.1.3-.1.1-.1.2-.2.3-2.5 2.6-5 5.2-7.4 7.9-.4.4-.9.4-1.3.1-1.9-1.8-3.7-3.5-5.5-5.3-.3-.2-.3-.6 0-.9 1.2-1.3 2.3-2.6 3.6-3.8 1.6-1.5 2.7-3.5 4.7-4.5 1.9 1.2 3.3 2.9 4.9 4.4.4.4.8.8 1.2 1.3.1-.1.1 0 .1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm789.4 351.6c.2.1.5.2.6.3.9.8 1.7 1.6 2.6 2.4.8.7 1.5 1.4 2.3 2.1 1.2 1.1 1.2 1.2 0 2.5-.8.9-1.6 1.7-2.4 2.5-1.8 1.7-3.1 3.8-5.2 5.1-1.8-1.7-3.6-3.3-5.4-5-.2-.2-.4-.3-.5-.5-.1-.3-.1-.6-.1-1 0-.1.1-.2.2-.3 2.5-2.6 5-5.3 7.4-7.9.1-.1.3-.2.5-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm805.8 367.3c-.1.2-.1.5-.3.6-2.4 2.6-4.8 5.1-7.2 7.6-.5.5-1.1.6-1.5.2-1.9-1.8-3.7-3.5-5.5-5.3-.1-.1-.1-.4 0-.6 0-.1.1-.2.2-.3 2.5-2.6 4.9-5.3 7.4-7.9.4-.4.9-.5 1.2-.2 1.9 1.8 3.7 3.5 5.6 5.3.1.1.1.3.1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm808.3 386.2c-.2-.1-.4-.1-.6-.3-1.7-1.6-3.4-3.2-5.1-4.8-.1-.1-.2-.2-.2-.3-.4-.4-.4-1 .1-1.5 1.4-1.5 2.9-3.1 4.3-4.6 1-1 1.9-2 2.9-3 .4-.4 1-.4 1.5 0 .3.2.5.5.8.7 1.5 1.7 3.6 2.9 4.8 5-1.5 1.6-3 3.3-4.4 4.9-1.1 1.2-2.2 2.4-3.4 3.5-.2.2-.5.3-.7.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm826.8 386.7c0 .1 0 .2-.1.3-.1.2-.2.4-.4.6-2.3 2.5-4.6 4.9-7 7.3-.7.7-1.2.8-1.8.2-1.7-1.6-3.4-3.2-5.1-4.8-.5-.4-.5-1 0-1.5 2.4-2.5 4.8-5.1 7.2-7.6.7-.7 1-.7 1.8 0 1.7 1.6 3.4 3.2 5 4.8.2.3.3.6.4.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm837.2 396.5c-.4.5-.7 1-1.1 1.4-2.2 2.3-4.3 4.6-6.5 6.9-.7.7-1.1.8-1.8.2-1.6-1.5-3.3-3-4.8-4.6-.9-.9-.9-1-.1-2 2.2-2.4 4.5-4.8 6.7-7.1.2-.2.3-.3.5-.5s.7-.3 1-.1c2.1 1.7 4.2 3.6 6.1 5.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm844 419.9c-.1-.1-.2-.1-.3-.2-1.9-1.7-3.8-3.5-5.6-5.2-.4-.4-.4-.8 0-1.2 2.5-2.6 4.9-5.3 7.4-7.9.4-.4 1-.4 1.5 0 .3.3.7.6 1 1 1.5 1.6 3.5 2.7 4.5 4.8-1.4 1.6-2.8 3.1-4.2 4.7-1.2 1.3-2.4 2.5-3.6 3.8-.2.1-.5.1-.7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm856.3 431.1c-.1 0-.3.1-.3 0-.4-.3-.8-.5-1.1-.8-4.7-4.3-5.2-4.8-5-5.2 1.2-2 3.1-3.5 4.6-5.2 1-1.1 2.1-2.2 3.1-3.3.3-.3.9-.3 1.3 0 1.8 1.7 3.6 3.3 5.3 5 .4.4.4.8.1 1.2-2.5 2.6-4.9 5.3-7.4 7.9-.2.3-.4.3-.6.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm867.6 441.9c-.2-.1-.5-.1-.6-.2-1.8-1.6-3.6-3.3-5.4-5-.4-.4-.5-.9-.2-1.2 2.5-2.7 5.1-5.4 7.7-8.1.2-.2.8-.3 1-.1 1.9 1.8 3.9 3.7 5.8 5.5.1.1 0 .4 0 .6 0 .1-.1.2-.2.3-2.5 2.6-4.9 5.3-7.4 7.9-.2.2-.5.2-.7.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm756.9 305.4c0-.7 0-.8 0-.9.2-5.6.2-5.6.7-5.8 1.8-.5 3.7-.2 5.5-.2 3.4 0 3.3-.1 3.3 3.2 0 2.2-.1 4.4-.2 6.6 0 .7-.1 1.4-.3 2.1-.1.4-.3.6-.7.7-.7.1-1.4.1-2.1 0-5.7 0-6 0-6.1-.5-.6-1.9 0-3.8-.1-5.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm777 305.4c0-1.7 0-3.5 0-5.2 0-.6.5-1.1 1.1-1 2.3.1 4.7.1 7 .2h.3c.2 0 .7.5.7.7s.1.5.1.7c-.1 3.4-.2 6.8-.3 10.1 0 .6-.5 1-1.1 1.1-.2 0-.5 0-.7 0-1.9 0-3.7-.1-5.6-.1-1.6 0-1.8-.5-1.8-1.9.2-1.6.2-3.1.3-4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm807.1 313.1c0 1.5-.1 3-.2 4.5 0 .6-.1 1.2-.3 1.7-.1.3-.5.5-.8.6-.2.1-.5 0-.7 0-2.1-.1-4.2-.2-6.3-.3-.9 0-1.3-.4-1.3-1.2.1-3.5.3-7 .4-10.5 0-.5.4-.9.9-.9 2.2-.1 4.4-.1 6.6.2 1.6.2 1.7.4 1.7 2 .1 1.4 0 2.6 0 3.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm839.5 320.6c-1.8 0-3.5 0-5.3 0-.6 0-1.1-.5-1.1-1.1 0-.8 0-1.6 0-2.5 0-4.9 0-5.3.5-5.4 1.7-.6 3.5-.2 5.2-.3 2-.1 4 0 6 0 .5 0 1 .4 1 .8 0 2.6.1 5.1.1 7.7 0 .2-.5.6-.7.6-2 0-3.8.1-5.7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm840.7 334.3c-2.1.2-3.9.3-5.6.4-.4 0-1-.4-1.1-.8-.1-2.5-.3-5.1-.4-7.6 0-.2.2-.4.4-.5.3-.2.6-.3 1-.4 1-.1 2.1-.2 3.1-.2 2.2-.1 4.4 0 6.6-.4.8-.1 1.3.3 1.4 1.2.1 1 .3 2.1.3 3.1.1 5.6 1.1 5-4.8 5.2-.4 0-.9 0-.9 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm843.1 354.6c-.2-.1-.5-.1-.6-.2-1.2-2.4-2.4-4.8-3.6-7.2-.1-.3.1-.7.4-.9 1.5-.7 2.9-1.4 4.4-2.2 1.4-.7 2.7-1.4 4.1-2 2.2-1 2.2-1.4 3.5 1.4.6 1.4 1.3 2.7 2 4.1.2.3.3.6.4 1 .2.4-.1.9-.5 1.2-.2.1-.4.2-.6.3-2.9 1.4-5.9 2.8-8.8 4.3-.3.1-.5.1-.7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm849.5 366.5c.1-.2.2-.4.2-.4 2.7-2.6 5.4-5.1 8.1-7.7.3-.3 1-.3 1.3 0 1.9 1.9 3.7 3.9 5.5 5.8-.2.3-.2.6-.4.7-2.7 2.6-5.4 5.1-8.1 7.7-.3.2-.7.2-1 0-1.9-2-3.8-4.1-5.6-6.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm884.4 379.8c-.1.2-.2.4-.3.6-.8 1.2-1.5 2.3-2.3 3.5-.9 1.4-1.8 2.7-2.7 4.1-1.5 2.3-1.3 2.1-3.7.5-1.4-.9-2.7-1.8-4.1-2.7-.3-.2-.6-.4-.8-.7-.1-.2-.1-.4-.1-.7 0-.1.1-.2.1-.3 2-3 4-6 6.1-9 .3-.5.7-.6 1.2-.2 2.1 1.4 4.2 2.8 6.4 4.3.1.1.1.4.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm869.8 325.2c.3 0 .1 0-.1 0-5-.1-4.3.3-4.1-4.7 0-.3.1-.7.1-1 .2-2.2.4-4.4.7-6.6 1-.8 2.2-.3 3.4-.3s2.3.1 3.5.1c.5 0 .9 0 1.4.2.3.1.6.3.7.7 0 .3.1.7.1 1-.2 3.4-.5 6.7-.7 10.1 0 .2-.5.6-.7.7-.3 0-.7.1-1 0-1.1-.1-1.9-.1-3.3-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm894.1 319.6v4.9c0 1-.4 1.5-1.4 1.5-2.3 0-4.6 0-7 0-.4 0-.9-.5-.9-.9 0-3.6-.1-7.2-.1-10.8 0-.4.5-.9.9-.9h7.6c.4 0 .6.3.6.7.1 1.8.2 3.7.3 5.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm902.3 320.3c0-2.5 0-4.3 0-6 0-.6.5-1 1.1-1h6.6c1.1 0 1.4.4 1.4 1.5.1 3 .2 6.1.3 9.1 0 .3 0 .7-.1 1 0 .4-.5.9-.9.9-2.6 0-5.1.1-7.7 0-.3 0-.6-.3-.6-.7 0-1.8 0-3.7-.1-4.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm929.1 319.1v4.9c0 1.1-.4 1.6-1.4 1.6-2.3 0-4.7 0-7 0-.4 0-.9-.5-.9-.9-.1-3.6-.1-7.2-.2-10.8 0-.2.5-.7.7-.7 2.4-.1 4.9-.3 7.3-.4.6 0 1.2.5 1.2 1 .2 1.8.2 3.6.3 5.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm947 319.1v4c0 2.2 0 2.2-2.4 2.2-1.3 0-2.6 0-3.8 0-.7 0-1.4 0-2.1-.1-.2 0-.7-.4-.7-.6 0-.1 0-.2 0-.3-.1-3.5-.1-7-.2-10.5 0-.6.5-1.1 1.1-1.2.5 0 .9-.1 1.4-.1 5.5-.1 6.3-.1 6.5.4.5 2.2 0 4.3.2 6.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm964.5 318.6v4.7c0 1.6-.2 1.9-2.1 1.9-2.1 0-4.2 0-6.3 0-.4 0-.8-.4-.8-.9 0-3.6-.1-7.2-.1-10.8 0-.4.4-.8.9-.8 2.3 0 4.6-.1 7-.1.8 0 1.2.4 1.2 1.3.1 1.6.2 3.2.2 4.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm915.9 392.3c-.1 1.7-.2 3.5-.4 5.2-.1 1-.5 1.3-1.6 1.3-2.2-.2-4.4-.3-6.6-.5-.7-.1-1-.4-1-1.1 0-.8 0-1.6.1-2.4.2-2.4.4-4.9.5-7.3 0-.3.1-.7.2-1s.4-.6.8-.6c1.3 0 2.6 0 3.8.1.5 0 .9.1 1.4.2 1 .3 2.1-.2 2.9.6 0 1 .1 2.1.1 3.1-.1.7-.1 1.6-.2 2.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm937.9 394.1c.2 1.1-.3 1.7-1 1.9-2.3.5-4.5 1-6.8 1.4-.4.1-1-.2-1.1-.7-.5-2.4-1.1-4.8-1.6-7.1-.2-1-.4-2-.6-3.1-.2-.9.1-1.4.9-1.6 2-.4 4.1-.9 6.1-1.3.4-.1.9-.1 1.4-.1.2 0 .5.2.5.4.5 2 1 4.1 1.4 6.1.3 1.6.6 3 .8 4.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm961.6 384.2c-.1.2-.1.5-.2.6-2.2 1.3-4.4 2.6-6.6 3.9-.7.4-1 .3-1.4-.4-1.4-2.3-2.7-4.6-4.1-6.9-2-3.6-2.2-2.7 1.3-4.8 1.4-.8 2.8-1.6 4.2-2.4.4-.2 1-.1 1.2.3 1.8 3.1 3.6 6.2 5.5 9.3 0 .2.1.3.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm983.3 362.9v.3c-.1.3-.3.6-.5.9-1.3 1.8-2.7 3.5-4 5.3-.6.7-1 .8-1.7.2-2.7-2-5.4-4.1-8-6.2-.7-.5-.8-.9-.3-1.4 1.5-2 3.1-4 4.7-6.1.2-.2.7-.3.9-.1 3 2.3 5.9 4.5 8.9 6.8-.1.1-.1.2 0 .3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm993.7 342.4c0 .1-.1.3-.1.4-.9 2.4-1.8 4.8-2.8 7.2-.1.4-.8.6-1.2.5-3.3-1.3-6.5-2.6-9.7-3.9-.6-.2-.9-.8-.6-1.4.8-2.2 1.7-4.4 2.5-6.5.3-.7.8-.9 1.4-.7 3.3 1.3 6.5 2.6 9.8 3.9.2 0 .5.3.7.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm998.3 320.1c-.2 1-.4 2.4-.6 3.8-.3 3.3-.4 2.9-3.5 2.6-2.9-.3-5.8-.8-8.6-1.3-.4-.1-.7-.4-.6-.7.3-2.5.7-5.1 1-7.6.1-.5.5-.8 1-.8h.3c3.4.7 6.9.9 10.4 1.4.2 0 .6.5.6.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1024.9 319.3c-.1-5.5-.1-5.7.4-5.8 1.8-.6 3.7-.2 5.5-.3h2.5c.4 0 .8.4.8.9v10.5c0 .4-.5 1-.8 1-2.6 0-5.1 0-7.7 0-.2 0-.4-.3-.5-.4-.1-.3-.2-.7-.2-1 0-1.6 0-3.3 0-4.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1048.3 319.2v5.2c0 .6-.5 1-1.2 1-2.2.1-4.4.1-6.6.1-1 0-1.4-.5-1.4-1.6 0-.9 0-1.9-.1-2.8-.1-7.3-.1-7.8.4-7.9 2.6-.5 5.3-.2 8-.3.4 0 .9.4.9.9-.1 2-.1 3.7 0 5.4-.1 0-.1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm899.3 440.4c-.3 2.2-.6 4.6-1.1 7-.2 1 0 2.1-.8 2.9-.9 0-1.9 0-2.8-.1-1.7-.2-3.4-.5-5.2-.8-.4-.1-.8-.6-.8-1 .1-.7.1-1.4.2-2.1.4-2.6.9-5.3 1.3-7.9 0-.2.1-.5.1-.7.1-.4.6-.8 1-.8 2.5.4 5.1.7 7.5 1.4.3.1.6.4.5.8.1.4.1.7.1 1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm915.4 443.3c-.3 1.9.4 4-.6 5.8-1.8.6-3.7.1-5.5.2-.6 0-1.2 0-1.8 0-1-.1-1.5-.5-1.4-1.6 0-3.4.1-6.8.1-10.2 0-.4.5-.9.9-.9 2.5 0 4.9.1 7.4.1.5 0 .9.4.9.9v2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm932.3 442.4c.2 1.5.4 3 .6 4.5 0 .4-.4 1-.8 1-.1 0-.2.1-.3.1-2.3.2-4.6.4-7 .6-.5 0-1-.3-1-.7-.1-.3-.2-.7-.2-1-.3-3-.7-6-1-9 0-.3 0-.7 0-1 0-.2.2-.5.4-.5 2.6-.5 5.3-.9 8-.8.3 0 .6.2.7.7.3 2.1.5 4.2.8 6.3-.1-.2-.2-.2-.2-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm944.1 446.7c-1.1.1-1.8.1-2.5.1-.5 0-.9-.3-1-.7 0-.1-.1-.2-.1-.3-.2-2.1-.4-4.2-.6-6.2-.1-.8-.1-1.6-.3-2.4-.6-2.4 0-2.7 2.4-2.9 1.9-.1 3.7-.3 5.6-.4.4 0 1 .4 1 .8.1.6.2 1.1.3 1.7.3 2.9.6 5.8.8 8.7.1.7-.2 1.1-.9 1.2-.6.1-1.1.3-1.7.4-1.1-.1-2.3 0-3 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm961.8 442.3c-.2-.1-.5-.1-.6-.2-.4-.4-.7-.9-1-1.4-.8-1.4-1.7-2.8-2.5-4.2-.8-1.5-2.1-2.8-2.3-4.6 1.2-1.3 2.9-1.9 4.3-2.8.8-.5 1.6-.9 2.4-1.4.6-.3 1-.2 1.4.4 1.7 2.9 3.5 5.8 5.2 8.7.1.1.1.2.1.3.3.6.2 1-.5 1.4-2 1.2-4 2.4-6 3.6 0 0-.3.1-.5.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm983.5 425.5c-.1.2-.1.5-.3.6-1 1-2 2-3 3-.8.8-1.7 1.6-2.5 2.4-.1.1-.4.1-.6 0s-.4-.2-.6-.4c-2.5-2.5-4.9-5-7.4-7.5-.4-.4-.4-.9 0-1.2 1.8-1.8 3.7-3.6 5.5-5.4.1-.1.4-.1.6 0s.4.2.6.4c2.5 2.5 5 5 7.4 7.5.2.1.2.4.3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm986.4 405.6c.1 0 .2 0 .3.1.1 0 .2.1.3.2 2.5 2.6 5.1 5.1 7.6 7.7.3.3.3.9 0 1.2-1.4 1.4-2.8 2.8-4.2 4.2-1.7 1.7-1.7 1.9-3.5 0-1.5-1.6-3.1-3.1-4.7-4.7-.7-.7-1.4-1.5-2.1-2.3.3-1.3 1.4-1.8 2.2-2.6 1.1-1.2 2.3-2.3 3.5-3.4.2-.3.4-.3.6-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1003.6 390.6c.1.1.3.1.3.1.2.3.5.5.7.8.8 1.1 1.6 2.3 2.4 3.4.9 1.2 1.7 2.5 2.6 3.7 1.3 1.9 1.3 1.8-.7 3.2-1.2.9-2.5 1.7-3.7 2.6-.5.3-1 .6-1.5.9-.2.1-.5 0-.6-.1-.2-.1-.4-.3-.5-.5-.7-1-1.5-2.1-2.2-3.2-.9-1.3-1.9-2.7-2.8-4-1.3-2-1.9-1.8.8-3.6 1.4-.9 2.7-1.9 4-2.8.3-.2.6-.4.9-.5.1-.1.2 0 .3 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1026.5 393.7c-.2.2-.3.6-.5.8-.5.3-1 .5-1.6.7-5.2 2.2-5.9 2.4-6.1 2.1-1.2-1.7-1.7-3.8-2.6-5.7-.5-1.2-.9-2.4-1.5-3.5-1.1-2.1-.4-2.2 1-2.8 1.2-.5 2.3-1 3.5-1.5.9-.3 1.7-.7 2.6-1 .2-.1.5.1.6.2.2.2.4.5.6.9 1.3 3 2.5 6 3.7 9 .2.2.2.5.3.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1039.7 388.3c-.2.3-.2.5-.4.6-2.3 1-4.7 2-7 3-.4.2-.9-.1-1.1-.5-1.4-3.3-2.8-6.6-4.1-10-.1-.3.2-1.1.5-1.2 2.4-1 4.7-1.9 7.1-2.9.2-.1.7.2.9.4.2.4.4.8.6 1.3 1.2 2.8 2.3 5.6 3.4 8.4 0 .2 0 .6.1.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1053.5 382.6c-.2.2-.4.5-.7.7-2.2 1-4.5 1.9-6.7 2.8-.1 0-.2.1-.3.1-.2-.1-.4-.2-.6-.3s-.3-.4-.4-.6c-1.3-3.2-2.6-6.4-4-9.7-.2-.4.1-1 .5-1.2 2.2-1 4.5-1.9 6.7-2.8.5-.2 1.2.1 1.4.6 1.3 3.2 2.7 6.4 4 9.7.1.2.1.4.1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1061.6 366c.3.1.5.1.7.3.2.1.3.4.4.6 1.3 3.2 2.7 6.4 4 9.7.2.4-.1 1-.4 1.2-.4.2-.8.4-1.3.6-5.1 2.2-6.2 2.6-6.5 2.2-1.3-1.8-1.8-4-2.7-6-.7-1.6-1.3-3.2-1.9-4.8-.1-.4.4-.7 4.5-2.4.1 0 .2-.1.3-.1 1-.5 1.9-.9 2.9-1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1080.1 371.6c-.1.1-.1.2-.2.3s-.2.2-.3.2c-.3.1-.6.3-1 .4-2.2.8-4.2 2-6.5 2.5-1.5-1-1.5-2.8-2.3-4.2-.4-.7-.6-1.5-.9-2.3-.4-1.1-.9-2.1-1.3-3.2-.6-1.6-.6-1.7.9-2.3 1.1-.5 2.1-.9 3.2-1.4 1.1-.4 2.1-.9 3.2-1.3.2-.1.4.1.6.2s.3.4.4.6c1.3 3.2 2.7 6.5 4 9.7.2.3.2.6.2.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1092.3 363.6c-.1.2-.1.5-.3.6-.3.2-.6.3-.9.5-2 .9-4.1 1.7-6.1 2.6-.5.2-1.2-.1-1.4-.6-1.3-3.2-2.7-6.5-4-9.7-.2-.6 0-1.1.6-1.4 2.3-.9 4.5-1.9 6.8-2.8.4-.2.9 0 1.1.5 1.4 3.3 2.8 6.7 4.1 10 0 .1 0 .2.1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1093.9 338.5c.1-1.4.2-2.8.4-4.2 0-.4.5-.8 1-.8l10.5.3c.6 0 1.1.6 1.1 1.2-.1 2.4-.3 4.9-.4 7.3 0 .5-.5.9-.9.9-.7 0-1.4 0-2.1-.1-2.4-.4-4.9-.4-7.3-.4-.3 0-.7 0-1 0-.7 0-1.1-.5-1.1-1.1-.2-1-.2-2-.2-3.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1080.6 319.3c0-1.3 0-2.6 0-3.8 0-.7.1-1.4.2-2.1.2-1.1.5-1.4 1.6-1.4 2.2.1 4.4.3 6.6.4.1 0 .2 0 .3.1.4.1.8.5.8 1 0 1-.1 2.1-.1 3.1-.1 2.3-.3 4.6-.4 7-.1 1.2-.3 1.4-1.5 1.3-2.3-.1-4.6-.2-7-.4-.2 0-.6-.4-.7-.6-.1-.3 0-.7 0-1 0-1.2 0-2.3 0-3.5.1-.1.1-.1.2-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1104.1 321c-2.6-.2-4.5-.3-6.3-.4-1.1-.1-1.3-.4-1.3-1.6.1-2.2.2-4.4.3-6.7 0-.6.6-1 1.2-1 1.3 0 2.6 0 3.9.2 2.1.3 4.2.4 6.3.4.8 0 1.2.4 1.2 1.3 0 2-.1 4-.2 6 0 .3-.1.7-.2 1-.1.4-.6.8-1 .8-1.6 0-3.1 0-3.9 0z',
      },
    },
  ],
}
