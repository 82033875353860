<template>
    <div class="outer" id="call-us">
        <div class="inner">
            
            <div class="row">
                <div class="column column-image" data-aos="fade-up">
                    <img src="/assets/form-girl.jpg" alt="">
                </div>
                <div class="column column-second" data-aos="fade-up">
                    <h1 data-aos="fade-up">Свяжитесь<br>с нами</h1>
                    <div class="text-block mobile-hide">Ирина Георгиевна</div>
                    <div class="text-block-2 mobile-hide">Наш агент по недвижимости свяжется с Вами <div class="underlined"></div></div>

                    <div class="form" id="contact-form">
                        <div class="row">
                            <div class="column">
                                Ваше имя <span class="red">*</span>
                            </div>
                            <div class="column">
                                <input type="text" name="name" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                Почта <span class="red">*</span>
                            </div>
                            <div class="column">
                                <input type="email" name="email">
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                Телефон <span class="red">*</span>
                            </div>
                            <div class="column">
                                <input type="text" name="phone" ref="input" v-mask="'+7(###)###-##-##'" placeholder="+7" required>
                            </div>
                        </div>
                        <div class="row message hide red" id="message"></div>
                        <div class="row">
                            
                            <button type="submit" class="btn" @click.prevent="send">Отправить</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'
export default {
    directives: {mask},
    name: 'ContactForm',
    mounted() {
    },
    methods: {
        send() {
            let name = document.querySelector('input[name="name"]').value
            let phone = document.querySelector('input[name="phone"]').value
            let email = document.querySelector('input[name="email"]').value
            let message = document.getElementById('message');

            if (!name || !email) {
                message.innerHTML = 'Пожалуйста, заполните все обязательные поля';
                message.classList.add('red');
                message.classList.remove('hide');
                return;
            } 
            if (phone.length !== 16) {
                message.innerHTML = 'Пожалуйста, введите корректный номер телефона';
                message.classList.add('red');
                message.classList.remove('hide');
                return;
            }

            
            message.classList.add('hide');

          
            fetch('/mail.php?name=' + name + '&phone=' + phone + '&email=' + email)
                .then(async (response) => {
                    console.log(response);
                    return response.json();
                })
                .then(data => {
                    if (data == 'success') {
                        message.innerHTML = 'Мы свяжемся с вами в ближайшее время';
                        message.classList.remove('red');
                        message.classList.add('gren');
                        message.classList.remove('hide');
                    } else {
                        message.innerHTML = 'Произошла ошибка';
                        message.classList.add('red');
                        message.classList.remove('gren');
                        message.classList.remove('hide');
                    }
                })
                .catch(() => {
                        message.innerHTML = 'Произошла ошибка';
                        message.classList.add('red');
                        message.classList.remove('gren');
                        message.classList.remove('hide');
                });

            
        }
    }
}
</script>

<style lang="scss" scoped>
    .outer {
        background: rgb(37, 39, 41);
        padding: 100px 20px 80px;

        .inner {
            max-width: 1312px;
            margin: auto;
        }

        h1 {
            font-size: 112px;
            font-weight: 500;
            text-align: right;
            color: white;
            margin: 0 0 24px 0;
            text-align: right;
        }

        @media only screen and (max-width: 1200px) {
            h1 {
                font-size: 80px !important;
            }
        }

        @media only screen and (max-width: 800px) {
            h1 {
                font-size: 45px !important;
            }
        }

        .text-block {
            color: #fff;
            font-size: 32px;
            margin: 20px 0;
            text-align: right;
        }

        .text-block-2 {
            color: #fff;
            font-size: 16px;
            .underlined {
                display: inline-block;
                text-decoration: underline;
            }
            margin: 20px 0;
            text-align: right;
        }

    }
    #contact-form {
        background: white;
        width: 100%;
        max-width: 400px;

        .row {
            padding: 20px 20px 0;

            &:last-child {
                padding-bottom: 20px;
            }

            .column {
                margin-right: 20px;

                &:first-child {
                    max-width: 100px;
                }

                &:last-child {
                    margin-right: 0;

                    input {
                        width: calc(100% - 6px);
                    }
                }
            }
        }

        .message {
            justify-content: center;
        }

        .hide {
            display: none !important;
        }

        button {
            border: none;
            color: #222222;
            background: #d2b281;
            padding: 10px 24px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            margin: auto;
        }

        .red {
            color: red;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        .column {
            flex: 1;

            img {
                width: 100%;
            }
        }

        

        .column-image {
            max-width: 501px;
            padding-right: 20px;
        }

        .column-second {
            
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: auto;
        }
    }

    @media only screen and (max-width: 400px) {
        #contact-form {
            .row {
                display: block !important;

                .column:first-child {
                    margin-bottom: 5px;
                }

                &:not(:first-child) {
                    // padding: 0 20px 15px !important;
                    padding-top: 15px;
                    // padding-bottom: 5px;
                }
            }
        }
    }


    @media only screen and (max-width: 1000px) {
        .column-image {
            display: none !important;
        }
    }

    @media only screen and (max-width: 1000px) {
        .inner {
            display: block !important;
            .right {
                margin-top: 24px !important;
            }
        }
    }
</style>