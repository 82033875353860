import q1 from './queues/1'
import q2 from './queues/2'
import q3 from './queues/3'
import q4 from './queues/4'
import q5 from './queues/5'
import q52 from './queues/5-2'
import q42 from './queues/4-2'
import q32 from './queues/3-2'
import q22 from './queues/2-2'

export default [
  q1,
  q2,
  q22,
  q3,
  q32,
  q4,
  q42,
  q5,
  q52,
  {
    shape: {
      tag: 'circle',
      attrs: {
        r: "29",
        cx: "1199",
        cy: "679"
      }
    },
    name: 'Детский сад',
    cabins: [],
  },
  {
    shape: {
      tag: 'polygon',
      attrs: {
        points: '881,686,936,702,916,789,896,799,857,721,871,710,878,695'
      }
    },
    name: 'Многофункциональный центр',
    cabins: [],
  },
  {
    shape: {
      tag: 'polygon',
      attrs: {
        points: '738,709,720,712,699,728,633,712,604,723,601,774,565,817,450,860,286,860,296,1010,311,1057,447,959,583,848,665,810,723,797,800,809,885,840,894,825,852,752,867,747,845,708,827,714,806,695,792,707,772,706,763,706,752,708'
      }
    },
    name: 'Пляжная территория',
    cabins: [],
  },
  {
    shape: {
      tag: 'polygon',
      attrs: {
        points: '552,190,783,581,816,573,855,575,867,529,848,522,790,468,740,418,670,304,690,289,675,271,681,242'
      }
    },
    name: 'Зона отдыха',
    cabins: [],
  },
  {
    shape: {
      tag: 'circle',
      attrs: {
        r: "23",
        cx: "815",
        cy: "635"
      }
    },
    name: 'МФЦ, Супермаркет, аптека',
    cabins: [],
  },
  {
    shape: {
      tag: 'polygon',
      attrs: {
        points: '772,656,764,681,769,695,720,712,716,699,690,692,706,639'
      }
    },
    name: 'Спортивный центр: спортзал, спа, ресторан',
    cabins: [],
  },
  {
    shape: {
      tag: 'circle',
      attrs: {
        r: "15",
        cx: "837",
        cy: "615"
      }
    },
    name: 'Офис продаж',
    cabins: [],
  },
]