<template>
  <div>
    <modal :show="area && !activeCabin" @close="$emit('close')" :full="true">
      <template #title>
        {{ area.name }}
      </template>

      <div class="content">
        <div class="map-svg-wrap" id="map-svg-wrap">
          <map-svg
              ref="mapSvg"
              in-modal
              :areas="[area]"
              :active-type="activeType"
              class="map-svg"
              @cabinClick="showCabin($event)"
              @cabinHover="activeType = $event"
          />
        </div>
        <div class="cabins">
          <button
              v-for="(cabin, i) in [...area.cabinData].sort((a, b) => (a.order > b.order) ? 1 : -1)"
              :key="i"
              class="cabin-wrap"
              :class="{ hover: cabin.type == activeType }"
              :style="{ borderColor: cabin.color }"
              @mouseover="activeType = cabin.type"
              @mouseout="activeType = null"
              @focusin="activeType = cabin.type"
              @focusout="activeType = null"
              @click="showCabin(cabin)"
          >
            <div class="cabin-img-wrap">
              <img
                  v-if="cabin.img.length"
                  :src="`/assets/resized/cabins/${cabin.img[0]}`"
                  :alt="title"
                  class="cabin-img"
              >
            </div>
            <div v-if="cabin.label" class="cabin-title" v-html="cabin.label"/>
          </button>
        </div>
      </div>
    </modal>

    <cabin-modal :cabin="activeCabin" @close="activeCabin = null"></cabin-modal>
  </div>
</template>

<script>
import Modal from "@/components/common/Modal";
import { computed, nextTick, ref, toRefs, watch } from "vue";
import MapSvg from "@/components/Map/MapSvg";
import CabinModal from "@/components/CabinModal";
import svgPanZoom from 'svg-pan-zoom'
import Hammer from 'hammerjs'

export default {
  name: "AreaModal",
  components: {
    Modal,
    MapSvg,
    CabinModal
  },
  emits: ['close'],
  props: {
    area: {
      type: null,
      required: true
    },
  },
  setup(props) {
    const {area} = toRefs(props)
    console.log(area);
    const activeType = ref()
    const activeCabin = ref()
    const mapSvg = ref()
    const hammerjs = ref()

    const svgPanZoomEventsHandler = {
      haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
      init(options) {
        let instance = options.instance,
            initialScale = 1,
            pannedX = 0,
            pannedY = 0

        // Init Hammer
        // Listen only for pointer and touch events
        hammerjs.value = Hammer(options.svgElement, {
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
        })

        // Enable pinch
        hammerjs.value.get('pinch').set({enable: true})

        // Handle double tap
        hammerjs.value.on('doubletap', () => {
          instance.zoomIn()
        })

        // Handle pan
        hammerjs.value.on('panstart panmove', (ev) => {
          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }

          // Pan only the difference
          instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
          pannedX = ev.deltaX
          pannedY = ev.deltaY
        })

        // Handle pinch
        hammerjs.value.on('pinchstart pinchmove', (ev) => {
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
          }

          instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
        })

        // Prevent moving the page on some devices when panning over SVG
        options.svgElement.addEventListener('touchmove', (e) => {
          e.preventDefault();
        });
      },
      destroy() {
        hammerjs.value.destroy()
      }
    }

    const title = computed(() => props.area?.name)
    const img = computed(() => props.area?.img?.[0])

    const getImg = (cabinType) => cabinType?.img?.[0]

    const showCabin = (cabin) => {
      activeCabin.value = props.area?.cabinData?.find(el => el?.type === cabin.type)
    }

    watch([area, activeCabin, mapSvg], ([val1, val2, val3]) => {
      console.log(val3);
      if (val1 && !val2) {
        nextTick(() => {
          if (mapSvg.value?.$el) {
            svgPanZoom(mapSvg.value.$el, {
              zoomEnabled: true,
              controlIconsEnabled: true,
              fit: true,
              center: true,
              customEventsHandler: svgPanZoomEventsHandler
            })
          }
        })
      }
    })

    return {
      title,
      img,
      getImg,
      activeType,
      showCabin,
      activeCabin,
      mapSvg,
    }
  },
  mounted() {
    console.log('mounted');
  },
}
</script>

<style scoped lang="scss">
.cabin-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cabin-wrap {
  border: 2px solid;
  width: calc(100% / 3 - 20px);
  min-width: 200px;
  max-width: 320px;
  padding: 0;
  background: transparent;
  text-align: start;
  margin: 0 10px 10px 0;
  cursor: pointer;
  transition: transform ease-in-out .2s;
  flex-shrink: 0;
  outline: none;
  background: white;

  &:hover,
  &:focus-visible,
  &.hover {
    transform: scale(.95);
  }

  // &:last-child {
  //   margin-right: 0;
  // }
}

@media only screen and (max-width: 670px) {
  .cabins {
    flex-direction: column;
    .cabin-wrap {
      margin: 0 0 10px 0;

      .cabin-img-wrap {
        display: none !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.cabin-title {
  font-size: 14px;
  padding: 10px;
  color: #128737;
  font-weight: 500;
}

.cabins {
  display: flex;
  align-items: center;
  margin-right: -10px;
  overflow: auto;
}

.cabins::before,
.cabins::after {
  content: '';
  flex: 1;
}

.content {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;

  .map-svg-wrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .map.map-svg {
      max-width: 100% !important;
      height: 100% !important;
    }
  }

  .map-svg {
    width: 100%;
    height: 100%;
  }
}

.cabin-img-wrap {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

</style>