<template>
    <div class="outer">
        <div class="inner">
            <div class="right">
                <h1 data-aos="fade-up">Социальная инфраструктура</h1>
            </div>

            <div class="row">
                <div class="column column-iconed">
                    <div class="row">
                        <img  src="/assets/icons/comfort.svg" width="60" height="60" data-aos="fade-in">
                        <h5 >Комфорт</h5>
                    </div>
                    <p>Озерный спроектирован таким образом, чтобы каждый нашел в нем то, что нужно, а покидать его пришлось бы только при явной необходимости.</p>
                </div>
                <div class="column column-iconed">
                    <div class="row">
                        <img src="/assets/icons/objects.svg" width="60" height="60" data-aos="fade-in">
                        <h5>Объекты первой необходимости</h5>
                    </div>
                    <p>Все под рукой: супермаркет, аптека, многофункциональный торговый центр, кафе, спортивный комплекс, детский сад, парковка.</p>
                </div>
                <div class="column column-iconed">
                    <div class="row">
                        <img src="/assets/icons/badminton.svg" width="60" height="60" data-aos="fade-in">
                        <h5>Спортивные объекты</h5>
                    </div>
                    <p>Занимайся любимым спортом на волейбольной и баскетбольной площадках, площадке для ворк-аута, футбольном поле, тенисном корте, памп треке, игровых площадках, уличном бассейне.</p>
                </div>
            </div>
            <div class="row">
                <div class="column column-iconed">
                    <div class="row">
                        <img src="/assets/icons/dosug.svg" width="60" height="60" data-aos="fade-in">
                        <h5>Досуговые объекты</h5>
                    </div>
                    <p>Проводи свободное время с удовольствием, для этого мы построили зоны барбекю, площадки и беседки у воды, ставок для рыбалки, СПА комплекс, пляж, прогулочные парки.</p>
                </div>

                <div class="column column-iconed">
                    <div class="row">
                        <img src="/assets/icons/substitution.svg" width="60" height="60" data-aos="fade-in">
                        <h5>Вместимость</h5>
                    </div>
                    <p>Вся инфраструктура создана с заделом на будущее.</p>
                </div>
                <div class="column column-iconed">
                    <div class="row">
                        <img src="/assets/icons/modern.svg" width="60" height="60" data-aos="fade-in">
                        <h5>Современность</h5>
                    </div>
                    <p>Вся инфраструктура спроектирована по передовым стандартам</p>
                </div>
            </div>
            <br>
            <br>
            <img class="big-image" src="/assets/social_infrastructure.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialInfrostructure',
}
</script>

<style lang="scss" scoped>
    .outer {
        background: rgba(14, 10, 6, 0.99);
        padding: 80px 0;

        .inner {
            margin-top: 100px;
            max-width: 1312px;
            padding: 0 24px;
            margin: auto;
            
            .right {
                display: flex;
                justify-content: flex-end;
                max-width: 1312px;
                margin: auto;

                h1 {
                    font-size: 120px;
                    color: white;
                    margin: 0 0 20px 0;                    
                    word-break: break-word;
                }

                @media only screen and (max-width: 900px) {
                    h1 {
                        font-size: 80px !important;
                    }
                }

                @media only screen and (max-width: 650px) {
                    h1 {
                        font-size: 45px !important;
                    }
                }
            }

            
            .column-iconed {
                margin: 24px 0;
                flex: 50%;

                .row {
                    display: flex;
                    align-items: center;

                    img {
                        margin: none;
                        width: auto;
                        margin-right: 20px;
                    }

                    h5 {
                        color: #128737;
                        font-size: 22px;
                        line-height: 24px;
                        font-weight: 500;
                        margin: 0;
                        padding: 0;
                    }
                }

                p {
                    color: white;
                    font-size: 16px;
                    line-height: 120%;
                }
            }

            .row {
                display: flex;

                .column {
                    padding-right: 20px;
                    flex: 1;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            
            .big-image {
                width: 100%;
            }
        }
    }
    
    @media only screen and (max-width: 900px) {
        .column, .row {
            display: block !important;
        }

        .column-iconed {
            .row {
                display: flex !important;
            }
        }
    }
</style>