export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '862,551,891,559,922,568,946,584,1034,607,1055,614,1070,614,1171,619,1170,719,1054,714,1029,711,917,679,896,667,877,649,859,627,845,622'
    }
  },
  name: 'Очередь 2 (правое крыло)',
  id: 6,
  imgForModal: {
    img: '/assets/area2-2.svg',
    viewBox: {
      x: '835',
      y: '543',
      width: '350.84',
      height: '184.04',
    },
  },
  active: true,
  showBorder: false,
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm880.1 574.5c-.9-.2-2.2-.6-3.6-.9-1-.3-2-.5-3-.8-.4-.1-.7-.7-.6-1.1.8-3.4 1.7-6.8 2.6-10.2.2-.6.7-1 1.3-.9 2 .5 4.1 1 6.1 1.6.3.1.6.3 1 .4.5.2.8.6.7 1.1-.6 2.4-1.2 4.8-1.7 7.1-.3 1-.5 2-.8 3-.4.6-.9.9-2 .7z',
      },
      type: 'premium-wright-style',
      number: {
        n: 156,
        x: 879,
        y: 570,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm894.9 571.1c.5 0 .8-.1 1.2 0 2.1.5 4.3 1.1 6.4 1.6.1 0 .2.1.3.1.4.2.7.7.6 1.1-.9 3.5-1.8 7-2.7 10.4-.1.4-.4.6-.7.6-.9-.1-6.9-1.6-7.7-2-.3-.1-.6-.4-.5-.8 0-.1 0-.2 0-.3.9-3.4 1.7-6.7 2.6-10.1 0-.2.4-.4.5-.6z',
      },
      type: 'premium-wright-style',
      number: {
        n: 157,
        x: 898,
        y: 581,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm913 598.6c-1.8-.5-4.2-1.1-6.6-1.7-.6-.2-.9-.7-.7-1.4.9-3.4 1.8-6.7 2.6-10.1.1-.5.6-.9 1-.8 2.5.6 4.9 1.3 7.4 1.9.5.1.8.6.7 1.1-.9 3.4-1.8 6.7-2.7 10.1-.2.8-.6 1.1-1.7.9z',
      },
      type: 'premium-wright-style',
      number: {
        n: 158,
        x: 912,
        y: 593,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm928.7 601.7c-.5 1.8-1.1 4.3-1.7 6.7-.2.9-.5 1.8-.7 2.7-.1.4-.6.7-1.1.7-.2 0-.5 0-.7-.1-2.1-.5-4.3-1.1-6.4-1.6-.3-.1-.8-.8-.8-1.1s.1-.7.1-1c.8-3 1.5-6.1 2.3-9.1.1-.2.2-.5.3-.6.2-.2.6-.5.8-.5 2.4.6 4.7 1.2 7.1 1.8.8.4 1 .7.8 2.1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 159,
        x: 922,
        y: 607,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm940.6 612.9v.4c-.8 3.1-1.6 6.3-2.4 9.4-.1.4-.3.9-.5 1.3-.1.2-.4.3-.6.3-2.6-.6-5.2-1.1-7.7-2-.3-.1-.5-.5-.5-.8.9-3.5 1.8-7 2.7-10.4.1-.4.6-.8 1-.7 1 .2 2 .5 3 .7 1.5.4 2.9.7 4.4 1.1.3.1.4.5.6.7z',
      },
      type: 'premium-wright-style',
      number: {
        n: 160,
        x: 935,
        y: 620,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm942.6 627.3c.6-2.1 1.1-4.3 1.7-6.4.1-.3.5-.6.8-.5 2.6.7 5.1 1.3 7.7 2 .3.1.5.6.5.8-.9 3.5-1.8 7-2.7 10.4-.1.4-.6.8-1.1.7-1.5-.3-3-.6-4.4-1-4.7-1.4-3.6-.4-2.8-4.7 0-.4.2-.9.3-1.3z',
      },
      type: 'premium-wright-style',
      number: {
        n: 161,
        x: 947,
        y: 630,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm966.6 628.3c-.7 2.6-1.5 5.7-2.3 8.9-.1.4-.7.8-1.1.7-.5-.1-.9-.1-1.4-.2-1.8-.4-3.6-.9-5.4-1.4-.9-.2-1.3-.9-1.1-1.8.6-2.3 1.1-4.5 1.7-6.8.2-.8.3-1.6.6-2.4.5-1.3.7-1.7 2.3-1.2s3.2.8 4.7 1.2c.6.1 1.1.4 1.6.6.3.1.6.3.5.8.1.3.1.6-.1 1.6z',
      },
      type: 'premium-wright-style',
      number: {
        n: 162,
        x: 960,
        y: 634,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm981.4 630c0 .3.1.7 0 1-.8 3.2-1.6 6.3-2.4 9.5 0 .1-.1.2-.1.3-.1.4-.7.7-1.1.7-2.4-.4-4.7-1.2-7.1-1.7-.6-.1-.9-.7-.7-1.3.8-3.4 1.7-6.8 2.6-10.2.1-.4.7-.8 1-.7 2.5.6 5 1.3 7.4 1.9.1.1.2.4.4.5z',
      },
      type: 'premium-wright-style',
      number: {
        n: 163,
        x: 975,
        y: 637,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm994.7 633.9c-.5 1.8-.9 3.5-1.3 5.2-1.1 4.6-1.5 5.8-1.9 5.8-2 0-3.9-.7-5.7-1.2-.7-.2-1.4-.3-2-.5-.4-.1-.8-.7-.7-1.1.9-3.5 1.8-7 2.7-10.4.1-.5.6-.9 1-.8 2.5.7 4.9 1.3 7.4 2 .3.1.5.6.5 1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 164,
        x: 988,
        y: 640,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1008.9 637.4c0 .3 0 .7-.1 1.2-.8 3.1-1.6 6.1-2.3 9.2 0 .1-.1.2-.1.3-.1.4-.7.7-1.1.6-2.4-.6-4.8-1.2-7.1-1.8-.4-.1-.8-.7-.7-1.1.3-1.3.6-2.5.9-3.8.6-2.3 1.2-4.5 1.7-6.8.1-.4.8-.7 1.1-.6 2.4.6 4.7 1.2 7.1 1.8.3.1.5.4.6 1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 165,
        x: 1002,
        y: 644,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1018.3 652c-2-.5-4-1.1-6.1-1.6-.4-.1-.8-.7-.7-1.1.2-1 .5-2 .7-3.1.6-2.4 1.2-4.7 1.9-7.1.2-.7.6-1 1.3-.8 2.3.5 4.5 1.1 6.8 1.6.6.2 1 .7.9 1.3-.1.5-.2.9-.3 1.4-.7 2.9-1.5 5.9-2.2 8.8-.2.6-.7 1-1.3.8-.1 0-.2-.1-.3-.1-.2 0-.5-.1-.7-.1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 166,
        x: 1017,
        y: 647,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1025.6 652.5c0-.1.1-.6.2-1 .7-2.7 1.4-5.4 2.1-8.1.1-.3.3-.6.4-1 .2-.4.5-.5.8-.5.7.1 1.4.2 2.1.3 1.1.3 2.2.7 3.4.9 3.1.6 2.8.9 2.2 3.4-.6 2.7-1.4 5.4-2.1 8.1-.1.3-.3.6-.5.9-.1.2-.4.3-.6.3-2.5-.6-4.9-1.3-7.4-2-.5 0-.7-.4-.6-1.3z',
      },
      type: 'premium-wright-style',
      number: {
        n: 167,
        x: 1031,
        y: 651,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1042.3 650.6c.1-1.6.1-3.3.2-4.9 0-.5.4-.8.9-.9h.3c2.2.1 4.4.1 6.6.2.9 0 1.3.5 1.3 1.3 0 1.9 0 3.7-.1 5.6-.1 1.8.3 3.7-.5 5.5-1.7.5-3.5.1-5.2.2-.7 0-1.4 0-2.1 0-1.4-.1-1.7-.4-1.7-2 0-.5 0-.9.1-1.4.1-1.3.2-2.5.2-3.6z',
      },
      type: 'premium-wright-style',
      number: {
        n: 168,
        x: 1046,
        y: 653,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1066.5 653.4c0 1.5-.1 3-.1 4.5 0 .3-.1.7-.1 1s-.7.9-1 .9c-2.4-.1-4.9-.2-7.3-.3-.4 0-.8-.5-.8-.9 0-2 .1-4 .1-5.9 0-.7.1-1.4.1-2.1.1-4.1-.2-3.7 4-3.5 1.5 0 3 .3 4.5.5.5.1.8.5.8.9-.2 1.6-.2 3.2-.2 4.9-.1 0-.1 0 0 0z',
      },
      type: 'premium-wright-style',
      number: {
        n: 169,
        x: 1061,
        y: 656,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1080.9 653.7c-.2 2.3-.3 4.3-.5 6.2 0 .2-.3.3-.5.5-.1.1-.2 0-.3 0-2.4-.1-4.9-.1-7.3-.2-.5 0-.9-.4-.9-.9 0-3.5 0-7 .4-10.4.1-1 .4-1.3 1.3-1.3 2.3.1 4.6.2 7 .3.6 0 1.1.6 1 1.2-.1 1.7-.2 3.3-.2 4.6z',
      },
      type: 'premium-wright-style',
      number: {
        n: 170,
        x: 1075,
        y: 657,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1085.9 654.6c0-.2 0-.6 0-.9.2-1.7-.4-3.5.5-5.1 1.6-.5 3.2-.2 4.9-.2 4.4 0 4-.3 3.9 4-.1 2.5-.2 4.9-.3 7.4 0 .9-.5 1.4-1.2 1.3-2.3-.1-4.7-.1-7-.2-.6 0-1-.6-1-1.2.1-1.7.1-3.3.2-5.1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 171,
        x: 1090,
        y: 657,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1109.9 655c-.1 1.6-.1 3.1-.2 4.7-.1 2-.2 2.1-2.3 2.1-1.7 0-3.5 0-5.2-.1-1.5-.1-1.9-.5-1.9-1.8.1-1.9.2-3.7.3-5.6 0-.5.1-.9.2-1.4.3-1.2-.4-2.5.5-3.7 1.2-.4 2.5-.2 3.8-.1 5.9.2 4.9-.5 4.9 5-.1.4-.1.7-.1.9z',
      },
      type: 'premium-wright-style',
      number: {
        n: 172,
        x: 1105,
        y: 658,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1115.1 656.6c0-.3 0-.7.1-1 .3-5.3.4-5.6.8-5.7 1.8-.4 3.7 0 5.5 0 3.5 0 3.2-.1 3.2 3.2 0 1.4 0 2.8-.1 4.2-.2 3.7-.4 4.9-.8 5-1.6.4-3.2.1-4.8.1-4.4 0-4 .2-3.9-4-.1-.6-.1-1.2 0-1.8z',
      },
      type: 'premium-wright-style',
      number: {
        n: 173,
        x: 1120,
        y: 658,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1138.8 656.7c-.1 1.8-.1 3.5-.2 5.3 0 .7-.5 1.1-1.1 1.1-2.5-.1-4.9-.2-7.4-.4-.3 0-.9-.7-.9-1 .1-3.4.3-6.8.4-10.2 0-.7.4-1.2 1-1.2 2.5 0 4.9.1 7.4.1.4 0 .8.5.8.9v5.3z',
      },
      type: 'premium-wright-style',
      number: {
        n: 174,
        x: 1134,
        y: 658,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1143.7 657.2c.1-1.6.1-3.3.2-4.9 0-.9.5-1.4 1.3-1.4 2.3.1 4.7.2 7 .3.7 0 1.2.5 1.1 1.1-.4 3.1-.4 6.3-.5 9.5 0 .3 0 .7-.1 1 0 .5-.4.9-.9.9-2 0-4 .1-6-.2-2.3-.3-2.5.1-2.4-2.8 0-.8 0-1.6.1-2.5.1-.3.1-.7.2-1-.1 0 0 0 0 0z',
      },
      type: 'premium-wright-style',
      number: {
        n: 175,
        x: 1148,
        y: 659,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1157.8 658.2c.1-1.5.1-3 .2-4.5 0-.5.1-.9.2-1.4 0-.3.6-.9 1-.9h1.4c2 .1 4 .3 5.9.5.6.1 1 .5 1 1.2 0 .8-.1 1.6-.1 2.4-.1 2.4-.3 4.9-.4 7.3 0 .3-.1.7-.2 1-.1.2-.3.4-.5.4-2.7.2-5.3-.2-8-.4-.2 0-.4-.3-.4-.5-.1-.3-.1-.7-.1-1-.1-1.3-.1-2.7 0-4.1z',
      },
      type: 'premium-wright-style',
      number: {
        n: 176,
        x: 1163,
        y: 660,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm870.8 604.7c-.2-.1-.3-.1-.4-.1-2.2-1.1-4.4-2.1-6.6-3.2-.6-.3-.8-.8-.4-1.5 1.5-3.2 3-6.3 4.6-9.4.1-.2.7-.5.9-.4 2.5.9 4.9 2.1 7.2 3.5.3.2.4.5.2.9-1.6 3.3-3.1 6.5-4.7 9.8-.2.1-.6.2-.8.4z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 155,
        x: 871,
        y: 600,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm885.6 615.2c-.2-.1-.5-.1-.6-.2-2-1.6-3.9-3.3-5.8-4.9-.4-.3-.4-.8 0-1.2 2.2-2.7 4.5-5.3 6.8-8 .4-.5 1.1-.6 1.5-.2 1.9 1.7 3.9 3.3 5.8 5 .2.2.2.4.1.6s-.2.4-.3.6c-2.2 2.7-4.5 5.3-6.8 8-.3.1-.6.2-.7.3z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 154,
        x: 886,
        y: 610,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm904.7 619.5c0 .1 0 .2-.1.3-.2.3-.5.5-.7.8-1.1 1-2.2 2.1-3.3 3.1-1 1-2 1.9-3.1 2.9-1.5 1.4-1.5 1.7-3.3-.3-.9-1-1.9-2-2.9-3.1-.5-.5-.9-1.1-1.3-1.6-.1-.1 0-.4 0-.6 0-.1.1-.2.2-.3 2.7-2.5 5.3-4.9 8-7.4.2-.2.8-.2 1 0s.4.3.5.4c1.6 1.7 3.2 3.4 4.8 5.2.1.1.1.4.2.6z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 153,
        x: 897,
        y: 622,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm898.3 644.8c-.1.2-.1.5-.2.6-2.7 2.5-5.5 5.1-8.2 7.6-.2.2-.7.1-.9-.1-1.7-1.9-3.5-3.7-5.2-5.6-.3-.4-.3-.8.1-1.2 2.5-2.4 5.1-4.8 7.7-7.1.5-.5 1.1-.5 1.5 0 1.7 1.8 3.3 3.6 5 5.4.1 0 .1.2.2.4z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 152,
        x: 890,
        y: 648,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm908.9 654.5v.3c-.1.2-.2.4-.4.6-2 2.5-4.1 5.1-6.1 7.6-.2.3-.5.5-.7.7-.2.1-.5.2-.6.1-.7-.4-1.4-.9-2-1.4-1.5-1.3-3-2.5-4.5-3.8-.1-.9.7-1.4 1.1-2l2.4-3c3.4-4.2 3.8-4.7 4.2-4.5 1.8.9 3.1 2.4 4.7 3.5.6.5 1.2 1 1.9 1.6z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 151,
        x: 901,
        y: 658,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm921 662.5c0 .2 0 .5-.1.7-1.9 2.9-3.8 5.9-5.7 8.8-.5.7-.8.8-1.4.4-2.1-1.3-4.1-2.6-6.2-4-.5-.4-.6-.9-.3-1.5 2-3 4-6 5.9-9 .2-.3.7-.4.9-.2 2.1 1.4 4.3 2.8 6.4 4.2.3.2.4.4.5.6z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 150,
        x: 914,
        y: 668,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm930.7 677.9c-.2 0-.7-.1-1.3-.3-1.7-.4-3.4-.9-5.1-1.3-.6-.1-.9-.7-.8-1.3.8-3.4 1.7-6.8 2.5-10.2.2-.6.7-.9 1.3-.8 2.2.6 4.5 1.2 6.7 1.8.9.3 1.1.6.9 1.5-.6 2.4-1.2 4.7-1.8 7.1-.2.9-.5 1.8-.7 2.7-.1.7-.6.9-1.7.8z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 149,
        x: 929,
        y: 673,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm946.5 649.9c1.9.5 4.2 1.1 6.6 1.8.5.1.8.6.7 1-.9 3.5-1.8 7-2.7 10.5-.1.4-.6.8-1 .7-1.2-.3-2.5-.6-3.7-.9-.2-.1-.4-.2-.7-.2-1.2-.5-2.8 0-3.4-1.3-.6-1.1.4-2.2.6-3.3.5-2.5 1.3-4.9 1.9-7.4.2-.9.6-1.1 1.7-.9z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 148,
        x: 948,
        y: 659,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm963.4 667.2c-2.1-.6-4.4-1.1-6.6-1.7-.9-.3-1.2-.7-1-1.5.8-3.3 1.7-6.6 2.5-9.8.2-.6.7-1 1.3-.9 2.4.6 4.7 1.2 7.1 1.8.5.1.8.7.7 1.1-.9 3.5-1.8 7-2.7 10.5-.2.4-.7.7-1.3.5z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 147,
        x: 961,
        y: 662,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm981.3 659.9c-.8 3.2-1.6 6.4-2.5 9.7-.1.3-.2.7-.4.9-.1.2-.4.3-.6.3-2.6-.4-5.2-1.1-7.7-2-.3-.1-.6-.4-.5-.8.9-3.5 1.8-7 2.7-10.4.2-.6.7-.9 1.4-.7 2.3.6 4.7 1.3 7 1.9.5.1.8.5.6 1.1z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 146,
        x: 975,
        y: 666,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm994.6 668.3c-.5 1.8-.9 3.6-1.4 5.4-.1.3-.7.7-1.1.7-.5-.1-.9-.1-1.4-.2-1.9-.5-3.8-1-5.8-1.5-.4-.1-.9-.7-.8-1 .9-3.6 1.6-7.3 2.8-10.8.1-.3.6-.5.8-.5.8.2 1.6.3 2.4.6 1.8.7 3.9.5 5.6 1.8-.3 1.9-.7 3.7-1.1 5.5z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 145,
        x: 990,
        y: 669,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1005 664.8c1.1.3 2.8.8 4.4 1.3.2 0 .3.4.3.6-.1.7-.2 1.4-.3 2.1-.7 2.8-1.4 5.7-2.2 8.5-.1.6-.7.9-1.3.8-1.9-.5-3.8-1-5.8-1.4-.5-.1-1-.1-1.4-.3-.3-.1-.4-.5-.6-.8-.1-.2 0-.5 0-.7.8-3.1 1.5-6.1 2.3-9.2.1-.4.3-.9.5-1.3.1-.2.4-.3.6-.3 1.2.1 2.1.4 3.5.7z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 144,
        x: 1004,
        y: 673,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1019.9 681.6c-2.4-.6-4.7-1.2-7.1-1.8-.3-.1-.6-.4-.6-.8 0-.6.1-1.2.2-1.7.7-2.8 1.4-5.6 2.2-8.4.1-.3.3-.6.5-.9.1-.2.4-.4.6-.3.5 0 .9.2 1.4.3 2 .5 4 1 6.1 1.6.4.1.7.7.6 1.1-.1.5-.1.9-.2 1.4-.7 2.8-1.4 5.6-2.1 8.4 0 .1-.1.2-.1.3-.4.6-.9.9-1.5.8z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 143,
        x: 1018,
        y: 676,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1034 685.3c-2.8-.7-4.9-1.3-7.1-1.9-.4-.1-.7-.6-.6-1.1.6-2.4 1.1-4.8 1.7-7.1.3-1 .5-2 .8-3 .2-.6.7-.9 1.3-.8 1.8.4 3.6.9 5.4 1.4 2.7.7 2.5.4 1.9 3.3-.7 2.8-1.4 5.7-2.2 8.5-.1.5-.6.8-1.2.7-.3-.1-.5-.2 0 0z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 142,
        x: 1032,
        y: 680,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1046.2 699.4c-.6 2.1-1.1 4.3-1.7 6.4-.1.4-.7.9-1 .8-2.4-.6-4.7-1.2-7.1-1.9-.2-.1-.5-.2-.6-.3-.1-.2-.3-.4-.3-.6.9-3.5 1.8-7 2.7-10.5.1-.5.6-.8 1-.7l2.7.6c1.5.4 2.9.7 4.4 1.1.7.2 1 .7.8 1.3-.3 1.3-.6 2.5-.9 3.8z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 141,
        x: 1040,
        y: 701,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1062.6 681.6c-.1 1.8-.3 3.8-.5 5.7 0 .4-.5.8-.9.8-.3 0-.7 0-1 0-2.1-.2-4.2-.3-6.3-.5-.5 0-.9-.4-.8-.9.1-3 .2-6 .3-9.1 0-.7.2-1.4.4-2 0-.2.3-.3.5-.4.1-.1.2-.1.3-.1 2.4.1 4.9.3 7.3.4.4 0 .9.5.9.9v1c-.1 1.5-.1 2.7-.2 4.2z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 140,
        x: 1058,
        y: 684,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1067.9 682.6c.1-1.5.1-3 .2-4.5 0-.5.1-.9.2-1.4.1-.4.5-.9.9-.9 2.2.1 4.4.2 6.6.3.3 0 .7.2 1 .2.4.1.7.5.7 1v.7c-.4 3.1-.4 6.3-.5 9.4 0 .2 0 .5-.1.7s-.3.4-.5.4c-2.7-.1-5.3-.1-8-.3-.3 0-.6-.4-.6-.8 0-.9 0-1.9 0-2.8 0-.6 0-1.3.1-2-.1 0-.1 0 0 0z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 139,
        x: 1073,
        y: 685,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1091.4 683.1c-.1 1.9-.3 3.7-.4 5.6 0 .2-.5.6-.8.6-2.4-.1-4.9-.1-7.3-.2-.6 0-1-.4-1-1.1.1-3.1.2-6.3.3-9.4 0-.5.1-.9.2-1.4.1-.4.4-.6.7-.6 2.6-.1 5.1 0 7.6.3.4 0 .8.6.8 1 0 1.7-.1 3.4-.1 5.2z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 138,
        x: 1086,
        y: 685,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1096.6 683.6c.1-1.9.2-3.7.4-5.6 0-.4.5-.8 1-.8 2.3 0 4.7.1 7 .1 1 0 1.4.5 1.4 1.6v.3c-.2 3.3-.4 6.5-.6 9.8 0 .5-.4.9-.9.9-2.6-.1-5.1-.1-7.7-.3-.2 0-.4-.3-.5-.5-.1-.3-.2-.7-.1-1 0-1.5 0-3 0-4.5z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 137,
        x: 1101,
        y: 685,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1110.7 684.9c0-.1 0-.2 0-.4.5-8.4-.9-6.6 7.1-6.6.5 0 .9.1 1.4.1.7.1 1.2.5 1.1 1.1-.2 3.6-.4 7.2-.6 10.8 0 .2-.3.4-.5.5s-.5.1-.7.1c-2.2 0-4.4-.1-6.7-.1-.8 0-1.3-.6-1.3-1.3 0-1.4 0-2.8 0-4.2z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 136,
        x: 1115,
        y: 686,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1134.6 684.1c-.2 2.5-.3 4.4-.4 6.2 0 .4-.6.8-1 .8-2.3 0-4.7-.1-7-.1-.9 0-1.4-.6-1.4-1.6.1-2.4.3-4.9.4-7.3.1-.9.1-1.9.1-2.8 0-.4.5-.8.9-.8 2.4.1 4.9.1 7.3.2.4 0 .9.4.9.9.2 1.7.2 3.4.2 4.5z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 135,
        x: 1129,
        y: 686,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1149.4 694.7c0 .9-.1 1.8-.1 2.7-2.8 1-5.8.4-8.7.4-.6 0-.9-.6-1-1.2.4-.9.5-1.8.5-2.7.1-1.5.1-3 .1-4.6 0-.8 0-1.6 0-2.5.1-1.8.2-1.9 2.1-1.9 1.3 0 2.6.1 3.9.1.6 0 1.2 0 1.7-.1.2-.3.4-.3.5.1.7.4 1.2.9 1.1 1.9-.1 1.7 0 3.4-.1 5.1.3.8.4 1.8 0 2.7z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 134,
        x: 1145,
        y: 693,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1166 687.5c-.5 1.1-.4 2.3-.4 3.4-.1 2-.1 3.9-.1 5.9 0 .2-.1.5-.1.7-.1.5-.4.9-.9.9-1.5 0-3-.1-4.5-.1-4.3-.1-4 .5-3.7-3.9.1-2.1.1-4.2.2-6.3 0-.6 0-1.2.2-1.7.1-.3.3-.7.7-.7h3.5c1.3 0 2.6.1 3.8.2.9 0 1.2.8 1.3 1.6z',
      },
      type: 'comfort-wright-style',
      number: {
        n: 133,
        x: 1161,
        y: 695,
      },
    },
  ],
}
