<template>
  <div class="outer" id="genplan">
    <div class="right">
        <h1 data-aos="fade-up">Ген. план</h1>
    </div>
    <div class="inner">
      <map-svg
          ref="map"
          :in-modal="inModal"
          :areas="queues"
          @pointLocation="pointLocation"
          @unpointLocation="unpointLocation"
          @mousemove="mousemove"
          @areaClick="setActiveArea"
      />
    </div>

    <ul class="queues">
      <template
          v-for="(item, i) in queues"
          :key="i"
      >
        <li
            v-if="item.cabins.length"
            :class="{ _active: item.active }"
        >
          <button
            :disabled="!item.active"
            @click="item.active ? setActiveArea(item) : null"
          >
            {{ item.name }}
          </button>
        </li>
      </template>
    </ul>

    <teleport to="body">
      <div
          v-if="!inModal"
          :style="tooltipStyle"
          class="tooltip-custom"
          role="tooltip"
      >
        <div class="tooltip-inner"> {{ pointedLocation }}</div>
      </div>
    </teleport>

    <area-modal v-if="activeArea" :area="activeArea" @close="closeQueueModal"/>
  </div>
</template>

<script>
import queues from "../../public/assets/js/queues";
import cabinData from "../../public/assets/js/cabinData";
import { uniqBy } from "lodash";
import AreaModal from "@/components/AreaModal";
import MapSvg from "@/components/Map/MapSvg";
import { ref } from "vue";

export default {
  name: 'Map',
  components: {
    AreaModal,
    MapSvg
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const tooltipStyle = ref(null)
    const pointedLocation = ref(null)
    const showAreaModal = ref(false)
    const activeArea = ref(null)
    const map = ref()

    const getLocationName = (e) => {
      const node = e?.target
      const name = node?.attributes?.['data-name']?.value

      if (node && name && map.value) {
        tooltipStyle.value = {
          top: `${e.clientY}px`,
          left: `${e.clientX}px`,
          opacity: 1
        }
        return name
      } else return null
    }

    const pointLocation = (e) => {
      pointedLocation.value = getLocationName(e) || pointedLocation.value
    }

    const unpointLocation = () => {
      tooltipStyle.value = {...tooltipStyle.value, opacity: 0}
    }

    const mousemove = (e) => {
      tooltipStyle.value = {
        top: `${e.clientY}px`,
        left: `${e.clientX}px`,
        opacity: 1
      }
    }

    const route = document.location.href.split('/');

    const baseUrl = route[0] + '//' + route[2];

    if (route[3] === "queues") {
      const area = queues.find(queue => queue.id == route[4])
      console.log(area)

      if (area) {
        const types = uniqBy(area.cabins, 'type')
            .map(el => cabinData?.[el?.type])
            .filter(el => el)
        types.push(cabinData?.['sold'])

        activeArea.value = {
          ...area,
          cabinData: types
        }
      }
    }

    const setActiveArea = (area) => {
      location.replace(`${baseUrl}/queues/${area.id}`)
    }
    
    const closeQueueModal = () => {
      activeArea.value = null
      location.href = '#genplan'
    }

    return {
      queues,
      cabinData,
      tooltipStyle,
      pointedLocation,
      showAreaModal,
      activeArea,
      pointLocation,
      unpointLocation,
      map,
      mousemove,
      setActiveArea,
      closeQueueModal,
    }
  },
}
</script>

<style lang="scss" scoped>
.right {
  display: flex;
  justify-content: flex-end;
  max-width: 1312px;
  margin: auto;

  h1 {
    font-size: 120px;
    color: white;
    margin: 0;
  }

  @media only screen and (max-width: 900px) {
      h1 {
          font-size: 80px !important;
      }
  }

  @media only screen and (max-width: 650px) {
      h1 {
          font-size: 45px !important;
      }
  }
}
.outer {
  background: rgb(37, 39, 41);
  padding: 40px 20px;
  position: relative;

  @include tablet_desktop {
    padding-bottom: 80px;
  }

  .inner {
    max-width: 1312px;
    margin: auto;
    // padding: 0 20px;
    position: relative;

    @media (min-width: 768px) {
      // padding: 48px;
    }
  }
}

.tooltip-custom {
  position: fixed;
  pointer-events: none;
  transform: translateX(10px) translateY(10px);
  opacity: 0;
  background: black;
  border-radius: 5px;
  padding: 6px 10px;
  display: none;

  @media (hover) {
    display: block;
  }

  .tooltip-arrow {
    position: absolute;
    border: none;
    width: 8.5px;
    height: 8.5px;
    background: black;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: calc(100% - 4.25px);
  }

  .tooltip-inner {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
}

.queues {
  color: white;

  li {
    &:not(._active) {
      opacity: .5;
    }
  }

  button {
    background: transparent;
    color: inherit;
    padding: 5px 0;
    border: none;
    font-size: 16px;

    &:focus-visible {
      text-decoration: underline;
    }
  }

  @media (hover) {
    display: none;
  }
}
</style>
