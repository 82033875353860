export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '204,281,273,474,389,525,480,312,493,308,625,355,644,349,611,292,428,284,379,269'
    }
  },
  name: 'Очередь 4',
  id: 4,
  imgForModal: {
    img: '/assets/area4.svg',
    viewBox: {
      x: '191',
      y: '260',
      width: '468.95',
      height: '277.17',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm245.3 295.7.6.3c.1.1.2.2.2.3.9 2.3 1.7 4.6 2.5 6.9.2.5-.1.9-.6 1.1s-1.1.5-1.6.7c-2.8 1.1-5.7 2.1-8.5 3.2-.4.2-1 0-1.2-.4-.2-.5-.4-1.1-.7-1.6-1.9-4.9-2.2-5.9-1.9-6.2 1.7-1.2 3.8-1.6 5.7-2.4 1.4-.6 2.8-1.1 4.2-1.6.7-.3 1-.3 1.3-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm242.3 321.1c-.2-.2-.5-.3-.6-.4-.9-2.4-1.8-4.8-2.7-7.2-.1-.2.2-.8.4-.9.8-.3 1.5-.6 2.3-.9 2.6-1 5.2-1.9 7.8-2.9.9-.3 1.2-.1 1.5.8.8 2.2 1.6 4.4 2.4 6.5.2.4-.1 1-.5 1.2-3.3 1.2-6.5 2.4-9.8 3.6-.1.2-.5.2-.8.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm255.3 322.2c.2.2.4.3.5.4.3.5.5 1.1.7 1.6.5 1.3.9 2.6 1.5 3.9 1.3 2.9.4 2.5-1.3 3.3-2.6 1.1-5.2 2-7.8 2.9-.3.1-.7.2-1 .3-.4.1-1-.1-1.2-.5-.9-2.4-1.8-4.8-2.6-7.2-.1-.2.1-.5.3-.6.3-.2.6-.3.9-.5 2.9-1.1 5.8-2.2 8.8-3.3.3-.2.8-.2 1.2-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm263.6 343.8c-.1.2-.3.4-.4.5-.4.2-.8.4-1.2.6-2.4.9-4.8 1.8-7.2 2.7-2.9 1-2.6 1.4-3.7-1.6-.7-1.7-1.3-3.5-1.9-5.2-.1-.4.1-.9.5-1.1.1-.1.2-.1.3-.2 3.3-1.2 6.5-2.4 9.8-3.6.4-.2 1.1.1 1.2.4 1 2.4 1.9 4.7 2.6 7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm265.1 349c.2.1.6.3.7.5.9 2.4 1.8 4.8 2.6 7.2.1.4-.2.7-.4.8-.4.2-.8.4-1.3.6-1.1.4-2.2.8-3.3 1.2-1.4.5-2.8 1.1-4.2 1.6-2.5.8-2.2 1.2-3.4-1.7-.6-1.4-1.1-2.8-1.6-4.2-.2-.4-.3-.9-.4-1.3 0-.2.2-.4.3-.6.2-.2.4-.3.6-.4 3.1-1.2 6.3-2.3 9.4-3.5.4-.1.7-.1 1-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm273.3 370c-.1.2-.3.6-.5.7-2.7 1-5.4 2-8.1 3-.7.2-1.3.5-2 .7-.6.2-1.1-.1-1.4-.7-.8-2.2-1.6-4.3-2.4-6.5-.3-.7-.1-1 .6-1.3 3.4-1.2 6.7-2.5 10.1-3.7.4-.2 1 .1 1.1.5.9 2.3 1.7 4.6 2.5 6.8.1.2.1.3.1.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm267.2 387.9c-.3-.2-.6-.2-.7-.4-.5-1.2-1-2.3-1.4-3.5-1.8-5.3-2.4-3.9 2.7-5.9 1.7-.7 3.5-1.3 5.2-1.9.5-.2 1.1-.3 1.7-.5.3-.1 1 .3 1.1.6.2.5.5 1.1.6 1.6.5 2 1.9 3.7 1.7 5.9-1.7 1.1-3.7 1.4-5.5 2.3-1.4.6-2.8 1.1-4.3 1.6-.3.1-.7.2-1.1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm283.1 396.7c-.1.1-.1.3-.2.3-.2.2-.3.3-.5.4-3.3 1.2-6.5 2.4-9.8 3.6-.6.2-1.2 0-1.4-.6-.8-2.1-1.5-4.2-2.3-6.2-.6-1.5-.4-1.4 1-2 3.1-1.3 6.3-2.4 9.5-3.5.5-.2 1 0 1.1.5.9 2.3 1.7 4.6 2.6 6.9.1.1 0 .4 0 .6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm285 402.2c.2.1.5.2.5.4.5 1.3 1 2.6 1.5 3.9 1.7 4.6 2 3.8-2.4 5.5-2.1.8-4.1 1.6-6.2 2.3-.4.2-.9.2-1.3.2-.2 0-.5-.1-.6-.3-1.2-2.4-2-4.9-2.8-7.5 0-.2.1-.4.3-.6s.4-.2.6-.3c3.3-1.2 6.5-2.4 9.8-3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm278.4 420.1c.2-.3.2-.6.4-.7l.9-.6c3.2-1.2 6.3-2.3 9.5-3.5.6-.2 1.1 0 1.4.7.8 2.2 1.6 4.4 2.4 6.5 0 .1 0 .2.1.3.1.3-.3 1-.6 1.1-3.4 1.2-6.8 2.5-10.1 3.7-.3.1-.8.1-.9-.3-1.2-2.4-2.1-4.9-3.1-7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm284.2 432.7c.3.1.5.2.7.3.1 0 .2.1.2.2 1 2.2 2 4.5 2.9 6.7.2.4 0 1.1-.4 1.2-3.3 1.4-6.6 2.9-9.9 4.3-.6.2-1.1 0-1.4-.6-.2-.4-.4-.8-.6-1.3-2.4-5.3-2.6-5.8-2.3-6.1 1.7-1.2 3.7-1.8 5.6-2.7 1.4-.6 2.7-1.2 4.1-1.8.5-.1.8-.2 1.1-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm307.6 443.7c-.1.2-.2.4-.4.6-.8.9-1.5 1.8-2.3 2.6-1 1.1-2 2.2-3 3.4-2.7 3.1-2.1 3-5.3.2-1.1-1-2.3-2-3.4-3-.4-.3-.4-.9-.1-1.2 2.4-2.7 4.8-5.4 7.2-8.1.5-.5.9-.5 1.5 0 1.8 1.6 3.6 3.3 5.4 4.9.3.1.4.3.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm311.9 461.6c-1.7-1.2-3.7-2-5.5-3.1-.4-.2-.8-.5-1.2-.7-.2-.2-.3-.6-.2-.9 1.7-3.2 3.4-6.4 5.2-9.5.3-.6.8-.7 1.5-.4.3.2.6.3.9.5 1.9 1 3.7 1.9 5.6 2.9.6 4.8-2.4 8-5 11.4-.3.2-.9 0-1.3-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm330.1 456.6v.3c-1.5 3.4-2.9 6.8-4.4 10.2-.1.3-.6.5-.9.4-.1 0-.2-.1-.3-.2-2-.8-4.1-1.7-6.1-2.5-1.1-.5-1.3-.9-.9-1.8 1.3-3 2.5-6 3.8-9 .1-.2.2-.4.4-.6.2-.4.7-.6 1.2-.4 2.3 1 4.7 2 7 3 .1.1.1.4.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm343.1 463.1c-1.2 2.9-2.7 6.2-4.1 9.5-.2.5-.8.7-1.4.4-.1 0-.2-.1-.3-.1-.2-.1-.5-.1-.7-.2-2-1.1-4.4-1.5-6.2-3.1.8-2 1.6-4.1 2.5-6.1.5-1.3 1.1-2.6 1.6-3.9.5-1 1-1.3 1.9-.9 2.1.9 4.3 1.8 6.4 2.8.5.2.7.6.3 1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm349.8 464.4c-.5 0-.4-.1-.3 0 2.2.9 4.5 1.9 6.7 2.9.5.2.8.9.5 1.5-.6 1.4-1.2 2.8-1.8 4.2s-1.1 2.8-1.7 4.2c-1.1 2.6-.9 2.2-3.6 1.2-1.4-.5-2.8-1.2-4.1-1.8-.4-.2-.8-.4-1.2-.7-.2-.1-.2-.4-.3-.6 0-.1 0-.2.1-.3 1.4-3.3 2.8-6.6 4.3-9.9.1-.6.4-.8 1.4-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm362.9 470.1c.2.1.3.1.4.1 2.2 1 4.5 1.9 6.7 2.9.7.3.8.7.5 1.4-1.4 3.2-2.7 6.4-4.1 9.6-.2.5-.9.8-1.5.5-2.2-1-4.5-1.9-6.7-2.9-.3-.1-.6-.4-.5-.8 1.4-3.4 2.9-6.9 4.4-10.3.3-.1.6-.3.8-.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm384.2 479.8v.3c-1.5 3.4-2.9 6.8-4.4 10.2-.1.3-.6.4-.9.3-.1 0-.2-.1-.3-.2-.3-.1-.7-.2-1-.3-4.4-1.8-6.2-2.7-6.1-3.1.4-2.2 1.6-4.1 2.4-6.1.5-1.4 1.2-2.8 1.8-4.1.3-.7.7-.9 1.4-.7 2.3 1 4.7 2 7 3 0 .2.1.5.1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm385.4 492.5c1.4-3.3 2.9-6.6 4.3-9.9.3-.6.7-.8 1.4-.5.1 0 .2.1.3.2.2.1.4.2.6.3 5.6 2.3 6.2 2.6 6.1 3.1-.5 2.2-1.6 4.1-2.4 6.1-.5 1.3-1.1 2.6-1.6 3.9-.4 1-1 1.3-1.9.9-2.1-.9-4.3-1.8-6.4-2.8-.3-.3-.5-.8-.4-1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm274 291.5c.4 0 .7.1.8.5.9 2.3 1.7 4.6 2.6 6.8.2.6-.1 1.1-.7 1.4-.2.1-.4.2-.6.3-3 1.1-6.1 2.3-9.1 3.4-.6.2-1.1 0-1.4-.6 0-.1-.1-.2-.1-.3-.8-2.2-1.6-4.4-2.4-6.5-.1-.3.2-1 .6-1.1 1.2-.5 2.4-.9 3.6-1.3 2.1-.8 4.1-1.6 6.2-2.3.1-.2.3-.2.5-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm269 312.7c.1-.4.2-.6.3-.8.3-.2.6-.4.9-.5 3.2-1.2 6.3-2.3 9.5-3.5.7-.3 1-.1 1.3.6.2.4.4.8.5 1.3.4 1 .7 2 1 3 1.4 3.8 1.8 3.1-2.3 4.7-2.2.8-4.3 1.6-6.5 2.5-.3.1-.7.2-1 .3-.5.1-1-.1-1.1-.5-.8-2.4-1.7-4.8-2.6-7.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm289.9 333c-.1.2-.2.5-.4.5-3.4 1.3-6.7 2.5-10.1 3.7-.1 0-.2.1-.3.1-.5.1-.9-.1-1.1-.6 0-.1-.1-.2-.2-.3-.8-2.2-1.6-4.4-2.4-6.5-.2-.4.1-1 .5-1.2 3.3-1.2 6.5-2.4 9.8-3.6 1-.4 1.4-.2 1.8.9.8 2.1 1.5 4.1 2.3 6.2.1.3.1.6.1.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm296.9 351.4c-.1.2-.2.4-.4.5-.4.2-.8.5-1.2.6-2.9 1.1-5.9 2.2-8.8 3.2-.9.3-1.4.2-1.7-.6-.7-2-1.5-3.9-2.2-5.9-.5-1.4-.3-1.8 1.1-2.3 3-1.1 6.1-2.3 9.1-3.4.6-.2 1.2 0 1.4.6.8 2.3 1.7 4.6 2.5 6.9.1.2.1.3.2.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm303 368.3c-.1.1-.1.2-.2.3-.4.2-.8.5-1.2.7-3 1.1-6.1 2.2-9.1 3.3-.6.2-1.3 0-1.4-.5-.8-2.3-1.7-4.6-2.5-6.9-.1-.4.2-1 .5-1.2.1 0 .2-.1.3-.1 3.1-1.2 6.3-2.3 9.4-3.5.8-.3 1.4 0 1.7.7.8 2.2 1.6 4.4 2.4 6.5.1.3.1.5.1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm309.9 386.2c-.1.4-.2.6-.3.8-.3.2-.6.4-.9.5-3.2 1.2-6.3 2.3-9.5 3.5-.7.2-1.1.1-1.3-.7-.9-2.3-1.7-4.6-2.6-6.9-.1-.3.2-1 .6-1.1 3.4-1.3 6.8-2.5 10.1-3.7.4-.1 1 .1 1.2.5.9 2.4 1.8 4.8 2.7 7.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm312.8 395.4c.2.1.4.2.5.4.9 2.4 1.8 4.8 2.7 7.2.1.3-.1.8-.4.9-.9.3-1.7.7-2.6 1-2.6 1-5.2 1.9-7.8 2.9-.3.1-.8.1-.9-.3-1-2.5-1.9-5-2.8-7.5-.1-.2.2-.7.5-.8 3.4-1.3 6.8-2.5 10.1-3.7.2-.1.4-.1.7-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm324.9 420.4c-.1.1-.1.2-.2.3s-.2.2-.2.2c-3 2-6 4-9 6-.5.3-1.1.1-1.5-.4-1.3-1.9-2.6-3.9-3.8-5.8-.4-.6-.3-1 .3-1.5 1.4-1 2.9-1.9 4.4-2.9 1.2-.8 2.3-1.5 3.5-2.3 1.8-1.2 1.8-1.3 3.2.8.8 1.2 1.6 2.3 2.3 3.5.4.6.7 1.2 1 1.8-.1 0-.1.1 0 .3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm340.7 430c0 .2.1.5 0 .7-1.4 3.3-2.8 6.6-4.2 9.9-.1.3-.8.6-1.2.4-2.3-1-4.7-2-7-3.1-.2-.1-.2-.4-.3-.6 0-.1 0-.2 0-.3 1.5-3.4 2.9-6.8 4.4-10.3.1-.2.7-.5.9-.3 2.3 1 4.7 2 7 3 .1.2.2.5.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm357.7 437.3c0 .2 0 .5-.1.7-1.4 3.2-2.8 6.4-4.2 9.6-.3.6-.8.8-1.4.6-1.7-.7-3.4-1.4-5.2-2.2-2.2-.9-2.4-1-1.3-3.3 1.3-2.9 2.5-5.8 3.8-8.7.1-.2.7-.4.9-.4 2.4 1 4.7 2 7 3 .2.3.3.6.5.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm374.9 444.7c0 .2 0 .5-.1.7-1.4 3.2-2.7 6.4-4.1 9.6-.3.8-.8.9-1.7.5-2.1-.9-4.3-1.8-6.4-2.7-.4-.2-.6-.8-.5-1.2 1.4-3.2 2.7-6.4 4.1-9.6.3-.8.9-1.1 1.7-.8 2.2 1 4.5 1.9 6.7 2.9.1.1.2.4.3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm392.3 452c0 .2 0 .3-.1.4-.2.5-.4 1.1-.6 1.6-1 2.5-2.1 4.9-3.1 7.4-.2.4-.4.8-.6 1.3s-.6.6-1.2.4c-1.1-.4-2.2-.9-3.2-1.3-1.2-.5-2.3-1-3.5-1.5-.5-.2-.7-.7-.5-1.1 1.5-3.4 2.9-6.8 4.4-10.2.1-.3.6-.4.9-.3 2.3 1 4.7 2 7 3.1.2-.1.3.1.5.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm401.6 455.8c.2 0 .4 0 .5.1 2.3 1 4.7 2 7 3 .2.1.4.7.3.9-.5 1.3-1.1 2.6-1.6 3.9-.7 1.6-1.4 3.2-2 4.8-1.1 2.7-.9 2.3-3.9 1.1-1.6-.7-3.2-1.4-4.8-2.1-.3-.1-.6-.8-.4-1.2 1.4-3.3 2.8-6.6 4.3-9.9 0-.2.4-.4.6-.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm313 291.8c.3.8.7 1.7 1.1 2.5.7 1.7 1.3 3.5 1.9 5.2.1.2-.2.8-.4.9-3.4 1.3-6.7 2.5-10.1 3.8-.1 0-.2 0-.3.1-.4.1-.9-.1-1.1-.6-.9-2.3-1.7-4.6-2.6-6.9-.1-.2.1-.6.3-.9.1-.2.4-.2.6-.3 3.3-1.2 6.5-2.4 9.8-3.6.2-.1.4-.1.8-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm309.4 318.4c-.2-.5 0-.9.5-1.1s1.1-.4 1.6-.6c2.8-1.1 5.6-2.2 8.5-3.2.6-.2 1.2 0 1.4.7.9 2.4 2 4.7 2.5 7.2.1.5-1.3 1.1-6.9 3.1-1.2.4-2.4.9-3.6 1.3-.8.3-1.2.1-1.6-.8-.8-2.3-1.6-4.5-2.4-6.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm319.5 344c-.2-.1-.4-.2-.6-.3-.2-.2-.3-.4-.4-.6-.8-2.2-1.6-4.4-2.4-6.5-.2-.6.1-1.2.7-1.4 3.3-1.2 6.5-2.4 9.8-3.6.6-.2 1.2.1 1.4.6.3.6.5 1.3.7 2 .5 1.2.9 2.4 1.3 3.6.7 2 .7 2.1-1.2 2.8-1.2.5-2.4.9-3.6 1.3-1.7.6-3.5 1.3-5.3 1.9 0 .1-.2.1-.4.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm325.7 360.9c-.2-.1-.4-.2-.6-.3-.2-.2-.3-.4-.4-.6-.8-2.1-1.5-4.1-2.3-6.2-.3-.8-.1-1.5.6-1.7 3.3-1.2 6.5-2.4 9.8-3.6.6-.2 1.1.1 1.4.7.1.3.3.6.4 1 2.1 5.5 2.4 6.3 2 6.5-1.9 1.3-4 1.7-6.1 2.5-1.4.5-2.8 1.1-4.2 1.6-.1 0-.3.1-.6.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm331.4 377c-.1 0-.2-.1-.2-.2-.3-.6-.7-1.2-.9-1.9-2.2-5.9-2.2-5.9-1.9-6.2 1.7-1.2 3.8-1.6 5.8-2.3 1.5-.6 3-1.2 4.6-1.7.8-.3 1.3 0 1.6.8.8 2.2 1.6 4.4 2.4 6.5.2.5-.1.9-.5 1.1-.2.1-.4.3-.6.3-3.2 1.2-6.3 2.3-9.5 3.5-.2.1-.5.1-.8.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm340.3 383c-.4.1-.2 0 0 0s.5.1.6.3c.2.1.3.4.4.6.2.5.4 1.1.6 1.6 1.9 5 2.1 5.6 1.7 5.9-1.6 1.1-3.6 1.5-5.4 2.2-1.7.7-3.5 1.3-5.2 1.9-.4.1-1-.2-1.2-.6-.8-2.3-1.6-4.6-2.4-6.9-.2-.5 0-.9.5-1.1s1.1-.5 1.6-.7c2.7-1 5.4-2 8.8-3.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm359.5 396.6c-.2.3-.4.6-.6.9-2.5 2.5-5 4.9-7.4 7.4-.5.5-.9.5-1.5-.1-.3-.2-.5-.5-.7-.7-4.7-4.7-4.8-4.7-4.5-5.1 1.2-1.9 3-3.2 4.5-4.8 1-1 2-2 3-3 .9-.9 1.3-.9 2-.1l4.7 4.7c.1.2.3.5.5.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm371.6 404.7v.7c-1.4 3.3-2.8 6.6-4.3 9.9-.1.3-.9.6-1.2.4-2.2-.9-4.5-1.9-6.7-2.9-.4-.2-.6-.8-.5-1.2 0-.1.1-.2.1-.3 1.4-3.2 2.8-6.4 4.1-9.6.2-.5.8-.8 1.1-.6 2.3 1 4.7 2 7 3 .2.2.3.5.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm388.8 412.2c0 .1 0 .2-.1.3-1.4 3.3-2.8 6.6-4.2 10 0 .1-.1.2-.2.3-.2.3-.4.5-.9.3-2.4-1-4.7-2-7.1-3-.5-.2-.6-.7-.4-1.2 1.4-3.3 2.8-6.6 4.3-10 .1-.3.8-.6 1.2-.4 2.3 1 4.7 2 7 3.1.2.1.3.4.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm406.1 419.5c0 .2.1.5 0 .7-1.4 3.3-2.8 6.6-4.2 9.9-.2.4-.8.6-1.2.5-2.2-.9-4.5-1.9-6.7-2.9-.4-.2-.7-.8-.6-1.2.1-.2.1-.5.2-.7 1.3-3.1 2.7-6.2 4-9.3.2-.5.8-.8 1.1-.7 2.3 1 4.7 2 7 3 .1.2.2.5.4.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm415.2 423.3h.5c2.4 1 4.7 2 7.1 3 .5.2.7.6.4 1.1-1.4 3.3-2.9 6.6-4.3 9.9-.2.5-.7.7-1.1.5-2-.8-4.1-1.6-6.1-2.5-1.6-.7-1.6-.7-.9-2.3 1.3-3 2.6-6 3.8-9 .2-.3.5-.6.6-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm352.2 350.3c-.2-.1-.5-.1-.6-.3-.2-.3-.3-.6-.5-.9-.7-1.9-1.4-3.7-2.1-5.6-.5-1.3-.7-1.7 1-2.2 3.1-1.1 6.1-2.3 9.1-3.4.6-.2 1.3.1 1.5.6.9 2.3 1.7 4.6 2.5 6.9.1.4-.1.7-.3.9-.3.2-.6.4-.9.5-3.2 1.2-6.3 2.3-9.5 3.5 0 0-.1 0-.2 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm368.8 361c-.1.2-.2.5-.4.6-.6.3-1.3.6-1.9.8-2.4.9-4.8 1.8-7.2 2.6-.4.2-.9.3-1.3.4s-.9-.2-1.1-.6c-.1-.3-.3-.6-.4-1-.6-1.5-1.2-3-1.7-4.6-.7-2.2-.9-2 1.1-2.8 3-1.2 6.1-2.2 9.1-3.3.5-.2 1 0 1.2.4.9 2.4 1.8 4.8 2.6 7.2.1.1.1.2 0 .3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm374.1 371.7c.4.2.9.4 1.2.6 1.7 1.2 3.5 2.4 5.2 3.6.9.7 1 1 .3 2-.8 1.2-1.5 2.3-2.4 3.4-1.4 1.8-2.3 4.1-4.2 5.5-1.9-1.3-3.9-2.6-5.8-3.9-.4-.3-.7-.6-1.1-.9-.1-.1-.1-.4-.1-.6s.2-.4.3-.6c1.9-2.8 3.9-5.6 5.8-8.3.3-.2.5-.5.8-.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm393.8 396.6c-.2 0-.3-.1-.4-.1-2.3-.9-4.5-1.9-6.8-2.8-.9-.4-1-.6-.6-1.6 1.4-3.2 2.7-6.4 4.1-9.7.2-.4.7-.6 1.1-.4 2.2 1 4.5 1.9 6.7 2.9.7.3.8.6.5 1.3-1.4 3.2-2.7 6.4-4.1 9.7.1.2-.3.5-.5.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm403.4 400.5c0-.3-.1-.5 0-.7 1.4-3.3 2.8-6.6 4.2-10 .2-.4.8-.6 1.2-.5 2.2 1 4.5 1.9 6.7 2.9.4.2.8.8.6 1.1-1.4 3.3-2.8 6.6-4.2 10-.3.7-.8.9-1.4.6-2.3-.9-4.5-1.9-6.7-2.9-.2-.1-.3-.4-.4-.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm426.6 397h.4c2.3 1 4.7 2 7 3.1.5.2.6.6.4 1.1-1.3 3.1-2.6 6.2-3.9 9.3-.1.2-.2.4-.4.6-.2.4-.7.7-1.2.5-2.3-1-4.7-2-7-3-.2-.1-.4-.7-.3-.9 1.4-3.3 2.8-6.6 4.3-10 .1-.3.5-.5.7-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm389.1 330.2c.2.1.5.1.6.3.3.5.5 1 .7 1.6.7 1.9 1.4 3.7 2.1 5.6.2.5 0 .9-.5 1.2-.2.1-.4.2-.6.3-3.2 1.2-6.3 2.3-9.5 3.5-.6.2-1.1 0-1.4-.6-.9-2.3-1.7-4.6-2.6-6.9-.1-.4.1-.7.4-.9.2-.2.4-.3.6-.4 3.2-1.2 6.3-2.3 9.5-3.5.2-.1.5-.1.7-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm411.9 354.4c-.1.8-.1 1.1-.2 1.3-1.4 3.2-2.7 6.4-4.1 9.6-.3.7-.7.8-1.4.5-.1 0-.2-.1-.3-.2-.2-.1-.4-.2-.6-.3-2-.9-4.1-1.5-6-2.7-.5-1.3.6-2.2.9-3.2.3-1.1.9-2.1 1.3-3.2.6-1.4 1.2-2.8 1.8-4.1.3-.8.9-1 1.7-.6 2.1.9 4.3 1.8 6.4 2.8.2-.1.5.2.5.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm429.2 362.1c0 .2 0 .5-.1.7-1.4 3.2-2.7 6.4-4.1 9.7-.3.7-.8.9-1.4.7-2.2-.9-4.3-1.8-6.4-2.7-.8-.3-.9-.9-.6-1.7.6-1.4 1.1-2.8 1.8-4.2 1-2 1.3-4.2 2.9-5.9 2.2.4 4.1 1.6 6.2 2.4.5.2 1 .5 1.5.8.1 0 .2.1.2.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm447.6 370c0 .2 0 .5-.1.7-1.4 3.2-2.7 6.4-4.1 9.7-.3.8-.7.9-1.3.7-2.2-.9-4.3-1.8-6.4-2.7-.8-.3-1-.9-.6-1.7 1.3-3.1 2.7-6.2 4-9.3.3-.6.8-.9 1.4-.6 2.3.9 4.5 1.9 6.7 2.9.1-.1.2.1.4.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm418.7 337.6c-.2 0-.3.1-.4.1-2.4-1-4.7-2-7-3-.4-.2-.7-.7-.5-1.1 1.4-3.2 2.7-6.4 4.1-9.6.4-.9 1-1.1 2-.7 1.9.8 3.8 1.7 5.8 2.5.9.4 1.2.9.7 1.9-1.4 3.1-2.7 6.2-4 9.3-.2.2-.5.4-.7.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm441.8 334.3c0 .2.1.5 0 .7-1.4 3.3-2.8 6.6-4.2 9.9-.2.4-.8.7-1.2.5-2.2-.9-4.5-1.9-6.7-2.9-.5-.2-.7-.9-.5-1.5 1.4-3.2 2.7-6.4 4.1-9.6.3-.6.8-.8 1.4-.6 2.2 1 4.5 1.9 6.7 2.9.2.2.3.5.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm459.5 342c0 .2 0 .5-.1.7-1.4 3.3-2.8 6.6-4.3 9.9-.2.5-.6.6-1.1.4-2.1-.9-4.3-1.8-6.4-2.7-.8-.3-1.1-.9-.8-1.6 1.4-3.2 2.7-6.4 4.1-9.6.2-.6.8-.8 1.5-.5 2.2 1 4.5 1.9 6.7 2.9.3 0 .3.3.4.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm453.3 307.9c0 .2 0 .4-.1.6-1.4 3.2-2.7 6.4-4.1 9.6-.2.5-.7.8-1.1.6-2.2-1-4.5-1.9-6.7-2.9-.1 0-.2-.1-.3-.2-.4-.2-.6-.8-.4-1.2 1.4-3.2 2.8-6.4 4.1-9.6.3-.6.9-.9 1.4-.7 2.2.9 4.5 1.9 6.7 2.9.3.1.5.4.5.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm471.1 315.2c0 .1 0 .2-.1.3-1.4 3.3-2.8 6.7-4.2 10-.4.9-.7.9-1.3.6-2.2-1-4.5-1.9-6.7-2.9-.5-.2-.7-.7-.5-1.1 1.4-3.3 2.9-6.6 4.3-10 .2-.4.8-.7 1.1-.5 2.4 1 4.7 2 7.1 3 .1.2.2.4.3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm507.3 305.4c-1.6-.6-4-1.4-6.4-2.3-.2-.1-.2-.4-.3-.6 0-.1 0-.2 0-.3 1.1-3.3 2.2-6.6 3.3-9.9.1-.2.2-.4.4-.6.2-.3.5-.5.9-.4 1.8.6 3.6 1 5.3 1.7 2.4 1 3 .3 1.8 3.6-1 2.7-1.9 5.5-2.8 8.2-.4.7-.8.9-2.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm529.7 300.1c0 .3 0 .6-.1.8-1.1 3.1-2.1 6.1-3.2 9.2-.5 1.5-.7 1.6-2.3 1-1.2-.4-2.4-.8-3.6-1.3-3.8-1.4-3.5-.6-2.2-4.4.8-2.4 1.6-4.8 2.4-7.3.3-1 .9-1.3 1.9-1 2.2.7 4.4 1.5 6.6 2.2.2.2.3.6.5.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm547.9 306.4c0 .2 0 .5-.1.7-1.1 3.3-2.3 6.6-3.4 9.9-.1.4-.7.7-1.1.6-.6-.1-1.1-.3-1.7-.5-1.9-.7-4-1.2-5.8-2.2-.5-1.2.3-2.2.6-3.2s.6-2 1-3c.5-1.5 1.1-3.1 1.6-4.6.2-.6.8-1 1.3-.8 2.4.8 4.8 1.6 7.2 2.5.2.1.3.4.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm555 321.7c-.3-.1-1.4-.4-2.5-.8-1-.4-1.4-.9-1-1.8 1.1-3.3 2.3-6.6 3.4-9.9.2-.5.7-.8 1.1-.6 2.3.8 4.6 1.6 6.9 2.4.6.2.9.7.7 1.4-1.1 3.3-2.2 6.6-3.4 9.9-.3.7-.7.9-1.3.7-1.1-.3-2.1-.7-3.9-1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm569.5 313.3h.3c2.3.8 4.6 1.6 6.9 2.4.5.2.8.9.6 1.5-.5 1.4-1 2.9-1.4 4.3-1.9 5.5-2.2 6.4-2.6 6.3-2.3-.4-4.4-1.4-6.6-2.1-.4-.1-.9-.4-1.2-.6-.2-.1-.3-.4-.3-.6 0-.3.1-.7.2-1 1-3.1 2.1-6.2 3.1-9.2.1-.2.2-.4.3-.6.3-.2.5-.3.7-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm591.8 321.4c-.1.4-.2.8-.3 1.1-1 3-2 5.9-3 8.9-.1.3-.3.6-.5.9s-.5.5-.9.3c-2.3-.8-4.6-1.6-6.9-2.4-.7-.2-.9-.7-.6-1.4.1-.3.2-.7.3-1 .6-1.8 1.1-3.5 1.8-5.3.6-1.5.7-3.2 1.9-4.4 1.5.1 2.9.9 4.3 1.3 1.1.3 2.2.7 3.3 1.1.3.3.4.7.6.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm617.1 340.7c-.2-.1-.5-.1-.6-.3-.7-1.1-1.3-2.2-2-3.3-.5-.8-.9-1.6-1.4-2.4-.7-1.2-.9-1.7.6-2.5.8-.4 1.6-.9 2.4-1.4 1.8-1 3.6-2.1 5.5-3.1.3-.2.6-.4.9-.4s.8 0 .9.2c.4.4.7.9 1 1.4 2.8 4.8 3.2 5.5 2.9 5.8-1.5 1.5-3.5 2.2-5.3 3.3-1.4.8-2.8 1.6-4.2 2.5-.2.1-.5.1-.7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm597.9 307.2c-.2-.1-.5-.1-.6-.3-.8-1.3-1.6-2.6-2.3-3.9-2.3-4-2.3-3.1 1.4-5.3 2.2-1.3 4.4-2.6 6.6-3.8.6-.4 1.1-.4 1.5.2 1.3 2.2 2.5 4.4 3.8 6.7.1.1 0 .5-.1.6-.1.2-.3.4-.5.5-3 1.7-6 3.5-9.1 5.2-.2.1-.5.1-.7.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm613.6 310.5c.1.1.2.1.3.2s.2.1.2.2c1.3 2.2 2.6 4.4 3.9 6.7.1.2 0 .7-.3.9-.7.4-1.4.8-2.1 1.3-.9.5-1.8 1.1-2.7 1.6-1.9.9-3.4 2.4-5.5 2.8-1.4-1.6-2.2-3.6-3.3-5.4-.4-.6-.7-1.2-.9-1.9-.1-.2 0-.4.2-.6.1-.2.4-.3.6-.4 3-1.8 6.1-3.5 9.1-5.2 0-.1.3-.1.5-.2z',
      },
    },
  ],
}
