export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '1117,278,1112,349,1091,373,1013,406,972,444,952,453,879,457,696,292,680,292,672,303,733,409,865,531,968,521,1000,512,1019,494,1044,522,1051,514,1197,523,1201,490,1190,480,1200,271,1177,250'
    }
  },
  name: 'Очередь 4',
  id: 8,
  imgForModal: {
    img: '/assets/area4-2.svg',
    viewBox: {
      x: '660',
      y: '243',
      width: '566.64',
      height: '300.64',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm691.5 315c-.1 0-.2 0-.3-.1-.2-.1-.4-.2-.6-.3-.6-.5-1.2-1.1-1.8-1.7-3.7-3.5-3.9-3.7-3.7-4 .9-1.5 2.3-2.6 3.4-3.9 1.3-1.5 2.7-2.9 4.1-4.3.6-.7 1-.7 1.8 0 1.7 1.6 3.4 3.2 5.1 4.8.4.3.4.9.1 1.2-2.5 2.6-4.9 5.3-7.4 7.9-.3.2-.5.3-.7.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm722.6 376.6c-.2-.1-.4-.2-.6-.4-1.4-2.2-2.7-4.3-4-6.5-.1-.2 0-.7.3-.9.7-.6 1.5-1 2.3-1.5 2.3-1.4 4.5-2.8 6.8-4.2.6-.4 1.2-.3 1.5.2 1.3 2.1 2.5 4.2 3.8 6.2.2.4.1 1-.3 1.2-3.1 1.9-6.1 3.8-9.2 5.7-.1.1-.2.1-.3.1 0 .1-.1.1-.3.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm739.7 381.5c.1 0 .3 0 .3.1.4.5.7.9 1 1.4.8 1.3 1.6 2.6 2.4 3.9 1.3 2.2 1.7 1.9-.7 3.4-1.3.8-2.6 1.6-3.9 2.4-1.4.9-2.8 1.7-4.2 2.6-.3.2-.8.2-.9-.1-1.4-2.3-2.9-4.5-4.2-6.8-.1-.1 0-.4.1-.6s.3-.4.5-.5c3-1.8 6-3.7 8.9-5.5.2-.3.5-.3.7-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm756.6 404.3c-.1.2-.2.4-.3.6-1.7 1.8-3.3 3.6-5 5.3-.8.8-1.6 1.7-2.4 2.5-.3.3-.9.4-1.3 0-1.8-1.8-3.7-3.5-5.5-5.3-.2-.2-.2-.7.1-.9.6-.7 1.2-1.4 1.9-2.1 1.7-1.8 3.4-3.5 5.1-5.3 1.1-1.2 1.3-1.2 2.5-.1 1.6 1.5 3.2 3.1 4.8 4.6 0 .2 0 .5.1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm768.5 415.9c0 .2 0 .5-.1.6-2.6 2.7-5.1 5.4-7.7 8.1-.2.2-.8.3-1 .1-1.9-1.8-3.9-3.7-5.8-5.6-.1-.1-.1-.5 0-.6.1-.3.4-.6.6-.8 2.2-2.4 4.5-4.7 6.8-7 .7-.7 1.2-.7 1.8-.2 1.7 1.6 3.4 3.2 5 4.8.2.2.2.4.4.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm775.2 422.4c.3.3.7.5 1.1.8 1.5 1.4 3 2.9 4.5 4.3.8.8.8 1.2 0 2-.2.3-.5.5-.7.8-1.9 1.9-3.7 3.9-5.6 5.8-1.5 1.5-1.4 1.6-3 .1-1.4-1.4-2.9-2.7-4.3-4.1-.6-.5-.6-1.1-.2-1.5 2.5-2.6 5-5.2 7.5-7.8.2-.2.5-.3.7-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm794.4 441c-.1.2-.2.4-.3.6-2.4 2.5-4.8 5.1-7.2 7.6-.5.5-1.1.6-1.5.1-1.8-1.7-3.5-3.4-5.3-5.1-.4-.4-.4-.8 0-1.2 2.5-2.6 5-5.2 7.5-7.8.4-.4.9-.4 1.2-.1 1.8 1.8 3.7 3.5 5.5 5.3.1.1.1.4.1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm803.4 466.3c-.1 0-.2 0-.3-.1-.2-.1-.4-.2-.6-.4-1.7-1.6-3.4-3.2-5.1-4.8-.5-.5-.5-1 0-1.5 1.1-1.2 2.3-2.4 3.4-3.5 1-1.1 2.1-2.2 3.1-3.3 1.4-1.5 1.5-1.6 3.3.1 1.1 1.1 2.2 2.1 3.3 3.1.4.4.8.8 1.2 1.3.1.1.1.5 0 .6-2.6 2.8-5.3 5.6-8 8.3 0 .1-.2.1-.3.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm817.6 479.8c-.1 0-.3 0-.3-.1-1.9-1.8-3.9-3.7-5.8-5.5-.1-.1-.1-.4-.1-.6.1-.2.2-.4.4-.6 2.4-2.5 4.8-5.1 7.2-7.6.5-.5 1-.5 1.5 0 .1.1.2.2.3.2 1.6 1.5 3.2 3.1 4.8 4.6.6.6.6.9.1 1.5-2.5 2.6-5 5.2-7.5 7.8-.1.2-.4.2-.6.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm840.5 484.8c0 .2 0 .5-.2.6-2.6 2.7-5.1 5.4-7.7 8.1-.3.3-.7.3-.9.1-1.9-1.8-3.9-3.7-5.8-5.6-.1-.1 0-.4 0-.6 0-.1.1-.2.2-.3 2.5-2.6 5-5.2 7.5-7.8.3-.3.9-.4 1.3-.1 1.8 1.8 3.7 3.5 5.5 5.3 0 0 0 .2.1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm856.2 499.7c-.1.2-.2.5-.3.6-2.5 2.6-4.9 5.2-7.4 7.9-.4.5-1 .4-1.5 0-.3-.2-.5-.5-.8-.7-.5-.5-1.1-.9-1.5-1.4-1-1.2-2.7-1.9-3.2-3.6.8-1.3 1.9-2.3 3-3.4 1.5-1.6 3.1-3.2 4.6-4.8.5-.5 1.1-.6 1.5-.2 1.7 1.6 3.4 3.2 5 4.8.2.2.4.5.6.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm705.7 311.9c.2.1.5.1.6.2 1.2 1.1 2.4 2.2 3.6 3.3.8.8 1.9 1.3 2.2 2.5-.7.8-1.3 1.6-2.1 2.4-1.6 1.7-3.2 3.4-4.8 5.1-1.5 1.6-1.5 1.6-3.3 0-1.4-1.3-2.9-2.7-4.3-4.1-.4-.3-.4-.9-.1-1.2 2.5-2.6 4.9-5.3 7.4-7.9.3-.1.6-.2.8-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm724.6 329.7c0 .1 0 .2-.1.3-2.6 2.8-5.3 5.6-7.9 8.4-.2.2-.4.2-.6 0-2-1.8-3.9-3.6-5.9-5.5-.4-.3-.1-.7.1-.9.9-1.1 1.9-2.1 2.8-3.1 4.5-4.8 5.1-5.4 5.5-5.2 2.3 1.5 4 3.6 5.9 5.4 0 .1.1.3.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm735.4 340c-.1.2-.2.4-.3.6-1 1.1-2.1 2.2-3.1 3.3-4.6 4.8-4.6 4.9-5 4.7-2.1-1.1-3.5-3.1-5.2-4.6-.3-.3-.7-.7-.9-1-.1-.1 0-.4 0-.7 0-.1.1-.2.2-.3 2.5-2.6 4.9-5.2 7.4-7.9.4-.4.9-.4 1.2-.1 1.9 1.8 3.7 3.5 5.6 5.3.1.2.1.5.1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm743.9 347.8c.1 0 .3 0 .3.1 1.9 1.8 3.9 3.7 5.8 5.5.2.2.2.4.1.6s-.2.4-.4.6c-2.4 2.5-4.8 5.1-7.2 7.6-.4.5-1.1.4-1.5 0-1.3-1.2-2.6-2.4-3.8-3.6-2.4-2.2-2.1-1.9-.1-4 2-2.2 4.2-4.4 6.2-6.6.1-.1.4-.1.6-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm767.5 370c0 .1-.1.2-.1.3s-.1.2-.2.3c-2.5 2.6-5 5.3-7.4 7.9-.4.4-.9.4-1.2.1-1.8-1.7-3.6-3.3-5.4-5-.5-.5-.5-1 0-1.5 1-1 1.9-2 2.9-3.1 1.1-1.2 2.2-2.4 3.4-3.6 1.9-2 1.5-1.9 3.5-.1 1.2 1.1 2.4 2.2 3.6 3.3.3.3.6.7 1 1-.1.2-.1.3-.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm778 380c.1 0 .2 0 .3.1.5.3 1 .6 1.4 1.1 1.6 1.7 3.6 2.9 4.8 4.9-1.5 1.6-2.9 3.3-4.4 4.9-1.1 1.2-2.3 2.4-3.4 3.5-.3.3-.9.3-1.3 0-1.8-1.7-3.6-3.3-5.3-5-.4-.4-.4-.9-.1-1.2 2.6-2.7 5.1-5.4 7.7-8.1.1-.1.2-.1.3-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm793.2 394.2c.1 0 .2 0 .3.1 1.9 1.8 3.9 3.7 5.8 5.5.1.1.1.4 0 .6 0 .1-.1.2-.2.3-2.5 2.6-4.9 5.3-7.4 7.9-.3.4-1 .4-1.3.1-1.9-1.7-3.7-3.5-5.6-5.3-.2-.2-.2-.7 0-.9 2.5-2.7 5.1-5.4 7.6-8.1.3-.2.6-.2.8-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm815.5 415.3c0 .1 0 .2-.1.3 0 .1-.1.2-.2.3-2.5 2.6-4.9 5.3-7.4 7.9-.5.5-.9.5-1.5 0-.1-.1-.2-.2-.3-.2-4.4-4.1-5.4-5.1-5.2-5.4 1.3-2.1 3.2-3.6 4.8-5.4.8-1 1.8-1.9 2.6-2.8.5-.5 1.1-.6 1.5-.2 1.8 1.7 3.6 3.3 5.3 5 .4 0 .4.3.5.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm818.6 435.8c2.6-2.7 5.2-5.5 7.9-8.3.4-.4.8-.4 1.2 0 1.8 1.7 3.6 3.3 5.3 5 .4.4.4 1-.1 1.5-2.1 2.3-4.3 4.6-6.5 6.9-.3.3-.7.6-1 .9s-.7.2-1 0c-2-1.8-4-3.5-5.8-5.6.1-.1.1-.2 0-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm847.5 446.5c-.1.2-.2.4-.3.6-2.4 2.6-4.8 5.1-7.2 7.7-.6.6-1.2.6-1.8 0-1.7-1.6-3.4-3.2-5.1-4.8-.4-.4-.4-.9-.1-1.2 2.6-2.7 5.1-5.4 7.7-8.1.2-.2.7-.2.9 0 1.9 1.8 3.7 3.5 5.6 5.3.2.1.3.4.3.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm854.5 453.7c.2.1.3.1.4.2.4.3.7.6 1.1.9 1.6 1.7 3.7 2.9 4.8 5-1.2 1.9-2.9 3.3-4.4 4.9-1.1 1.2-2.2 2.4-3.4 3.5-.3.3-.9.3-1.3 0-1.9-1.8-3.7-3.5-5.6-5.3-.3-.3-.2-.6.1-.9 2.5-2.6 4.9-5.3 7.4-7.9.3-.2.7-.3.9-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm891.5 478.9v2.1c0 .4-.3.7-.6.8-.4.1-.9.1-1.4.2-.8.1-1.6.2-2.4.1-1.5 0-2.9.8-4.4.1-.6-1.3-.3-2.7-.5-4.1-.4-2-.4-3.9-.5-5.9-.1-1.9-.2-2.1 2-2.3 1.6-.1 3.2-.4 4.8-.5h1.4c.5 0 .8.4.9.9.2 2.4.5 4.9.7 7.3-.1.3-.1.8 0 1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm902.9 479.1c-.9 0-1.9 0-2.8 0-.7 0-1-.3-1.1-1-.2-3.2-.7-6.5-.6-9.7 0-.3 0-.7.1-1 0-.4.4-.6.7-.7.7-.1 1.4-.1 2.1-.2 1.7-.1 3.5-.1 5.2-.2.4 0 1 .4 1.1.7.3 3.5.5 7 .8 10.4v.3c0 .3-.5.9-.9 1-1.6.1-3.1.3-4.6.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm924.5 472.7c.1.8.1 1.6.2 2.4.2 1.7 0 2-1.8 2.2-2.1.2-4.2.3-6.3.5-.3 0-.9-.5-1-.8 0-.1-.1-.2-.1-.3-.3-3.4-.6-6.7-.9-10.1 0-.5.2-.9.8-1 .9-.2 1.8-.4 2.8-.5 1.5-.2 3-.3 4.5-.4.5 0 1 .3 1 .7.3 2.4.6 4.8.8 7.3.1 0 .1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm940.5 469.3c.2 1.6.4 3.2.5 4.9.1.8-.3 1.3-1.2 1.4-2.3.2-4.6.5-7 .7-.6.1-1.1-.4-1.2-1-.3-3.1-.6-6.3-.9-9.4 0-.3 0-.7 0-1 0-.4.4-1 .8-1 .3 0 .7-.1 1-.1 1.8 0 3.5-.1 5.2-.5.3-.1.7-.1 1-.1.5 0 1 .3 1 .8.4 1.6.7 3.5.8 5.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm947.7 467.4c-.1-1.5-.3-3-.4-4.5 0-.2.2-.5.4-.5 2.5-.3 5-1 7.6-.9.7 0 1.1.3 1.2 1 0 .2.1.5.1.7.2 2.3.3 4.7.7 7 .2.9.1 1.9.1 2.8 0 .6-.4 1.1-1.1 1.1-2.3.2-4.6.5-7 .7-.5 0-1-.3-1-.7-.1-2.3-.4-4.5-.6-6.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm969.9 466.8c1-.3 2-.6 3-.9.2 0 .5.1.6.2.3.5.5 1 .7 1.6 1 3 1.9 6 2.9 9 .1.5-.1 1-.5 1.2-.3.1-.6.3-1 .4-1.9.6-3.8 1.2-5.7 1.8-1.2.4-1.5.3-1.9-.8-1-3-1.9-6-2.8-9-.5-1.6-.4-1.9 1.3-2.4 1.2-.4 2.3-.7 3.4-1.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm983.2 464.7c-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.2-2.5-2.8-5-5.5-7.4-8.3-.2-.2-.2-.8 0-1 1.9-1.7 3.8-3.4 5.7-5.1.4-.3.9-.3 1.2.1 2.4 2.7 4.8 5.4 7.2 8.1.4.4.4.8-.1 1.2-1.9 1.7-3.8 3.4-5.7 5.1-.1.1-.2.1-.3.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm986.4 445.3c-.1-.5-.2-1.1.3-1.5.9-.8 1.9-1.5 2.5-2.6.1 0 .2-.1.3-.1 1.1-.8 1.9-2.1 3.3-2.4 1.1.7 1.8 1.8 2.6 2.7 1.7 1.9 3.4 3.8 5.1 5.7.2.2.4.6.1.9-2 1.8-4 3.5-6 5.3-.4.3-.7.1-.9-.2-1.4-1.4-2.7-2.9-4-4.3-1.1-1.2-2.2-2.3-3.3-3.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1004.9 427.6c.1 0 .2.1.3.1.3.2.6.4.8.6 2.1 2.3 4.2 4.7 6.3 7.1.9 1 .9 1.3-.2 2.3-1.6 1.5-3.3 3-5 4.4-.4.4-.9.4-1.2 0-2.4-2.7-4.8-5.4-7.3-8.1-.3-.4-.3-.9.1-1.2 1.8-1.6 3.7-3.3 5.5-4.9.3-.2.5-.2.7-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1033.3 422.5c-.4.3-.7.6-1.1.8-2.1 1-4.2 2-6.3 3-.4.2-1 0-1.2-.4-.4-.8-.8-1.7-1.2-2.5-1.1-2.3-2.2-4.6-3.3-6.9-.4-.8-.2-1.3.6-1.7 2.1-1 4.2-2 6.3-2.9.6-.3 1.1-.1 1.4.6 1.6 3.2 3.2 6.5 4.8 10z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1042.6 404.8c.1.1.2.1.3.2.1.2.3.4.4.6 1.3 3.2 2.7 6.4 4 9.6.2.6 0 1.1-.7 1.3-2.1.9-4.3 1.8-6.4 2.7-.7.3-1.3.1-1.6-.8-1.3-3-2.5-6-3.7-9-.1-.2-.1-.4-.2-.7-.1-.4.2-1 .6-1.1 2.2-.9 4.5-1.9 6.8-2.8 0-.1.3 0 .5 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1062.9 409.1c-.1.2-.2.4-.3.6-.1.1-.1.2-.2.2-2.4 1-4.7 1.9-7.1 2.9-.3.1-.7-.1-.9-.4-.1-.2-.2-.4-.3-.6-1.3-3.1-2.6-6.2-3.9-9.3-.3-.6.1-1.2.6-1.4 2.3-.9 4.5-1.9 6.7-2.8.4-.2.9.1 1.1.5 1.4 3.3 2.8 6.6 4.1 10 .2.1.2.2.2.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1074.9 391.3c.2.1.5.2.6.4.2.4.4.8.6 1.3 1.2 2.8 2.3 5.6 3.5 8.4.4 1 .3 1.4-.8 1.9-2.1.9-4.3 1.8-6.4 2.7-.4.2-1-.1-1.1-.5-1.2-2.8-2.3-5.6-3.5-8.3-.2-.5-.4-1.1-.6-1.6-.1-.4.1-1.1.5-1.2 2.4-1 4.7-1.9 7.1-2.9-.1-.2 0-.2.1-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1096.3 395.4c-.1.2-.1.5-.3.6-2.3 1-4.7 2-7 3-.5.2-1.2-.1-1.4-.7-1.3-3.1-2.5-6.2-3.9-9.3-.4-.9-.2-1.3.6-1.7 2.2-1 4.5-1.9 6.8-2.8.3-.1 1 .2 1.1.5 1.4 3.3 2.7 6.7 4.1 10z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1103.8 391.7c-.1-.1-.2-.1-.3-.2s-.2-.1-.3-.2c-2-3-4-6-6-9-.3-.5-.3-.8.2-1.2 2.1-1.4 4.2-2.9 6.4-4.3.2-.2.7-.1.9.2.6.9 1.2 1.7 1.8 2.6 1.5 2.2 3 4.5 4.5 6.7.3.4 0 .7-.2.9-2.2 1.5-4.4 3-6.6 4.5-.2.1-.3 0-.4 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1114.1 366.6c.2.1.5 0 .6.2 2.7 2.4 5.4 4.8 8.1 7.3.2.2.5.6.2.9-1.8 2-3.6 4-5.4 6-.3.3-.7.2-.9 0-2.7-2.4-5.4-4.8-8.1-7.2-.3-.3-.4-.9-.1-1.3 1.6-1.8 3.3-3.7 4.9-5.5.3-.2.5-.3.7-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1127.4 367.3c-.2 0-.5 0-.7-.1-3.1-1.6-6.2-3.2-9.3-4.8-.4-.2-.6-.8-.4-1.2 1.2-2.3 2.3-4.6 3.6-6.8.1-.2.7-.3.9-.2 3.2 1.6 6.4 3.3 9.7 4.9.4.2.6.8.4 1.2-1.2 2.3-2.4 4.6-3.6 6.8-.1.1-.4.1-.6.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1133.1 345.5c-2-.1-4-.3-5.9-.4-1.7-.1-1.9-.1-1.8-2.1.1-2.1.2-4.2.4-6.3 0-.4.6-.9 1-.9 1.7.1 3.5.1 5.2.2.6 0 1.2.1 1.7.2 1.5.3 3-.3 4.4.6.3 1.4 0 2.8 0 4.1-.1 5.4.6 4.7-4.6 4.5-.2.1-.3.1-.4.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1144.4 320.9c-1.5-.1-3-.1-4.5-.3-3-.3-2.8 0-2.7-3.1.1-1 .1-2.1.1-3.1.1-3.4-.1-3.4 3.2-3.2 1.3.1 2.6.2 3.8.3s2.3.2 3.5.2c2.1.1 2.3.1 2.1 2.5-.1 2-.3 3.9-.4 5.9 0 .5-.5.8-.9.8-1.5.1-2.9.1-4.2 0 0 .1 0 .1 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1138 299.6c0-.1 0-.2 0-.3.2-5-.5-4.2 4.3-4.2 2.2 0 4.4.3 6.6.5 1.6.1 1.7.2 1.7 1.9 0 1.6-.1 3.3-.1 4.9 0 .5-.1.9-.1 1.4-.1.6-.5 1.1-1.1 1.1-.3 0-.7 0-1 0-3.1-.2-6.3-.4-9.4-.6-.6 0-1-.5-1-1.1 0-1.3 0-2.5.1-3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1144.5 289.5c-1.6-.1-3.3-.1-4.9-.2-.5 0-1-.4-.9-.8.1-2.6.1-5.1.2-7.7 0-.2.5-.6.8-.6h4.5c1.9 0 3.7.1 5.6.2 1.3.1 1.7.4 1.7 1.6-.1 2.2-.2 4.4-.3 6.6 0 .8-.6 1.3-1.4 1.2-1.7-.2-3.5-.3-5.2-.5-.1.1-.1.1-.1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm885.7 513.8c-.1 1.7-.2 3.5-.3 5.2-.1 1-.5 1.4-1.6 1.3-.2 0-.5 0-.7 0-5.8-.3-6.7-.4-6.8-.8-.4-2.2.1-4.4.1-6.6 0-1.5.1-3 .2-4.5 0-.6.5-1 1.2-1 .9 0 1.9.1 2.8.2.7.1 1.4.2 2.1.2 3.6.2 3.2-.3 3.1 3.6v2.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm903.4 516.3c0 .6.1 1.3.1 2 0 .8-.4 1.3-1.3 1.3-2.2.2-4.4.4-6.6.6-.6.1-1.2-.3-1.3-.9-.4-3.5-.7-6.9-1.1-10.4-.1-.7.2-1 1-1.2 2.4-.5 4.8-.7 7.3-.7.5 0 .9.4 1 .8.4 2.9.7 5.6.9 8.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm918.7 517.8c-.4.1-.7.1-1 .2-3 .6-2.9.5-3.3-2.7-.3-2.8-.7-5.5-1-8.3-.1-.7.3-1.1.9-1.2.1 0 .2 0 .3-.1 2.2-.4 4.4-.6 6.6-.7 1 0 1.4.3 1.6 1.4.1.5.1.9.2 1.4.2 2.2.5 4.4.7 6.6.1.7.1 1.4.1 2.1 0 .3-.3.6-.6.7-.5.2-1.1.4-1.7.4-1 .1-1.9.1-2.8.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm938.5 515.6c-1 .2-2.1.4-3.1.5-.6.1-1.1-.2-1.3-.9-.2-.9-.4-1.8-.4-2.8-.1-2.1-.4-4.2-.6-6.3-.2-2.2-.6-2.5 2.2-2.8 2-.2 3.9-.6 5.9-.5.5 0 .8.3.9.8.2 1.6.4 3.2.6 4.9.2 1.9.4 3.7.6 5.6.1.6-.3 1.1-1 1.2-1.3.1-2.6.3-3.8.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm962.6 510.3v2.1c0 .4-.5.9-.9 1-.1 0-.2 0-.3.1-2.1.2-4.2.4-6.3.6-.2 0-.5 0-.7 0-.4 0-.9-.4-1-.8-.3-2.9-.6-5.8-.9-8.7-.1-.9-.6-1.9.2-2.7.6-.6 1.6-.2 2.3-.4 1.8-.5 3.7-.5 5.5-.6.4 0 1 .4 1 .8 0 .1 0 .2.1.3.3 2.7.6 5.5 1 8.3-.1 0-.1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm974.2 499.3c.4-.1 1.2-.1 2-.3 1.7-.3 2 .2 2.2 1.8.3 2.2.5 4.4.7 6.6.1 1.2.6 2.5-.1 3.7-1.1.2-2.2.6-3.4.7-1.5.2-3 .2-4.5.3-.5 0-.9-.3-1-.8-.4-3.5-.7-6.9-1.1-10.4-.1-.7.5-1.3 1.2-1.4 1.3 0 2.4-.1 4-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm992.4 507c-.2-.2-.4-.3-.5-.5-.3-.5-.6-1-.9-1.5-1.5-2.9-3-5.8-4.6-8.7.5-.3.8-.7 1.2-.9 1.7-.9 3.5-1.9 5.2-2.8 1.6-.8 1.7-.8 2.6.9.7 1.3 1.4 2.7 2.1 4 .6 1.1 1.2 2.3 1.8 3.4 1 1.9 1 1.9-.8 2.8-1.1.6-2.2 1.3-3.4 1.8-.8.6-1.8 1.1-2.7 1.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1015.9 488.9c-.1.2-.2.5-.3.6-1.7 1.7-3.5 3.4-5.3 5.1-.4.4-.8.4-1.2 0-2.5-2.6-5-5.2-7.5-7.7-.3-.4-.3-.9 0-1.2s.6-.7 1-1c1.8-1.5 3-3.5 5.2-4.5 1.6 1.6 3.1 3.1 4.7 4.7 1.1 1.2 2.2 2.4 3.3 3.6.1-.1.1.2.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1028.2 476.9c-.1.2-.1.5-.2.6-1.8 1.8-3.7 3.6-5.5 5.3-.2.2-.6.4-.9.1-.4-.4-.9-.8-1.3-1.2-1.9-2-3.9-4-5.8-6-1.3-1.3-1.2-1.4.1-2.7 1.4-1.4 2.8-2.8 4.3-4.1.6-.6 1.3-.6 1.8-.1 2.4 2.5 4.8 5 7.3 7.5 0 .2.1.4.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1033.5 471.9c-.1 0-.2 0-.3 0s-.2-.1-.3-.2c-2.5-2.6-5-5.2-7.6-7.8-.4-.4-.4-.8 0-1.2 1.8-1.7 3.5-3.4 5.3-5.1.3-.3 1-.4 1.3-.1 2.5 2.6 5.1 5.2 7.6 7.8.3.3.3.8.1 1-1.9 1.9-3.8 3.7-5.8 5.6-.1 0-.2 0-.3 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1054.9 442.5c.2.1.5.2.6.3.2.3.4.6.5.9.7 1.6 1.4 3.2 2 4.8.7 1.6 1.3 3.2 1.9 4.8-1.1 1.5-3 1.6-4.5 2.4-.2.1-.4.1-.7.2-.6.3-1.3.5-1.9.8-1 .5-1.4.1-1.8-.8-.9-2-1.7-4.1-2.5-6.1-.4-1.1-.9-2.2-1.3-3.2-.2-.4 0-1.1.3-1.2 2.4-1 4.7-1.9 7.1-2.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1070.9 435.7c.4-.2.9 0 1.1.5.9 2.1 1.9 4.3 2.8 6.4.5 1.2 1 2.4 1.4 3.6.2.4-.1 1-.5 1.2-2.3 1-4.5 1.9-6.8 2.8-.5.2-1 0-1.2-.4-.5-1.2-1-2.3-1.5-3.5-.9-2.2-1.8-4.3-2.6-6.5-.1-.3.2-1 .5-1.2 2.2-1 4.4-1.9 6.8-2.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1086.9 429.2c.2.1.4.2.6.3s.3.4.4.6c1.3 3.2 2.6 6.4 4 9.7.1.3-.2 1-.5 1.1-2.4 1-4.7 2-7.1 2.9-.2.1-.8-.2-.9-.4-1.4-3.3-2.8-6.6-4.2-9.9-.3-.6 0-1.1.6-1.4 2.1-.9 4.3-1.8 6.4-2.7.2-.2.5-.2.7-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1101.8 422.9c.2.1.5.2.6.3.2.1.3.4.4.6 1.3 3.2 2.7 6.4 4 9.7.2.4-.1 1-.4 1.2-.9.4-1.7.7-2.6 1.1-1.6.7-3.1 1.5-4.8 1.9-1.2-.6-1.2-1.9-1.7-2.9-.3-.6-.5-1.3-.8-1.9-.6-1.4-1.2-2.8-1.8-4.2-1-2.5-1.1-2.2 1.6-3.4 1.4-.6 2.8-1.1 4.2-1.7.4-.4.9-.5 1.3-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1122.5 427.3c-.1.2-.2.4-.3.6-.3.2-.6.4-.9.6-1 .4-1.9.8-2.9 1.2-.9.4-1.7.7-2.6 1-1.5.6-1.7.5-2.3-.9-.4-.9-.7-1.7-1-2.6-.4-1-.9-1.9-1.2-2.9-.5-1.5-1.6-2.8-1.5-4.5 1.2-.9 2.7-1.3 4.1-1.9 1-.4 1.9-.8 2.9-1.2.8-.3 1.4-.1 1.7.7 1.3 3.2 2.7 6.4 4 9.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1138.6 419.5c0 .1-.1.2-.1.3-.3.2-.5.4-.8.6-1.8 1-3.7 2-5.5 3-1.1.6-1.5.5-2-.5-1.7-3.1-3.3-6.1-5-9.2-.2-.4 0-.6.2-.9.1-.2.3-.3.5-.4 2-1.1 4.1-2.2 6.1-3.3.6-.3.9-.2 1.2.2 1.8 3.3 3.5 6.5 5.3 9.8.1.1.1.2.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1154.7 407.9c0 .1 0 .2-.1.3-1.6 2.2-3.1 4.3-4.7 6.5-.2.2-.4.3-.6.1-.1-.1-.2-.1-.3-.1-2.9-2.1-5.9-4.2-8.8-6.3-.4-.3-.5-.8-.3-1.2.3-.5.6-1 1-1.5 3.3-4.8 3.7-5.2 4.1-5 2 .8 3.5 2.3 5.2 3.4 1.4 1 2.8 2 4.2 3.1.2.3.2.5.3.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1162.8 401.1c-.4 0-.5 0-.6 0-3.4-1.2-6.8-2.4-10.2-3.5-.5-.2-.7-.6-.6-1.1.7-2.1 1.4-4.2 2.2-6.3.5-1.4.8-1.6 2.3-1.1 3 1 5.9 2 8.9 3 .2.1.4.2.6.3.3.2.6.4.5.8-.8 2.4-1.7 4.8-2.5 7.2-.2.5-.4.7-.6.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1161.9 381.4c-3-.1-5.3 0-7.5-.5-1.2-.3-1.5-.5-1.4-1.5.1-2.2.3-4.4.5-6.7 0-.6.5-1 1.2-1 1.2 0 2.3.1 3.5.1 2.2.1 4.4.3 6.7.5.8.1 1.2.5 1.2 1.4-.1 2.3-.3 4.7-.5 7 0 .5-.4.8-1 .8-1.2-.1-2.4-.1-2.7-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1154.9 365.3c-2.5-.2-4.5-.4-6.6-.6-.6-.1-1-.5-1-1.2 0-.5 0-.9 0-1.4.1-1.2.3-2.3.3-3.5.1-3.6.1-3.3 3.3-3.1 2.8.2 5.6.4 8.4.6.5 0 .9.4.9.9v.3c-.1 2.3-.3 4.7-.4 7 0 .7-.4.9-1.2.9-1.2.1-2.6.1-3.7.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1149.4 336.9c1.8.1 3.5.2 5.3.3.6 0 1 .5 1 1.1-.1 2.3-.2 4.7-.3 7 0 .5-.5.9-.9.9-.1 0-.2 0-.3 0-2.8-.1-5.6-.1-8.4-.2-.8 0-1.6-.1-2.4-.5-.2-.1-.3-.3-.4-.5-.1-.1-.1-.2-.1-.3.1-2.2.1-4.4.2-6.7 0-1.2.5-1.6 1.8-1.5 1.4.2 3 .3 4.5.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1180 276.9c-1.9-.1-3.6-.2-5.4-.3-.5 0-.9-.5-.9-.9 0-2.4.1-4.9.2-7.3 0-.4.5-.8 1-.8 3.3 0 6.5-.1 9.8.3.5.1.9.2 1.4.3.2.1.4.3.4.5.1 2.6.2 5.1-.3 7.7-.1.4-.6.9-1 .8-1.8-.1-3.6-.2-5.2-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1173.2 290.9c-1.9-.1-3.7-.2-5.6-.3-.4 0-.8-.5-.8-1 .1-2.3.1-4.7.2-7 0-.6.5-1.2 1.1-1.1 3.4.1 6.8.2 10.1.3 1 0 1.4.5 1.4 1.6-.1 2.1-.2 4.2-.2 6.3 0 .9-.5 1.3-1.3 1.3-1.6 0-3.2-.1-4.9-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1173.1 305.5c-1.7-.1-3.3-.1-5-.3-1.9-.2-2.1-.2-2-2.4.1-2 .2-4 .4-5.9 0-.6.5-1.1 1.2-1 1.8.1 3.5.1 5.3.2 1.1.1 2.1.2 3.1.3 3.2.1 2.9 0 2.8 2.9 0 1.2-.1 2.3-.1 3.5-.1 3 0 2.9-2.9 2.8-1-.1-1.9-.1-2.8-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1175.9 311.2c1.7.1 3.5.1 5.2.2.6 0 1.1.5 1.1 1.1-.1 2.2-.1 4.4-.2 6.6 0 .3-.1.7-.3 1s-.5.5-.8.6-.7.1-1 .1c-3.1-.2-6.3-.4-9.4-.5-.7 0-1.2-.4-1.1-1 .1-2.4.2-4.9.3-7.3 0-.4.5-.8.9-.8 1.9-.1 3.6-.1 5.3 0 0-.1 0-.1 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1179.8 337.1c-1.6 0-3.3 0-4.9 0-1.1 0-1.6-.5-1.5-1.5.2-2.3.3-4.7.5-7 0-.5.5-.9.9-.8.9 0 1.9.1 2.8.1 2.3.1 4.7.1 7 .2.3 0 .7.1 1 .1.4 0 .8.6.8 1-.1 1.5-.2 3-.3 4.5-.1.9-.1 1.9-.2 2.8 0 .5-.5.9-.9.9-.7 0-1.4-.1-2.1-.1-1-.1-2.1-.1-3.1-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1186.7 344.2h4.5c1.2 0 1.5.4 1.5 1.4 0 2-.1 4-.2 5.9 0 .3-.1.7-.2 1s-.3.7-.7.7c-3.6.1-7.2.1-10.8-.3-.4 0-.9-.6-.9-.9.1-2.4.1-4.9.2-7.3 0-.6.6-1 1.2-1 1.9.1 3.7.3 5.4.5 0-.1 0 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1191.5 373.5c-.1 1.9-.1 3.7-.2 5.6 0 .4-.5.9-.9.9-2.4-.1-4.9-.1-7.3-.2-.6 0-1.1-.6-1.1-1.2.1-3.5.3-7 .5-10.5 0-.4.5-.9.9-.9h.3c2.3.1 4.7.2 7 .3.6 0 1.1.5 1 1.1-.1 1.6-.2 3.2-.2 4.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1180.8 403.6c.1-1.7.1-3.5.2-5.2 0-.3.4-.7.6-.7 2.6-.2 5.1-.2 7.6.3.6.1.9.6.9 1.3-.1 3.4-.2 6.7-.4 10.1 0 .6-.6 1.1-1.2 1.1-2.4-.1-4.9-.2-7.3-.4-.4 0-.9-.5-.9-.9 0-1.3.1-2.6.2-3.8.2-.6.3-1.2.3-1.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1180.9 434.7c-1.5 0-3 0-4.5 0-.3 0-.7-.1-1-.1s-.9-.7-.9-1c.1-2.4.2-4.9.3-7.3 0-.6.6-1.1 1.2-1 3.4.5 6.7.4 10.1.5.2 0 .5 0 .7.1s.3.3.5.5c.1.1.1.2.1.3-.1 2.3-.1 4.7-.2 7 0 .9-.5 1.3-1.3 1.3-1.6 0-3.3 0-4.9 0-.1-.2-.1-.2-.1-.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1151.7 439c5.2.1 5.7.1 5.8.5.5 1.8.2 3.7.2 5.6 0 3.5.3 3.3-3.1 3.2-2.8 0-5.6-.1-8.4-.2-.7 0-1.1-.4-1.1-1.1 0-2.2.1-4.4.1-6.7 0-1 .4-1.4 1.6-1.4 1.6.1 3.3.1 4.9.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1128.8 459.5v4.5c0 .5-.1.9-.2 1.4-.1.4-.6.9-.9.9-1 0-2.1 0-3.1-.1-1.4-.1-2.8-.3-4.2-.4-.6-.1-1-.5-1-1.2.1-3.4.3-6.7.5-10.1 0-.6.5-1.2 1-1.2 2.6.1 5.1.3 7.7.4.2 0 .5.6.5.8-.1 1.7-.1 3.3-.3 5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1154.3 461.2c-.1 1.9.3 3.8-.4 5.5-.4.9-1.4.5-2.2.5-1.2 0-2.3-.1-3.5-.1-3.5-.1-3.3.2-3.2-3.2.1-2.8.2-5.6.4-8.4 0-.6.5-1 1.2-1 2.5 0 4.9.1 7.3.5.4.1.8.6.8 1-.1 1.7-.3 3.5-.4 5.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1173.8 461.4c-.1 2-.3 3.9-.4 5.9 0 .6-.6 1.1-1.2 1-2.4-.2-4.9-.3-7.3-.5-.4 0-.8-.5-.8-1 0-.6 0-1.2 0-1.7.2-2.9.4-5.8.6-8.7 0-.5.4-1 .8-1 2.7-.1 5.3.3 8 .6.1 0 .4.4.4.6 0 1.5 0 3.1-.1 4.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1188.6 456.6v2.3c0 .6 0 1.3.1 1.9-.1 2.1-.2 4.2-.3 6.3 0 .5-.1.9-.3 1.3-.1.3-.5.5-.8.6s-.7 0-1 0c-1.7-.1-3.5-.3-5.2-.3-2-.1-2.3-.3-2.1-2.2.2-3 .3-6 .5-9.1.1-1.3.5-1.5 1.5-1.4 2.5.2 5.1.4 7.6.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1035.2 492.6c.2.1.5.1.6.2 2.6 2.7 5.2 5.4 7.8 8.1.2.2.2.7-.1.9-.7.7-1.4 1.5-2.2 2.2-1.3 1.1-2.2 2.7-4 3.3-1.4-.9-2.4-2.2-3.5-3.4-1.5-1.5-2.9-3-4.4-4.5-.3-.4-.4-.9-.1-1.3 1.8-1.8 3.7-3.6 5.5-5.4.2 0 .3 0 .4-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1061.7 484.8c-.1 2-.2 4-.3 6 0 .5-.5.9-.9.9-2.6-.2-5.1-.4-7.7-.6-.2 0-.3-.3-.4-.5-.1-.1-.1-.2-.1-.3.1-3.5.2-7 .4-10.5 0-.6.6-1 1.2-1 2.3.1 4.7.2 7 .3.6 0 1 .5 1 1.2 0 .4 0 .7-.1 1.1-.1 1-.2 2.2-.1 3.4-.1 0-.1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1066.4 486.1c0-1.5.1-3 .1-4.5 0-.5.1-.9.2-1.4 0-.4.6-.9 1-.9h1c2 .1 3.9.3 5.9.4 1.1.1 1.4.4 1.4 1.5-.1 2.3-.3 4.6-.4 7-.1 1-.1 2.1-.1 3.1 0 .4-.5.9-.9.9-1.5 0-3 0-4.5-.2-4.3-.6-3.8.5-3.7-4.2 0-.6 0-1.1 0-1.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1090.3 486.3c-.1 1.9-.2 3.7-.3 5.6 0 .5-.5 1-.8 1-2.7-.2-5.3-.3-8-.5-.2 0-.3-.3-.5-.5-.1-.1 0-.2 0-.3.1-3 .3-6.1.4-9.1 0-.5 0-.9.1-1.4 0-.7.5-1.1 1.1-1.1.9 0 1.9 0 2.8.1 1.5.1 3 .3 4.5.4.4 0 .8.4.8.9 0 1.6 0 3.2-.1 4.9.1 0 .1 0 0 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1105.1 487.2c0 1.6-.1 3.3-.1 4.9 0 .3-.1.7-.2 1-.1.2-.3.4-.5.4-.5.1-.9.2-1.4.1-2.1-.3-4.2-.4-6.3-.5-.5 0-.9-.4-.9-.9.1-3.5.2-7 .4-10.5 0-.6.6-1.2 1.1-1.1 2.6.2 5.1.3 7.7.5.2 0 .5.5.5.8 0 1-.1 2.1-.1 3.1-.1.8-.2 1.5-.2 2.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1109.9 487.9c0-1.6.1-3.3.1-4.9.1-1.3.5-1.8 1.7-1.7 1.6.1 3.3.1 4.9.3 2.9.4 2.8-.2 2.7 3.1-.1 2.8-.2 5.6-.3 8.4 0 .6-.6 1.1-1.2 1.1-2.5-.1-4.9-.3-7.4-.4-.4 0-.8-.5-.8-1 .1-1.7.2-3.3.3-4.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1133.9 488.9c-.1 1.7-.2 3.5-.3 5.2 0 .3-.7.9-1 .8-2.3-.4-4.7-.3-7-.4-.9 0-1.4-.4-1.3-1.2.2-3.5.4-7 .5-10.5 0-.5.5-.8.9-.9.6 0 1.2-.1 1.7 0 1.9.1 3.7.3 5.6.5.7.1 1 .5.9 1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1148.6 487.8c-.2 2.1-.3 4.5-.5 6.8 0 .6-.6 1-1.2 1-.1 0-.2 0-.3 0-7-.4-7.4-.4-7.5-.8-.3-2.4.2-4.8.2-7.3 0-1.2.1-2.3.1-3.5.1-1 .4-1.3 1.2-1.3 2 0 4 .1 5.9.2s2.1.3 2.1 2.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1163 489.9c-.1 1.7-.2 3.5-.3 5.2 0 .6-.5 1-1.2 1-2.3-.1-4.7-.1-7-.2-.5 0-.9-.4-.9-.8-.1-3.6 0-7.2.5-10.8.1-.6.4-.9 1.2-.9 2.3 0 4.7.1 7 .5.6.1 1 .5 1 1.2-.2 1.5-.2 3.1-.3 4.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1167.7 490.3c.1-1.7.2-3.5.3-5.2.1-.9.4-1.2 1.4-1.1 2 0 4 .1 5.9.2.3 0 .7.1 1 .1.4.1.9.5.9.9v.7c-.2 3.4-.4 6.8-.6 10.1 0 .3-.4.5-.6.7-.1.1-.2.1-.3 0-2.4-.1-4.9-.2-7.3-.2-.4 0-.8-.5-.8-.9 0-1.8 0-3.5.1-5.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm1181.6 499c.2-2.4.3-4.4.4-6.4 0-.5.5-.9.9-.9 2.4.1 4.9.1 7.3.3.6 0 1.1.6 1.1 1.1v.3c-.4 3.4-.4 6.7-.6 10.1 0 .5-.4.9-.9.9-2.6-.1-5.1-.2-7.7-.3-.2 0-.6-.5-.6-.8.1-1.4.1-3.1.1-4.3z',
      },
    },
  ],
}
