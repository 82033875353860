<template>
    <div class="outer" id="about-project">
        <div class="inner">
            <div class="left">
                <h6>О ПРОЕКТЕ</h6>
                <h4>Мечтаете жить в доме на природе с городской инфраструктурой? Это реально!</h4>
                <p>Представляем вашему вниманию коттеджный поселок Озерный. Это готовые дома для комфортной и счастливой жизни.</p>
                <p>Архитекторы использовали каждую особенность рельефа, чтобы создать неповторимый пейзаж, в результате получилось идеальное место для жизни, сочетающее блага цивилизации и первозданную природу. Всего в 3 км. от Симферополя по Евпаторийскому шоссе расположился поселок Озерный.</p>
                <p>Рядом с поселком находятся  торговые центры «Ашан», «Метро», «Меганом», а также вся необходимая инфраструктура для комфортной жизни. Благодаря близости к трассе Таврида вы с легкостью сможете попасть в любой уголок Крыма!</p>
                <p>Жемчужина поселка – озеро с лесом и парком, на береговой линии которого, планируется обустройство рекреационной зоны с пляжем и оборудованными местами отдыха.</p>
            </div>
            <div class="right">
                <div class="line">
                    <img src="/assets/icons/target_new.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Расположение</h5>
                        <p>Озерный находиться всего в 3х км от города с удобной транспортной развязкой</p>
                    </div>
                </div>
                <div class="line">
                    <img src="/assets/icons/eco_black.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Экология</h5>
                        <p>Поселок находится в экологически благоприятном месте в окружении леса.</p>
                    </div>
                </div>
                <div class="line">
                    <img src="/assets/icons/comfort_black.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Комфорт</h5>
                        <p>Широкие дороги, парковые аллеи, озера</p>
                    </div>
                </div>
                <div class="line">
                    <img src="/assets/icons/infrastructure.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Инфраструктура</h5>
                        <p>Все основные социальные объекты прямо в поселке</p>
                    </div>
                </div>
                <div class="line">
                    <img src="/assets/icons/privat_new.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Безопасность</h5>
                        <p>Охраняемая территория поселка для спокойной и комфортной жизни</p>
                    </div>
                </div>
                <div class="line">
                    <img src="/assets/icons/private.svg" width="60" height="60">
                    <div class="right-column">
                        <h5>Сервис управляющей компании</h5>
                        <p>Обеспечит сохранность и содержание инфраструктуры и территорий общего пользования</p>
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>

export default {
    name: 'AboutProject',
}
</script>

<style lang="scss" scoped>
    .outer {
        background: rgb(37, 39, 41);
        padding: 80px 0 0;
        .inner {
            max-width: 1216px;
            background: white;
            margin: auto;
            padding: 48px;

            display: flex;

            .left {
                padding-right: 40px;
                flex: 50%;

                h6 {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 8px;
                    color: black;
                    font-size: 14px;
                }

                h4 {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 24px;
                    color: black;
                    font-size: 38px;
                    word-break: break-word;
                }

                @media only screen and (max-width: 800px) {
                    h4 {
                        font-size: 28px !important;
                    }
                }

                @media only screen and (max-width: 600px) {
                    h4 {
                        font-size: 22px !important;
                    }
                }

                p {
                    font-size: 16px;
                    color: black;
                    line-height: 19.2px;
                }

            }

            .right {
                flex: 50%;
                .line {
                    display: flex;
                    margin-top: 16px;
                    img {
                        margin-right: 16px;
                    }
                    h5 {
                        color: rgb(18, 135, 55);
                        font-size: 18px;
                        font-weight: 500;
                        margin: 0;
                        margin-bottom: 8px;
                        padding: 0;
                    }
                    p {
                        font-size: 16px;
                        line-height: 19.2px;
                        margin: 0;
                        padding: 0;
                    }
                    .right-column {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }
    }
    

    @media only screen and (max-width: 1000px) {
        .inner {
            display: block !important;
            .left {
                padding-right: 0 !important;
            }
            .right {
                margin-top: 24px !important;
            }
        }
    }
</style>