<template>
  <teleport to="body">
    <transition
        name="modal"
    >
      <div
          v-if="show"
          ref="container"
          class="overlay"
          :class="{ 'full': full }"
          :style="{ zIndex: 20 + (10 * lvl) }"
          @click.self="closeModal">
        <div class="title">
          <slot name="title"/>
        </div>

        <button @click="closeModal" class="close">
          <inline-svg src="/assets/close.svg" />
        </button>
        <div class="content" ><slot/></div>

      </div>
    </transition>
  </teleport>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import {nextTick, onBeforeUnmount, ref, toRefs, watch} from "vue";

export default {
  name: 'Modal',
  components: {
    InlineSvg
  },
  emits: ['close'],
  props: {
    show: {
      type: null,
      required: true
    },
    lvl: {
      type: Number,
      default: 0
    },
    full: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const { show, lvl, full } = toRefs(props)
    const container = ref()
    const keydownTabEvent = ref()

    const closeModal = () => {
      emit('close', true)
    }

    const hideScroll = (val) => {
      if (!process.server) {
        document.documentElement.style.overflow = val ? 'hidden' : 'auto'
      }
    }

    const setFocusInModal = () => {
      if (process.server) return

      const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      const modal = container.value

      if (!modal) return

      const focusableContent = modal.querySelectorAll(focusableElements)

      if (!focusableContent.length) return

      const firstFocusableElement = focusableContent[0]
      const lastFocusableElement = focusableContent[focusableContent.length - 1]

      keydownTabEvent.value = (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9

        if (!isTabPressed) {
          return
        }
        if (e.shiftKey) { // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus() // add focus for the last focusable element
            e.preventDefault()
          }
        } else if (document.activeElement === lastFocusableElement) { // if tab key is pressed
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus() // add focus for the first focusable element
          e.preventDefault()
        }
      }

      document.addEventListener('keydown', keydownTabEvent.value)

      firstFocusableElement.focus()
      nextTick(() => {
        firstFocusableElement.blur()
      })
    }

    const keydownEscapeEvent = (evt) => {
      evt = evt || window.event
      let isEscape = false

      isEscape = 'key' in evt 
        ? (evt.key === 'Escape' || evt.key === 'Esc')
        : (evt.keyCode === 27)

      if (isEscape) closeModal()
    }

    const removeEvents = () => {
      document.removeEventListener('keydown', keydownEscapeEvent)
      document.removeEventListener('keydown', keydownTabEvent.value)
    }


    watch(show, (val) => {
      if (val) {
        nextTick(() => {
          hideScroll(true)
          setFocusInModal()
          document.addEventListener('keydown', keydownEscapeEvent)
        })
      } else {
        hideScroll()
        removeEvents()
      }
    })

    watch(lvl, (val) => {
      console.log('watch lvl', val);
    })

    watch(full, (val) => {
      console.log('watch full', val);
    })


    onBeforeUnmount(() => {
      hideScroll()
      removeEvents()
    })

    return {
      closeModal,
      container
    }
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #252729;
  padding: 0;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    // padding: 50px;
  }
}

.overlay.full {
  max-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
}

.content {
  padding: 15px;
  margin: auto auto;
  background: #252729;
  position: relative;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: calc(100% - 79px);
  max-width: calc(100% - 30px);

  @media (min-width: 768px) {
    // border-radius: 4px;
    // max-height: calc(100% - 100px);
    // max-width: 750px;
    // padding: 30px;
    // height: auto;
  }

  .wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.close {
  width: 40px;
  height: auto;
  opacity: .5;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  transition: opacity ease-in-out .2s;
  padding: 0;
  border: none;
  background: transparent;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover,
  &:focus-visible {
    opacity: 1;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  padding-right: 40px;
  // padding-bottom: 15px;
  color: white;
  margin: 15px 0 0 15px;

  @include tablet_desktop {
    font-size: 40px;
  }
}

.modal-enter-active, .modal-leave-active {
  will-change: opacity;
  transition: opacity .2s;
}

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}
</style>
