<template>
    <div class="outer">
        <div class="row">
            <div class="column c1">
                <img class="logo" src="/assets/bottom_logo_white.svg" alt="">
                <a href="https://vladograd.com" target="_blank" class="volgograd">
                    <!-- <img alt="Vladograd logo" src="/assets/vladograd_logo.png" height="48">
                    <span>Официальный<br>партнер проекта</span> -->
                </a>
            </div>
            <div class="column c2">
                <div class="row-links">
                    <div 
                        v-for="(link, i) in links"
                        :key="i"
                        :href="link.href"
                        class="link"
                        @click="scrollMeTo(link.id)"
                    >{{link.name}}</div>
                </div>
                <div class="socials">
                    <a class="social" @click="openLink('https://vk.com/ozernyj.cottage')">
                        <img src="/assets/icons/vk_w.svg">
                    </a>
                    <a class="social" @click="openLink('https://t.me/ozernij')">
                        <img src="/assets/icons/telegram_w.svg">
                    </a>
                </div>
                <div class="row-text">
                    <div>
                        <span>* Некоторые изображения являются трехмерной графикой</span>
                        <span>**Информация на сайте не является публичной офертой</span>
                    </div>
                    <span>All Rights Reserved © 2021-2022. Коттеджный поселок “Озёрный”</span>
                </div>
            </div>
        </div>
        <div class="mobile-text">
            <div>* Некоторые изображения являются трехмерной графикой</div>
            <div>**Информация на сайте не является публичной офертой</div>
            <div>All Rights Reserved © 2021-2022. Коттеджный поселок “Озёрный”</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    setup() {
        
    },
    data () {
        return {
            links: [
                { name: 'Главная', id: 'main' },
                { name: 'Галерея', id: 'gallery' },
                { name: 'Архитектура', id: 'architecture' },
                { name: 'Коттеджи', id: 'cabins' },
                { name: 'Ген. план', id: 'genplan' },
                { name: 'Карта', id: 'yandex-map' },
            ],
        }
    },
    methods: {
        scrollMeTo(refName) {
            var element = document.getElementById(refName);
            element.scrollIntoView({
                behavior: 'smooth'
            });
        },
        openLink(url) {
            window.open(url, '_blank').focus();
        },
    },
}
</script>


<style lang="scss" scoped>
    .outer {
        padding: 40px 20px 40px;
        background: black;

        .row {
            display: flex;
            max-width: 1312px;
            margin: auto;
            gap: 20px;

            .c1 {
                display: flex;
                gap: 30px;
                flex: 1 1 auto;

                .logo {
                    height: 100px;
                }

                

                .volgograd {
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                    &:visited {
                        text-decoration: none;
                    }
                        
                    img {
                        height: 96px;
                    }
                    span {
                        color: #bc2727;
                        text-decoration: none;
                        font-weight: 900;
                        font-size: 8px;
                        text-align: center;
                    }
                }

            }

            .c2 {
                display: flex;
                flex-direction: column;
                gap: 15px;
                flex: 2 1 auto;

                .row-links {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 40px;

                    .link {
                        color: white;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }

                .socials {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 20px;

                    .social {
                        cursor: pointer;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }



                .row-text {
                    
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;

                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                    

                    span {
                        color: white;
                        display: block;
                        font-size: 10px;
                    }
                }
            }
        }

        .mobile-text {
            display: none;
        }
    }

    

    @media only screen and (max-width: 1000px) {
        .outer {
            .row {
                .column {
                    flex: 1;

                    &.c1 {
                        flex-direction: column;
                        align-items: center;

                        & > * {
                            width: min-content;
                        }
                    }

                    &.c2 {   
                        .row-links {
                            flex-direction: column;
                            justify-content: space-around;
                            gap: 15px;
                        }

                        .socials {
                            justify-content: flex-start;
                        }

                        .row-text {
                            display: none;
                        }
                    }
                }
            }

            .mobile-text {
                display: flex;
                color: white;
                margin: 30px 30px 0;
                font-size: 14px;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
</style>