export default {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '448,388.1 481,403.1 484,410.1 532,431.1 568,443.1 639,462.1 675,473.1 684,466.1 689,479.1 707,486.1 718,479.1 756,542.1 764.5,556.3 747.7,566.1 730.2,563.5 733,555.1 703,542.1 699,550.1 570,511.1 560,513.1 528,499.1 421,453.1'
    }
  },
  name: 'Очередь 2 (левое крыло)',
  id: 2,
  imgForModal: {
    img: '/assets/area2.svg',
    viewBox: {
      x: '412',
      y: '382',
      width: '364.58',
      height: '197.53',
    },
  },
  active: true,
  showBorder: false,
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm458.2 411c0 .2 0 .5-.1.7-1.4 3.2-2.7 6.4-4.1 9.6-.3.8-.7.9-1.3.6-2.2-1-4.5-1.9-6.7-2.9-.4-.2-.7-.8-.5-1.2 1.4-3.2 2.7-6.4 4.1-9.6.3-.8 1-1.1 1.6-.8 2.2 1 4.4 2 6.7 3 .2.1.2.4.3.6z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 71,
        x: 452,
        y: 416.6,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm467.8 428.1c-.2 0-.3.1-.4.1-2.3-1-4.7-2-7-3-.6-.3-.7-.5-.4-1.1 1.4-3.3 2.8-6.6 4.3-9.9.3-.6.5-.7 1.1-.5 2 .8 4.1 1.6 6.1 2.5 1.3.6 1.6.7.9 2.4-1.3 3-2.6 6-3.9 9-.2.1-.5.3-.7.5z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 72,
        x: 466.5,
        y: 422.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm489.4 424.8c-1.4 3.2-2.8 6.5-4.2 9.8-.2.6-.8.7-1.4.5-.1 0-.2-.1-.3-.2-.1 0-.2-.1-.3-.1-2.2-1.1-4.6-1.5-6.5-3.2.5-2.3 1.7-4.3 2.6-6.4.5-1.3 1.1-2.6 1.6-3.9.3-.6.9-.9 1.4-.7 2.3 1 4.7 2 7 3.1.2.2.3.6.1 1.1z',
      },
      type: 'premium-hitech',
      number: {
        n: 73,
        x: 483.4,
        y: 430,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm499.2 428c.2.1.3.1.4.1.9.3 1.8.6 2.6 1.1 1.6.9 3.6 1.1 4.9 2.5-.7 1.6-1.3 3.2-2 4.8 0 .1-.1.2-.1.3-2.2 5.1-2.5 5.9-2.9 5.8-2.2-.3-4.1-1.6-6.1-2.3-.5-.2-1-.5-1.5-.8-.2-.1-.2-.4-.3-.6 0-.1 0-.2.1-.3 1.4-3.3 2.8-6.6 4.3-10 0-.2.4-.4.6-.6z',
      },
      type: 'premium-hitech',
      number: {
        n: 74,
        x: 500.5,
        y: 438,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm518 449.8c-2.2-1-4.5-1.9-6.7-2.9-.6-.3-.8-.7-.5-1.4 1.1-2.7 2.2-5.4 3.4-8 1.1-2.5.8-2.8 3.7-1.5 1.5.7 3 1.2 4.5 1.9 1.1.5 1.3.9.9 1.8-1.3 3.1-2.6 6.2-4 9.3 0 .1-.1.2-.2.3-.1.5-.5.6-1.1.5z',
      },
      type: 'premium-hitech',
      number: {
        n: 75,
        x: 517,
        y: 445,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm534.1 456.6c-.2 0-.4 0-.6-.1-2-.9-4.1-1.7-6.1-2.6-1.1-.5-1.2-.7-.7-1.9 1.2-2.9 2.5-5.8 3.7-8.7.1-.2.2-.4.3-.6.3-.6.9-.9 1.4-.6 2.2 1 4.5 1.9 6.7 2.9.4.2.6.8.4 1.2-1.4 3.2-2.7 6.4-4.1 9.6-.2.3-.5.7-1 .8z',
      },
      type: 'premium-hitech',
      number: {
        n: 76,
        x: 533,
        y: 452,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm550.9 449.4c2.3.7 4.6 1.4 7 2.2.5.2.7.6.6 1.1-1 3.5-2 6.9-3 10.4-.1.4-.8.7-1.1.6-2.2-.6-4.4-1.3-6.7-1.9-1.2-.3-1.2-.9-1-1.8.9-3.2 1.9-6.5 2.8-9.7.2-.7.8-1 1.4-.9z',
      },
      type: 'premium-hitech',
      number: {
        n: 77,
        x: 552,
        y: 459,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm575.2 469.7c-.3 0-.5 0-.8-.1-2.2-.6-4.5-1.3-6.7-1.9-.6-.2-.9-.8-.8-1.4.9-3.2 1.9-6.5 2.8-9.7.1-.2.2-.4.3-.6.2-.4.7-.7 1.1-.6 2.2.7 4.5 1.3 6.7 2 .8.2 1.1.8.9 1.6-1 3.4-2 6.7-3 10.1.1.2-.3.4-.5.6z',
      },
      type: 'premium-hitech',
      number: {
        n: 78,
        x: 572,
        y: 466,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm597.2 463.7c0 .3 0 .6-.1.9-.9 3.1-1.8 6.3-2.8 9.4-.2.7-.7 1-1.3.8-.7-.1-1.4-.3-2-.5-1.7-.5-3.4-1-5-1.5-.6-.2-.8-.6-.7-1 1-3.4 2-6.7 3-10.1.2-.8.8-1.1 1.6-.9 2.2.7 4.5 1.4 6.7 2 .3.2.5.4.6.9z',
      },
      type: 'premium-hitech',
      number: {
        n: 79,
        x: 591,
        y: 471,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm614.1 468.5c-.1.4-.1.9-.2 1.3-.9 3-1.8 6-2.7 9-.3 1-.6 1.2-1.5 1-2.3-.6-4.7-1.2-7-2.1-.5-.2-.7-.6-.6-1.1.9-3.2 1.9-6.5 2.8-9.7.1-.2.2-.4.3-.6.2-.4.7-.8 1.1-.6 2.4.7 4.9 1.5 7.3 2.2.3.1.4.4.5.6z',
      },
      type: 'premium-hitech',
      number: {
        n: 80,
        x: 608,
        y: 476,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm628 485.1c-.5-.1-.8-.1-1-.2-2.2-.6-4.5-1.2-6.7-1.9-.6-.2-.9-.8-.7-1.4 1-3.3 2-6.7 2.9-10 0-.1.1-.2.2-.3.2-.4.8-.6 1.2-.5 2.2.7 4.4 1.3 6.7 2 .7.2 1 .7.9 1.2-1 3.5-2 6.9-3 10.4-.2.3-.4.6-.5.7z',
      },
      type: 'premium-hitech',
      number: {
        n: 81,
        x: 625.6,
        y: 481,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm640.8 475.7c2.3.7 4.7 1.4 7 2.1.4.1.7.7.6 1.2-1 3.5-2 6.9-3.1 10.4-.1.4-.7.7-1.1.6-2.3-.7-4.7-1.3-7-2-.6-.2-.9-.8-.7-1.4 1-3.4 2-6.7 2.9-10.1.2-.7.8-1 1.4-.8z',
      },
      type: 'premium-hitech',
      number: {
        n: 82,
        x: 642.5,
        y: 485,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm659 481.1c.1 0 .6.1 1 .2 4.8 1.3 6.1 1.8 6.1 2.3 0 2.1-1 4-1.5 6-.4 1.7-1 3.3-1.5 5-.1.4-.7.7-1.1.6-2.4-.7-4.9-1.4-7.3-2.2-.2-.1-.5-.6-.4-.8.9-3.3 1.9-6.7 2.9-10 .2-1 .7-1.3 1.8-1.1z',
      },
      type: 'premium-hitech',
      number: {
        n: 83,
        x: 660,
        y: 490,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm679.5 500.3c-2.3-.7-4.6-1.4-7-2.1-.5-.2-.7-.6-.6-1.1 1-3.3 2-6.7 2.9-10 .2-.8.8-1.2 1.5-.9 2.1.6 4.2 1.3 6.3 1.9 1.3.4 1.3.4.9 1.7-.9 3.2-1.9 6.5-2.9 9.7.1.7-.4.9-1.1.8z',
      },
      type: 'premium-hitech',
      number: {
        n: 84,
        x: 678,
        y: 495,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm692.9 504.3c-1.5-.5-3.7-1.1-6-1.8-.5-.2-.8-.6-.7-1 .8-3.7 2-7.2 3.1-10.8.1-.2.6-.4.9-.4.7.1 1.3.4 2 .6 1.2.3 2.5.6 3.7 1 2.3.7 2.3.7 1.7 3.1-.8 2.7-1.5 5.4-2.3 8.1-.1.2-.2.4-.3.6-.2.6-.7.8-2.1.6z',
      },
      type: 'premium-hitech',
      number: {
        n: 85,
        x: 692,
        y: 499,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm710.1 492.1c2.2.6 4.3 1.2 6.4 1.9.6.2.9.8.8 1.4-.2.8-.4 1.6-.7 2.3-.8 2.7-1.6 5.4-2.3 8.1-.1.3-.6.6-.8.6-2.6-.7-5.1-1.5-7.7-2.3-.2-.1-.5-.6-.4-.9.3-1.4.8-2.7 1.2-4 .6-2 1.1-4 1.7-6 .3-1 .7-1.5 1.8-1.1z',
      },
      type: 'premium-hitech',
      number: {
        n: 86,
        x: 711,
        y: 502.4,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm440.4 449.4c-.2 0-.5 0-.7-.1-2-.9-4-1.7-6.1-2.6-1-.4-1.3-1-.9-1.9 1.4-3.2 2.8-6.4 4.2-9.6.2-.4.8-.6 1.2-.4 2.2.9 4.5 1.9 6.7 2.9.4.2.6.8.5 1.2-.1.2-.1.5-.2.7-1.3 3.1-2.7 6.2-4 9.3-.1.2-.3.5-.7.5z',
      },
      type: 'comfort-hitech',
      isSold: true,
      number: {
        n: 70,
        x: 439,
        y: 444.6,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm458.9 443.9c.1 0 .3-.1.5 0 2.2.9 4.5 1.9 6.7 2.9.4.2.6.8.4 1.2 0 .1 0 .2-.1.3-1.4 3.2-2.8 6.4-4.2 9.6-.2.5-.7.7-1.1.6-2.2-1-4.5-1.9-6.7-2.9-.6-.3-.9-.9-.6-1.4 1.4-3.2 2.8-6.4 4.1-9.6.3-.3.5-.6 1-.7z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 69,
        x: 459.5,
        y: 453,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm478.9 465.7c-.3 0-.6 0-.8-.1-2.1-.9-4.3-1.8-6.4-2.8-.7-.3-.9-.8-.7-1.4 1.4-3.3 2.9-6.6 4.3-9.9.2-.4.8-.7 1.2-.5 2.2 1 4.5 1.9 6.7 2.9.6.2.7.8.5 1.5-1.4 3.2-2.8 6.4-4.2 9.6-.1.3-.4.5-.6.7z',
      },
      type: 'premium-hitech',
      number: {
        n: 68,
        x: 477,
        y: 460,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm495.9 473.1c-.4-.1-.7-.2-1-.3-1.8-.8-3.7-1.5-5.5-2.3-1.5-.7-1.6-.9-.9-2.4 1.3-3 2.6-6 3.9-9 .3-.7.7-.8 1.4-.5.3.1.7.2 1 .3 1.5.6 3 1.3 4.5 2 1.8.8 2 .9 1.1 2.9-1.3 2.9-2.5 5.8-3.8 8.7-.1.1-.5.3-.7.6z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 67,
        x: 494.6,
        y: 468,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm518.1 469.5c-.1.3-.2.5-.3.7-1.3 3.1-2.7 6.2-4 9.3-.4.9-.8 1.1-1.6.8-1.9-.8-3.8-1.7-5.8-2.5-1.5-.7-1.6-.8-.9-2.3 1.2-2.7 2.3-5.3 3.5-8 .1-.3.3-.6.4-1 .3-.6.9-.9 1.4-.7 2.2 1 4.5 1.9 6.7 2.9.3.1.4.6.6.8z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 66,
        x: 511.5,
        y: 474.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm530.1 487.8c-.2 0-.3-.1-.4-.1-.5-.2-1.1-.4-1.6-.6-2-1-4.2-1.4-5.9-2.9.8-1.9 1.6-3.9 2.4-5.8.6-1.4 1.2-2.8 1.8-4.2.5-1.2.9-1.3 2.1-.7 2 .9 4.1 1.8 6.1 2.7.6.2.8.7.6 1.1-1.4 3.3-2.9 6.6-4.3 10-.2.1-.6.3-.8.5z',
      },
      type: 'premium-hitech',
      isSold: true,
      number: {
        n: 65,
        x: 529,
        y: 481.5,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm537.4 485.3c.2 0 .5 0 .7.1 2.1.9 4.3 1.8 6.4 2.8.6.3.7.8.4 1.5-1.3 3.1-2.7 6.2-4 9.3-.4.9-.8 1.2-1.9.7-1.9-.9-3.8-1.6-5.8-2.5-1.1-.5-1.3-.8-.9-1.8 1.3-3 2.6-6 3.8-9 .1-.3.3-.6.6-.9.2-.1.5-.1.7-.2z',
      },
      type: 'premium-hitech',
      number: {
        n: 64,
        x: 538.5,
        y: 495,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm559.6 493.7c1.9.6 4.2 1.3 6.5 2 .5.2.8.6.7 1-1 3.3-1.9 6.7-2.9 10-.1.2-.2.4-.3.6-.2.3-.5.5-.9.4-1.8-.5-3.6-.9-5.4-1.5-2.4-.9-3-.3-2-3.5.9-2.9 1.7-5.8 2.6-8.7.1-.2.5-.4.8-.5.2 0 .3.1.9.2z',
      },
      type: 'premium-hitech',
      number: {
        n: 63,
        x: 561,
        y: 502,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm586.4 492.3c0 .3.1.5 0 .7-.9 3.1-1.8 6.3-2.7 9.4 0 .1-.1.2-.1.3-.3.8-.8 1.2-1.6 1-1.5-.4-2.9-.8-4.4-1.2-.8-.2-1.6-.5-2.3-.7-.6-.2-.9-.7-.7-1.4 1-3.4 1.9-6.7 2.9-10.1.1-.5.7-.8 1.1-.7 2.5.7 4.9 1.4 7.4 2.2.2.1.3.3.4.5z',
      },
      type: 'comfort-hitech',
      isSold: true,
      number: {
        n: 62,
        x: 580,
        y: 499,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm591.7 505.5c.9-3.2 1.9-6.5 2.8-9.7.1-.2.2-.4.3-.6.2-.4.7-.7 1.1-.6 2.3.7 4.7 1.4 7 2.1.4.1.7.6.6 1.1-.1.6-.3 1.1-.4 1.7-.8 2.8-1.6 5.6-2.4 8.4-.3 1-.6 1.2-1.8.8-2-.5-4-1.1-6-1.7-.2-.1-.4-.2-.6-.3-.6-.2-.8-.7-.6-1.2z',
      },
      type: 'comfort-hitech',
      isSold: true,
      number: {
        n: 61,
        x: 597,
        y: 504,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm615.3 500.3c1.6.5 3.1.9 4.7 1.4.4.1.7.7.6 1.1-1 3.5-2 6.9-3.1 10.4-.2.7-.6.8-1.4.6-.2-.1-.4-.1-.7-.2-2-.6-4-1.1-6-1.7-.6-.2-.9-.7-.7-1.4 1-3.5 2-6.9 3.1-10.4.1-.5.6-.7 1.1-.6.1 0 .2.1.3.1.8.3 1.5.5 2.1.7z',
      },
      type: 'premium-hitech',
      number: {
        n: 60,
        x: 614.5,
        y: 509,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm637.8 507.4v.4c-1 3.5-2 6.9-3 10.4-.2.7-.7.9-1.4.7-.9-.3-1.8-.5-2.7-.8-4-1.2-4.7-1.5-4.7-1.9-.1-1.7.7-3.1 1.2-4.7.6-2.1 1.2-4.2 1.9-6.4.1-.4.7-.6 1.1-.5 2.3.7 4.7 1.3 7 2.1.2.1.4.5.6.7z',
      },
      type: 'premium-hitech',
      number: {
        n: 59,
        x: 632,
        y: 514,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm646.8 509.7h.3c2.3.7 4.7 1.3 7 2 .4.1.7.7.6 1.1-.1.3-.1.7-.2 1-.9 3-1.8 6-2.7 9-.3 1-.9 1.2-1.8 1-2.2-.7-4.4-1.4-6.6-2-.4-.1-.7-.7-.6-1.1.9-3.2 1.9-6.5 2.8-9.7.1-.3.3-.6.5-.9.3-.2.5-.3.7-.4z',
      },
      type: 'premium-hitech',
      number: {
        n: 58,
        x: 649,
        y: 519,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm668.1 528.5c-2.3-.7-4.6-1.4-6.9-2.2-.5-.2-.7-.6-.6-1.1.9-3.2 1.9-6.5 2.8-9.7.1-.3.3-.6.4-.9.2-.3.5-.5.9-.4 2.3.7 4.7 1.4 7 2.1.7.2.9.6.7 1.3-.2.8-.4 1.6-.7 2.3-.7 2.6-1.5 5.1-2.3 7.7-.2.6-.7.9-1.3.9z',
      },
      type: 'premium-hitech',
      number: {
        n: 57,
        x: 667,
        y: 524,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm682.2 532.7c-1.5-.4-2.7-.8-4-1.1-.4-.1-.7-.7-.6-1.1.7-2.4 1.3-4.7 2-7.1.3-1.1.7-2.2 1.1-3.3.2-.7.6-.8 1.3-.6 2.1.6 4.2 1.2 6.4 1.8.8.2 1.2.8 1 1.5-1 3.5-2 6.9-3 10.4-.1.5-.6.6-1.1.5-1.2-.4-2.3-.7-3.1-1z',
      },
      type: 'premium-hitech',
      number: {
        n: 56,
        x: 683,
        y: 529,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm694.6 536c0-.4 0-.8.1-1.1.3-1.1.6-2.3.9-3.4.5-1.7 1-3.4 1.5-5 .9-2.9.5-2.3 3.7-1.6 1.7.4 3.3 1 5 1.5.4.1.7.7.6 1.1-1 3.5-2.1 6.9-3.1 10.4-.2.6-.6.9-1 .8-2.3-.7-4.7-1.4-7-2.1-.2-.1-.4-.4-.7-.6z',
      },
      type: 'comfort-hitech',
      isSold: true,
      number: {
        n: 55,
        x: 701,
        y: 533,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm734.2 524.8c-.1.2-.2.5-.4.6-.8.5-1.6 1-2.4 1.4-2.3 1.3-4.6 2.7-7 4-.5.3-1 .2-1.2-.2-1.3-2.2-2.6-4.4-3.8-6.6-.1-.2 0-.8.2-.9.1-.1.2-.2.3-.2 3.1-1.8 6.3-3.6 9.4-5.3.4-.2.9-.1 1.2.3 1.2 2.1 2.4 4.2 3.6 6.4.1 0 .1.3.1.5z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 54,
        x: 726.5,
        y: 526.7,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm743 540c-.1.1-.1.2-.2.3s-.1.2-.2.3c-3.1 1.8-6.3 3.6-9.4 5.4-.7.4-.9.3-1.4-.5-.1-.2-.3-.4-.4-.6-.2-.3-.3-.6-.5-.9-2.6-4.4-3-5.3-2.7-5.5 1.5-1.3 3.4-2 5-3.1 1.3-.8 2.6-1.5 3.9-2.3 1.5-.9 1.7-.9 2.6.8 1 1.8 2.1 3.6 3.1 5.5.2.2.2.4.2.6z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 53,
        x: 736,
        y: 541,
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm747.4 548.1c.2.1.5.1.6.3 1.3 2.2 2.6 4.4 3.8 6.7.2.4.1.9-.4 1.2-3.1 1.8-6.3 3.6-9.4 5.4-.5.3-.9.1-1.2-.3-1.3-2.2-2.6-4.4-3.8-6.7-.1-.2.1-.8.3-.9 3.2-1.9 6.5-3.7 9.7-5.6.2-.1.3 0 .4-.1z',
      },
      type: 'comfort-wright-style',
      isSold: true,
      number: {
        n: 52,
        x: 744,
        y: 556.5,
      },
    },
  ],
}
