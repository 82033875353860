<template>
  <modal :show="cabin" @close="$emit('close')" :lvl="1">
    <template #title>
      {{ cabin.label }}
    </template>

    <div class="row">
      <div class="column column-first" ref="container">
        <lightgallery
            v-if="container && cabin"
            :settings="{
                plugins: plugins,
                closable: false,
                showMaximizeIcon: true,
                container,
                addClass: 'cabin-test',
                download: false,
              }"
            :onInit="onInit"
        >
          <a v-for="(img, index) in images" :href="`/assets/cabins/${img}`" :key="index" class="iii">
            <img :src="`/assets/resized/cabins/${img}`" alt=""/>
          </a>
        </lightgallery>
      </div>

      <div v-if="cabin.infoInner.length" class="column second-column">
        <table>
          <tr v-for="(item, i) in cabin.infoInner" :key="i">
            <template v-if="item.empty">
              <td><br/></td>
              <td></td>
            </template>
            <template v-else>
              <td>{{ item.name }}</td>
              <td class="last-table-column">{{ item.value }} м<sup>2</sup> </td>
            </template>
          </tr>
        </table>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import {computed, ref} from "vue";
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default {
  name: "CabinModalNew",
  components: {
    Modal,
    Lightgallery,
  },
  emits: ['close'],
  props: {
    cabin: {
      type: null,
      required: true
    }
  },
  setup(props) {
    const container = ref()
    const plugins = [lgThumbnail, lgZoom]
    const images = computed(() => props.cabin?.img)

    const onInit = ({instance}) => {
      instance?.openGallery()
    }

    return {
      container,
      plugins,
      images,
      onInit,
    }
  }
}
</script>


<style lang="scss">

  .cabin-test {
    #lg-backdrop-1 {
      background-color: #252729 !important;
    }

    #lg-components-1 {

      .lg-thumb-outer {
        background-color: #252729 !important;
      }

    }
  }
</style>

<style scoped lang="scss">

table {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  
  @include tablet_desktop {
    flex-direction: row;
  }

  .column {
    &:first-child {
      flex: 100;
      position: relative;

      @include tablet_desktop {
        // width: 60%;
      }
    }

    &:last-child {
      color: white;
      flex: 400px;
      margin-left: 20px;
      
      .last-table-column {
        min-width: 75px;
      }
    }

    .iii {
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }

    @media only screen and (max-width: 1000px) {
      .column-first {
        min-height: 50vh;
      }
    }
}

</style>