<template>
    <div id="side-menu-overlay" @click.prevent="closeMenu">
        <div id="side-menu">
            <ul>
                <li v-for="(item, index) in items" v-bind:key="index" @click="scrollMeTo(item.id)" class="long-item">
                    {{item.name}}
                </li>
                <li class="socials">
                    <a class="social" @click="openLink('https://vk.com/ozernyj.cottage')">
                        <img src="/assets/icons/vk_b.svg">
                    </a>
                    <a class="social" @click="openLink('https://t.me/ozernij')">
                        <img src="/assets/icons/telegram_b.svg">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SideMenu',
        methods: {
            openMenu: function() {
                document.getElementById('side-menu-overlay').classList.add('open')
                document.getElementById('side-menu').classList.add('open')
                document.getElementById('menu').classList.add('isActive')
            },
            closeMenu: function() {
                document.getElementById('side-menu-overlay').classList.remove('open')
                document.getElementById('side-menu').classList.remove('open')
                document.getElementById('menu').classList.remove('isActive')
                document.getElementById('side-menu-overlay').classList.remove('mouseOnLeft')
                document.getElementById('side-menu').classList.remove('mouseOnLeft')
            },
            scrollMeTo(refName) {
                var element = document.getElementById(refName);
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            },
            openLink(url) {
                window.open(url, '_blank').focus();
            },
        },
        data () {
            return {
                items: [
                    { name: 'Главная', id: 'main' },
                    { name: 'Архитектура', id: 'architecture' },
                    { name: 'О проекте', id: 'about-project' },
                    { name: 'Коттеджи', id: 'cabins' },
                    { name: 'Ген. план', id: 'genplan' },
                    { name: 'Галерея', id: 'gallery' },
                    { name: 'Карта', id: 'yandex-map' },
                    { name: 'Связаться', id: 'call-us' },
                    { name: 'Документы', id: 'documents' },
                ],
            }
        },
    }
</script>

<style scoped lang="scss">
    #side-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        display: none;
    }

    #side-menu-overlay.open {
        display: block;
    }

    #side-menu-overlay.mouseOnLeft {
        display: block;
    }

    #side-menu {
        position: fixed;
        width: 200px;
        height: 100vh;
        background: white;
        left: -200px;
    }

    @keyframes myship{
        from {left: -200px;} 
        to{left:0;} 
    }
    @-moz-keyframes myship{
        from {left: -200px;} 
        to{left:0;} 
    }
    @-webkit-keyframes myship{
        from {left: -200px;} 
        to{left:0;} 
    }
    
    #side-menu.open {
        animation:myship 200ms;
        -moz-animation:myship 200ms; /* Firefox */
        -webkit-animation:myship 200ms; /* Safari and Chrome */
        left: 0;
    }

    #side-menu.mouseOnLeft {
        animation:myship 200ms;
        -moz-animation:myship 200ms; /* Firefox */
        -webkit-animation:myship 200ms; /* Safari and Chrome */
        left: 0;
    }
    

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li.long-item {
        padding: 20px;
    }

    li.long-item:hover {
        background: #d2b281;
        color: white;
        cursor: pointer;
    }
    li {
        color: #222222;
    }

    .socials {
        display: flex;

        .social{
            padding: 20px 20px 20px 20px;

            img {
                height: 40px;
                width: 40px;
                display: block;
            }

            // &:first-child {
            //     padding-left: 20px;
            // }

            &:hover {
                background: #d2b281;
                cursor: pointer;
            }
        }

    }


    a {
        text-decoration: none;
    }
</style>