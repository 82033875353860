export default   {
  shape: {
    tag: 'polygon',
    attrs: {
      points: '20,373,17,276,59,282,67,102,140,226,206,254,205,277,278,485'
    }
  },
  name: 'Очередь 5',
  id: 5,
  imgForModal: {
    img: '/assets/area5.svg',
    viewBox: {
      x: '0',
      y: '95',
      width: '290.72',
      height: '399.18',
    },
  },
  cabins: [
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm86 143c-.1.2-.2.4-.3.6-.1.1-.2.2-.3.2-3.4 1.2-6.8 2.4-10.1 3.6-.6.2-1.2-.1-1.4-.7-.3-.9-.6-1.8-.9-2.6-1.6-4.6-1.6-4.6-1.3-4.9 1.3-1 3-1.2 4.5-1.8l.6-.3c5.2-1.9 6-2.1 6.2-1.8 1.3 1.8 1.6 4.1 2.5 6.1.3.5.4 1.1.5 1.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm92.5 158.4c.2.1.4.2.6.3.1.1.2.2.2.3.8 2.3 1.6 4.6 2.4 6.9.2.7 0 1.1-.7 1.3-3.3 1.2-6.5 2.4-9.8 3.5-.6.2-1.2-.1-1.4-.6-.1-.3-.2-.7-.3-1-2.2-5.8-2.3-6.2-1.9-6.5 1.8-1.3 4-1.6 6.1-2.4 1.4-.5 2.8-1 4.3-1.5.1-.2.3-.3.5-.3z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm102.4 184.5c-.1.2-.2.5-.4.5-1.1.4-2.2.8-3.3 1.2-2.4.9-4.8 1.7-7.2 2.6-.4.2-1-.1-1.1-.6-.9-2.4-1.7-4.8-2.6-7.2-.1-.2.1-.5.3-.6.4-.3.8-.5 1.2-.6 3.1-1.1 6.1-2.2 9.2-3.3.7-.2 1-.1 1.3.7.9 2.3 1.7 4.6 2.5 6.9.1.2.1.3.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm105.8 194.9c.2.1.4.2.5.4.2.4.4.8.5 1.3.7 2 1.4 3.9 2.1 5.9.1.4-.1 1-.6 1.1-3.3 1.2-6.5 2.4-9.8 3.5-.2.1-.5.1-.7.1-.3-.1-.8-.2-.9-.5-.9-2.4-1.7-4.8-2.5-7.2-.1-.2.1-.5.2-.6.4-.3.8-.5 1.2-.7 3.1-1.1 6.1-2.1 9.2-3.2.3 0 .5 0 .8-.1z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm111 212c1-.3 1.3-.2 1.5.4.9 2.4 1.8 4.8 2.6 7.2.1.2-.2.7-.5.8-3.4 1.3-6.8 2.5-10.1 3.7-.6.2-1.2 0-1.4-.6-.8-2.3-1.7-4.6-2.5-6.9-.2-.4.1-.8.6-1.1.8-.3 1.5-.6 2.3-.8 2.1-.8 4.1-1.5 6.2-2.3.5-.2 1.1-.3 1.3-.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm109.4 239.4c-.2-.1-.4-.2-.6-.3-.2-.2-.3-.4-.4-.6-.8-2.1-1.5-4.2-2.3-6.2-.3-.9 0-1.5 1-1.9 3.2-1.1 6.4-2.3 9.5-3.4.7-.2 1.1 0 1.3.7.8 2.3 1.7 4.6 2.5 6.9.1.3-.2.9-.6 1.1-3.4 1.2-6.8 2.4-10.2 3.7 0 0-.1 0-.2 0z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm114.7 254.2c-.2-.1-.5-.1-.6-.3-.2-.3-.3-.6-.4-.9-.8-2.1-1.5-4.2-2.3-6.2-.2-.7 0-1 .7-1.4.2-.1.4-.2.7-.2 1.4-.5 2.9-1 4.3-1.5 1.9-.6 3.7-1.3 5.6-1.9 1.2.8 1.2 2.2 1.7 3.3.4.8.6 1.8.9 2.6.2.5.4 1.1.6 1.6.1.4-.2 1-.6 1.2-.1 0-.2.1-.3.1-3.2 1.1-6.3 2.3-9.5 3.4-.4.1-.6.1-.8.2z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm134.7 267.3c-.2.2-.3.7-.6.8-2.3.9-4.6 1.7-6.9 2.5-.6.2-1.2-.1-1.4-.7-1.2-3.3-2.4-6.6-3.6-9.9-.1-.4.1-1.1.4-1.2 2.3-.8 4.6-1.6 6.9-2.4.6-.2 1.2.1 1.4.7 1.2 3.3 2.4 6.6 3.6 9.9.2 0 .2.1.2.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm148.1 262.6c-.1.2-.2.5-.4.6-2.5.9-5 1.8-7.5 2.7-.1 0-.4-.2-.6-.3-.2-.2-.3-.4-.4-.6-1.2-3.3-2.4-6.6-3.5-9.8-.2-.6.1-1.2.7-1.4 2.2-.8 4.4-1.6 6.6-2.3.6-.2 1.3.1 1.5.6 1.2 3.4 2.4 6.8 3.6 10.2z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm160 245.4c.2.2.6.3.7.6 1 2.7 2 5.4 3 8.2.2.7.5 1.3.7 2 .2.6-.1 1.1-.7 1.4-.2.1-.4.2-.6.2-1.8.7-3.7 1.4-5.5 2-1 .3-1.5.1-1.9-.9-.6-1.5-1.2-3-1.6-4.6-.6-2-1.7-3.8-1.8-5.9 1.8-1.1 3.9-1.4 5.8-2.3.4-.4 1.1-.5 1.9-.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm181.5 269.3c.1 1.8.2 3.5.3 5.3 0 .4-.4.9-.9.9-2.1.2-4.2.4-6.3.5-1.9.1-1.9-.1-2.1-1.9-.2-2.6-.3-5.1-.4-7.7 0-.8-.1-1.6 0-2.4 0-.2.3-.5.5-.5l4.2-.3c1.2-.1 2.3-.2 3.5-.3.4 0 .9.4.9.9 0 .3.1.7.1 1 .1 1.5.1 3 .2 4.6-.1-.1 0-.1 0-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm198.7 269.4c.1 1.3.1 2.6.2 3.9 0 .9-.4 1.3-1.2 1.4-1.8.1-3.5.1-5.3.2-.6 0-1.2.1-1.7.2-.5 0-.9-.4-1-.8 0-.5-.1-.9-.1-1.4-.1-1.9-.3-3.7-.4-5.6-.1-1.3-.1-2.6-.1-3.9 0-.4.4-1 .8-1 2.6-.4 5.1-.4 7.7-.3.3 0 .6.3.6.7.1 1.7.3 3.5.5 5.2-.1.5-.1.9 0 1.4-.1 0 0 0 0 0z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm79.3 212.1c-.1.2-.1.5-.3.6-.3.2-.6.4-.9.5-2.1.8-4.1 1.5-6.2 2.3-.6.2-1.2-.1-1.4-.7 0-.1-.1-.2-.1-.3-.5-1.4-1-2.9-1.5-4.3s-1-2.9-1.5-4.3c-.8-2.1-.7-2 1.5-2.9 1.4-.5 2.9-1 4.3-1.5.4-.2.9-.3 1.3-.5.4-.1.9.1 1.1.6 0 .1.1.2.1.3 1.2 3.3 2.4 6.6 3.6 9.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm85.7 230.1c-.1.3-.2.5-.3.7 0 .1-.2.2-.3.2-3.3 1.2-6.6 2.4-9.8 3.6-.2.1-.5.1-.7.1-.4.1-.7-.2-.8-.5-.9-2.4-1.8-4.8-2.6-7.2-.2-.4.2-.7.5-.8.5-.3 1.1-.5 1.6-.7 2.1-.7 4.2-1.5 6.2-2.2 3.9-1.3 3.1-1.8 4.6 2 .5 1.3 1 2.6 1.4 3.9.1.3.1.6.2.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm89.7 242.4c.2.1.5.2.5.4.9 2.4 1.7 4.8 2.6 7.2.1.3-.3 1-.6 1.1-3.4 1.2-6.8 2.4-10.2 3.7-.1 0-.2.1-.3 0-.2-.1-.4-.2-.6-.3-.1-.1-.2-.2-.2-.3-.8-2.3-1.6-4.6-2.4-6.9-.1-.4.1-1 .4-1.2 3.5-1.3 7-2.5 10.5-3.8.1.1.2.1.3.1z',
      }
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm97.4 263.8c.2.1.4.3.5.4.6 1.4 1.1 2.8 1.6 4.2.3.8.5 1.5.8 2.3.3 1 .1 1.5-.9 1.9-3.1 1.1-6.1 2.2-9.2 3.3-.1 0-.2.1-.3.1-.6.2-1.3 0-1.4-.6-.8-2.3-1.6-4.6-2.4-6.9-.2-.5-.1-.8.5-1 1.2-.5 2.4-.9 3.6-1.3 2.2-.8 4.4-1.6 6.6-2.3.2-.1.4-.1.6-.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm75.4 272.4c.6 1.6 1.1 3.3 1.7 5 .1.2-.1.5-.2.6-.6.3-1.2.6-1.9.9-5.1 1.9-6 2.2-6.2 1.8-1.1-1.8-1.5-3.9-2.3-5.8-.6-1.5-1.1-3.1-1.7-4.6-.3-.8 0-1.3.8-1.6 2.2-.8 4.4-1.6 6.6-2.4.4-.1 1 .1 1.2.6.6 1.8 1.3 3.6 2 5.5z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm32.5 294.8c-1.8 0-3.5 0-5.3 0-.8 0-1.2-.5-1.2-1.3 0-2.3 0-4.7.1-7 0-.5.4-.9.9-.9.3 0 .7-.1 1-.1 3 .1 6.1.1 9.1.2.2 0 .5 0 .7.1.5 0 .9.4.9.9 0 2.5 0 4.9-.1 7.4 0 .5-.4.9-.9.9-1.7-.1-3.5-.2-5.2-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm54 309.1c0 1.3.1 2.6.1 3.9 0 .5-.3.9-.8 1-2.4.2-4.9.4-7.3.5-.4 0-.9-.4-1-.8 0-.1-.1-.2-.1-.3-.2-2.9-.4-5.8-.6-8.7 0-.7 0-1.4.1-2.1 0-.2.2-.4.4-.5.1-.1.2-.1.3-.2 2.5-.4 5.1-.6 7.7-.4.4 0 .6.3.6.7.1 1.2.3 2.3.4 3.5.2 1.1.2 2.2.2 3.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm55.4 338.6c.3.1.6.1.7.3.2.3.4.6.5.9.8 2.1 1.5 4.2 2.2 6.2.2.6-.1 1.2-.7 1.4-1.1.4-2.2.8-3.3 1.2-1.6.6-3.2 1.3-4.9 1.8-2.8.8-2.5 1.7-3.7-2-.6-1.7-1.2-3.3-1.8-4.9-.1-.4.2-1 .6-1.1 2.4-.9 4.8-1.7 7.2-2.6 1.1-.4 2.2-.8 3.2-1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm71.7 326.2c-.2-.1-.5-.2-.6-.3-.9-2.5-1.8-5-2.7-7.5-.1-.3.2-.7.5-.8l10.2-3.6c.6-.2 1.2.1 1.4.7.8 2.1 1.5 4.1 2.3 6.2.4 1 .2 1.4-.9 1.8-3 1.1-6.1 2.2-9.2 3.3-.3.1-.7.1-1 .2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm80.7 351.7c-.1-.1-.2-.1-.3-.2s-.2-.2-.2-.3c-.1-.3-.2-.7-.4-1-2.1-5.4-2.5-6.6-2.1-6.9 2-1.3 4.3-1.7 6.4-2.6 1.3-.6 2.6-.9 3.9-1.4.8-.3 1.3 0 1.6.8.8 2.2 1.6 4.4 2.3 6.6.2.4-.1 1-.5 1.1-.5.2-1.1.4-1.6.6-2.8 1-5.7 2.1-8.5 3.1-.2.2-.4.2-.6.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm83.1 359.4c.3-.2.4-.4.6-.5 3.5-1.3 7-2.5 10.5-3.8.4-.1.6.1.9.4.1.1.2.2.2.3l2.4 6.6c.2.6 0 1.3-.6 1.5-3.3 1.2-6.5 2.4-9.8 3.6-.1 0-.2 0-.3.1-.4.1-1-.1-1.2-.5-1-2.7-1.9-5.2-2.7-7.7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm91.8 382.7c-.1-.1-.2-.1-.3-.2s-.2-.2-.2-.3c-.2-.4-.4-.8-.6-1.3-1.9-5-2.3-6.3-1.9-6.6 1.9-1.2 4.1-1.6 6.1-2.4 1.4-.6 2.8-1 4.3-1.5.8-.3 1.3 0 1.6.8l2.4 6.6c.2.5-.1.9-.5 1.1-.3.1-.6.3-1 .4-3.1 1.1-6.1 2.2-9.2 3.3-.3 0-.5 0-.7.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm109.5 313.9c-.1.2-.1.5-.3.6-.5.3-1 .6-1.6.8-1.9.7-3.7 1.3-5.6 2-.6.2-1.1 0-1.3-.7-.1-.2-.2-.4-.2-.7-.5-1.4-1-2.9-1.5-4.3-.5-1.3-1-2.6-1.4-3.9-.7-2-.7-2 1.5-2.8 1.3-.5 2.6-1 3.9-1.4.7-.2 1.3-.4 2-.6.2 0 .4.1.6.3s.3.4.3.6c1.2 3.2 2.3 6.4 3.4 9.5.2.1.2.3.2.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm123.8 308.9c-.1.2-.2.5-.4.6-1.3.5-2.6 1-3.9 1.5-4.8 1.7-3.8 1.9-5.5-2.5-.8-2-1.4-3.9-2.1-5.9-.2-.4-.3-.9-.3-1.4 0-.3.2-.8.4-.9 2.4-1 4.7-1.9 7.2-2.6.4-.1.8 0 .9.3 1.3 3.5 2.6 7 3.8 10.5 0 .1 0 .3-.1.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm122.5 339.6c-.2.3-.4.7-.6.8-3.4 1.2-6.8 2.4-10.1 3.6-.4.1-1.1-.2-1.2-.5-.8-2.3-1.6-4.6-2.4-6.9-.2-.6.1-1.2.7-1.4 1.4-.5 2.8-1.1 4.2-1.6s2.8-1 4.3-1.5c2.3-.8 2.2-1.2 3.1 1.4.5 1.4 1.1 2.8 1.6 4.2.1.8.2 1.4.4 1.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm128.4 356.2c-.1.2-.2.4-.3.6-.2.1-.4.2-.6.3-3 1.1-6.1 2.2-9.1 3.3-.3.1-.7.2-1 .2-.4 0-.7-.2-.8-.5-.4-1.1-.9-2.1-1.3-3.2-.4-1.2-.9-2.4-1.3-3.6-.2-.6 0-1.1.7-1.3 1-.4 1.9-.7 2.9-1.1 2.1-.8 4.1-1.5 6.2-2.2.3-.1.7-.2 1-.2.5-.1.9.1 1.1.6.9 2.3 1.7 4.6 2.5 6.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm128.6 364.7c.7-.2 1.3-.4 2-.6.2 0 .5.1.6.2.3.4.5.8.7 1.2.7 2 1.4 3.9 2.1 5.9.2.6 0 1.2-.7 1.4-3.3 1.2-6.6 2.4-9.8 3.6-.8.3-1.2 0-1.6-.9-.8-2.2-1.6-4.4-2.4-6.6-.1-.4.1-.9.6-1.1.9-.4 1.7-.7 2.6-1 2-.7 4-1.4 5.9-2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm149 375.7c.2.2.7.3.8.5l1.8 4.5c.3.9.6 1.8.9 2.6.1.4-.2.6-.4.8-.2.1-.4.2-.6.3-3.1 1.1-6.1 2.2-9.2 3.3-.3.1-.7.2-1 .2-.2 0-.5-.2-.5-.3-.8-2.1-1.6-4.1-2.3-6.2-.2-.4-.3-.9-.4-1.3 0-.2.1-.5.3-.6.6-.3 1.2-.6 1.9-.8 2.6-1 5.2-1.9 7.9-2.8 0 0 .4-.1.8-.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm159.1 401.2c-.1.2-.2.4-.4.5-.3.2-.6.4-.9.5-3.2 1.1-6.3 2.2-9.5 3.4-.6.2-1.1-.1-1.3-.7-.7-2-1.5-3.9-2.1-5.9-.4-1.2-.4-1.8 1.1-2.3 3-1 5.9-2.1 8.8-3.2.1 0 .2-.1.3-.1.6-.2 1.3.1 1.4.6.8 2.3 1.6 4.6 2.4 6.9.2.1.2.2.2.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm155.8 303.7c-.1.2-.2.4-.4.6-.3.2-.6.4-.9.5-3.2 1.2-6.3 2.3-9.5 3.4-.5.2-1.1-.1-1.4-.7-.2-.5-.4-1.1-.6-1.6-1.9-5.1-2-5.6-1.7-5.9 1.6-1.2 3.6-1.4 5.5-2.2.6-.3 1.3-.5 2-.7 1.3-.4 2.5-1.3 3.9-1.1.9 1.1 1.1 2.5 1.7 3.8.5 1.2.9 2.4 1.3 3.6 0 .1 0 .2.1.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm155.2 320.5c-.2-.2-.5-.3-.6-.4-.9-2.4-1.7-4.8-2.6-7.3-.1-.3.1-.7.4-.8 2-.7 3.9-1.5 5.9-2.2 1.4-.5 2.9-1 4.3-1.5.6-.2 1.2.1 1.4.6.8 2.2 1.6 4.4 2.4 6.6.2.6 0 1.1-.7 1.3-3.2 1.2-6.4 2.3-9.5 3.5-.3.1-.7.1-1 .2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm157.6 328.2c.1-.3.2-.5.3-.6.4-.2.8-.4 1.3-.6 2.8-1 5.6-2.1 8.5-3.1.3-.1.7-.2 1-.2s.7.2.8.5c.5 1.1.9 2.1 1.3 3.2.4 1.2.9 2.4 1.3 3.6.2.7 0 1.1-.7 1.3-3.1 1.2-6.3 2.3-9.5 3.4-.3.1-.7.2-1 .1-.2 0-.5-.1-.6-.3-1.1-2.3-1.9-4.9-2.7-7.3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm177.6 346.8c-.1.2-.1.5-.3.6-.3.2-.6.3-.9.4-2.9 1.1-5.9 2.2-8.8 3.2-.1 0-.2.1-.3.1-.9.3-1.4.1-1.7-.7-.8-2.3-1.7-4.6-2.5-6.9-.1-.4.2-.7.5-.8.4-.2.8-.4 1.3-.6 2.7-1 5.5-2 8.2-2.9 1.6-.6 1.8-.4 2.5 1.3.7 1.9 1.3 3.7 2 5.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm183.3 362.4c-.1.3-.1.6-.3.7-.4.3-.8.5-1.2.6-2.4.9-4.8 1.8-7.2 2.6-.9.3-1.8 1-2.9.7-.6-.7-.8-1.7-1.1-2.5-.6-1.7-1.2-3.3-1.8-4.9-.2-.4.1-1 .6-1.1 3.4-1.2 6.8-2.4 10.2-3.6.4-.2 1 .1 1.1.5.9 2.3 1.8 4.7 2.6 7z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm187.7 374.5c.5 1.3.8 2.3 1.1 3.3.1.2-.1.5-.2.6-.4.3-.8.5-1.2.6-1 .4-2 .7-3 1-1.6.6-3.3 1.2-4.9 1.8-2.3.8-2.4.8-3.4-2.1-.6-1.6-1.2-3.3-1.8-4.9-.1-.4.1-1 .6-1.1 3.3-1.2 6.6-2.4 9.8-3.5.8-.3 1.3 0 1.6.7.5 1.4 1 2.7 1.4 3.6z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm183.3 398.2c-.2-.1-.5-.1-.6-.3-.2-.4-.4-.8-.6-1.3-.7-1.9-1.3-3.7-2-5.6-.3-1-.1-1.5 1-1.9 3.2-1.1 6.3-2.3 9.5-3.4.7-.2 1.1 0 1.3.7.9 2.3 1.7 4.6 2.5 6.9.1.4-.1.7-.4.9-.2.1-.4.3-.6.4-3.3 1.2-6.6 2.4-9.9 3.5 0 .1-.1.1-.2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm199.9 408.9c-.1.2-.2.4-.4.5-.3.2-.6.4-.9.5-3.1 1.1-6.1 2.2-9.2 3.2-.9.3-1.4.1-1.7-.6-.8-2.1-1.5-4.1-2.2-6.2-.3-.9-.1-1.4 1-1.8 3.2-1.1 6.3-2.3 9.5-3.4.6-.2 1.2.1 1.4.6.8 2.3 1.6 4.6 2.5 6.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm205.4 424.4c-.1.2-.2.4-.3.5-.3.2-.6.4-.9.5-3.2 1.1-6.3 2.2-9.5 3.3-.6.2-1.1 0-1.4-.7-.8-2.2-1.6-4.4-2.3-6.6-.2-.7 0-1.2.6-1.4 3.3-1.2 6.5-2.4 9.8-3.5.1 0 .2 0 .3-.1.4-.1 1 .2 1.1.6.8 2.2 1.6 4.4 2.3 6.6.2.3.3.5.3.8z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm195 303c-.1.1-.1.2-.2.3-.3.2-.5.5-.9.6-3.3 1.2-6.6 2.3-9.8 3.5-.4.1-1-.2-1.1-.5-.8-2.3-1.6-4.6-2.4-6.9-.2-.5.2-1.1.8-1.3 3.3-1.2 6.5-2.4 9.8-3.5.6-.2 1.1.1 1.4.7.8 2.3 1.6 4.6 2.4 6.9-.1 0 0 .1 0 .2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm200.3 318.3c-.1.2-.2.4-.4.5-.3.2-.6.3-.9.5-2.8 1-5.7 2-8.5 3.1-.3.1-.7.2-1 .2s-.8-.2-.9-.4c-.4-.9-.8-1.9-1.2-2.9-.4-1.1-.8-2.2-1.2-3.3-.5-1.5-.4-1.7 1.2-2.3 2.8-1 5.7-2.1 8.5-3.1.3-.1.7-.2 1-.2s.8.2.9.4c1 2.4 1.9 4.7 2.5 7.2.1.1 0 .2 0 .3z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm195.1 338.8c-.2-.1-.4-.2-.6-.3s-.3-.4-.4-.6c-.8-2.2-1.6-4.4-2.3-6.5-.2-.4.1-1.1.4-1.2 3.4-1.2 6.8-2.4 10.1-3.6.7-.2 1.2 0 1.4.6.7 1.7 1.4 3.5 1.9 5.2.9 2.6 1.1 2.3-1.4 3.2-2.6 1-5.2 1.9-7.8 2.8-.4.2-.8.3-1.3.4z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm211.6 349.8c-.2.2-.4.5-.7.6-1.8.7-3.7 1.4-5.6 2.1-1.3.5-2.6.9-3.9 1.4-1 .4-1.5.2-1.8-.9-.7-1.9-1.4-3.7-2-5.6-.5-1.4-.3-1.9 1-2.4 3.2-1.1 6.3-2.3 9.5-3.4.4-.1 1 .1 1.1.5.8 2.3 1.6 4.6 2.4 6.9z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm217.2 365.2c-.1.2-.2.4-.3.6-.2.1-.4.2-.6.3-3.1 1.1-6.1 2.2-9.2 3.4-.1 0-.2.1-.3.1-.6.2-1.3-.1-1.5-.6s-.4-1.1-.6-1.6c-1.9-5-2-5.6-1.6-5.9 1.6-1.3 3.6-1.6 5.5-2.2 5.5-2.1 5.6-2.1 5.9-1.7 1.2 1.6 1.5 3.6 2.2 5.5.3.7.4 1.3.7 2-.2-.2-.2 0-.2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm211.8 385.4c-.2-.1-.5-.2-.6-.3-1.2-2.4-2-4.9-2.7-7.5-.1-.2.3-.6.4-.9 0-.1.2-.1.3-.2 3.3-1.2 6.5-2.4 9.8-3.5.6-.2 1.2.1 1.4.6.7 2.1 1.5 4.2 2.2 6.2.4 1.2.2 1.6-1.1 2.1-3.2 1.1-6.3 2.3-9.5 3.4 0 .1-.1.1-.2.1z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm228.6 396.7c-.1.2-.2.5-.3.6-.5.3-1 .5-1.6.7-2.7 1-5.4 2-8.2 2.9-.2.1-.5.1-.7.2-.4.1-1.1-.2-1.2-.5-.8-2.3-1.6-4.6-2.4-6.9-.2-.5.1-.9.5-1.2.3-.2.6-.3.9-.5 3.1-1.1 6.1-2.2 9.2-3.2.4-.1 1.1 0 1.2.3.9 2.4 1.7 4.8 2.6 7.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm234.4 412.5c-.1.2-.2.4-.4.5-.3.2-.6.4-.9.5-3.2 1.1-6.3 2.3-9.5 3.4-.7.2-1.1 0-1.3-.7-.8-2.1-1.5-4.2-2.2-6.2 0-.1-.1-.2-.1-.3-.2-.6.1-1.3.6-1.4 3.3-1.2 6.6-2.4 9.8-3.5.7-.2 1.2-.1 1.4.6.8 2.3 1.6 4.6 2.5 6.9 0 0 0 .1.1.2z',
      },
    },
    {
      img: [],
      plan: [],
      name: '',
      info: [],
      attrs: {
        d: 'm236.5 420.1c.2.1.4.2.6.3.2.2.2.4.3.6.7 2.1 1.5 4.2 2.2 6.2.3.8.2 1.4-1 1.8-3.2 1.1-6.3 2.3-9.5 3.4-.6.2-1.2 0-1.4-.7-.8-2.2-1.6-4.4-2.3-6.6-.3-.7.1-1.3.9-1.6 3.2-1.1 6.3-2.3 9.5-3.4.3.1.5.1.7 0z',
      },
    },
  ],
}