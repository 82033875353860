<template>

  <svg :viewBox="inModal ? null : viewBox.main" class="map" :style="svgStyle" xmlns="http://www.w3.org/2000/svg">
          
    <filter id="outline" color-interpolation-filters="sRGB">
      <feDropShadow  dx="0.4" dy="0.4" stdDeviation="0.4" flood-color="black"/>
      

    </filter>

    <inline-svg :src="svgBgImage" :viewBox="inModal ? null : viewBox.main"/>
    <svg :viewBox="viewBox.second">
      <template
          v-for="(polygon, index) in areas"
          :key="`polygon${index}`"
      >
        <g
            class="group"
            :class="{ _inModal: inModal }"
            @mouseover="$emit('pointLocation', $event)"
            @mouseout="$emit('unpointLocation', $event)"
            @mousemove="$emit('mousemove', $event)"
        >
          <component
              :is="polygon.shape.tag"
              :data-name="polygon.name"
              v-bind="polygon.shape.attrs"
              :class="{
                _active: polygon.active,
                _border: polygon.showBorder && !inModal,
              }"
              class="polygon"
              @click="polygon.active && !inModal ? $emit('areaClick', polygon) : null"
          />
          <path
              v-for="(c, j) in polygon.cabins"
              :key="`cabin-${j}`"
              v-bind="c.attrs"
              :fill="getColor(c)"
              class="cabin"
              :class="{_active: polygon.active && inModal}"
              @click="polygon.active && inModal ? $emit('cabinClick', c) : null"
              @mouseover="$emit('cabinHover', c.type)"
              @mouseout="$emit('cabinHover', null)"
              @focusin="$emit('cabinHover', c.type)"
              @focusout="$emit('cabinHover', null)"
          />


          <template
              v-for="(c, j) in polygon.cabins"
              :key="`number-${j}`"
          >
          <!-- filter:url(#outline); -->
            <g
              v-if="c.number"
              :transform="`translate(${c.number.x}, ${c.number.y})`"
            >
              <text
                  text-anchor="middle"
                  :style="`
                      font-family:Tahoma;
                      font-size:7px;
                      transform: rotate(${c.number.angle || 0}deg);
                  `"
                  fill="white"
                  stroke-width="1"
                  paint-order="stroke"
                  stroke="black"


                  :class="{_active: polygon.active}"
                  @click="polygon.active && inModal ? $emit('cabinClick', c) : null"
                  @mouseover="$emit('cabinHover', c.type)"
                  @mouseout="$emit('cabinHover', null)"
                  @focusin="$emit('cabinHover', c.type)"
                  @focusout="$emit('cabinHover', null)"
              >
              
                  {{ c.number.n }}
              </text>
            </g>
          </template>
        </g>
      </template>
    </svg>
  </svg>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import cabinData from "../../../public/assets/js/cabinData";
import { computed } from "vue";

const viewBoxWidthDefault = 1312
const viewBoxHeightDefault = 1070
const bgImgDefault = '/assets/map.svg'

export default {
  name: "MapSvg",
  emits: [
    'pointLocation',
    'unpointLocation',
    'mousemove',
    'areaClick',
    'cabinClick',
    'cabinHover',
  ],
  components: {
    InlineSvg,
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    },
    areas: {
      type: Array,
      default: () => []
    },
    activeType: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const getColor = (cabin) => {
      const color = (cabin?.isSold == true) ? '#757575' : cabinData?.[cabin?.type]?.color

      if (props.activeType == 'sold') return cabin?.isSold == true ? '#757575' : 'white'
      return props.activeType
        ? cabin?.type === props.activeType
            ? color
            : 'white'
          : color;
    }

    const firstArea = computed(() => {
      return props.areas?.[0]
    })

    const viewBoxX = computed(() => {
      return firstArea.value?.imgForModal?.viewBox?.x || 0
    })

    const viewBoxY = computed(() => {
      return firstArea.value?.imgForModal?.viewBox?.y || 0
    })

    const myViewBoxWidth = computed(() => {
      return document.getElementById('map-svg-wrap')?.clientWidth; 
    })

    const myViewBoxHeight = computed(() => {
      return document.getElementById('map-svg-wrap')?.clientHeight; 
    })

    const viewBoxWidth = computed(() => {
      return firstArea.value?.imgForModal?.viewBox?.width || viewBoxWidthDefault
    })

    const viewBoxHeight = computed(() => {
      return firstArea.value?.imgForModal?.viewBox?.height || viewBoxHeightDefault
    })

    const viewBox = computed(() => {
      return props.inModal ?
          {
            main: `0 0 ${myViewBoxWidth.value} ${myViewBoxHeight.value}`,
            second: `${viewBoxX.value} ${viewBoxY.value} ${viewBoxWidth.value} ${viewBoxHeight.value}`
          } : {
            main: `0 0 ${viewBoxWidthDefault} ${viewBoxHeightDefault}`,
            second: `0 0 ${viewBoxWidthDefault} ${viewBoxHeightDefault}`
          }
    })

    const svgBgImage = computed(() => {
      return (props.inModal && firstArea.value?.imgForModal?.img) ? firstArea.value.imgForModal.img : bgImgDefault
    })

    const svgStyle = computed(() => {
      return props.inModal ? {
        maxWidth: myViewBoxWidth.value,
        height: myViewBoxHeight.value
      } : {}
    })

    return {
      getColor,
      cabinData,
      firstArea,
      viewBox,
      svgBgImage,
      svgStyle,
    }
  }
}
</script>

<style scoped lang="scss">
.map {
  margin-top: -180px;

  .group {
    .polygon {
      stroke-opacity: 0;
      fill-opacity: 0;
      stroke: transparent;
      stroke-width: 5px;
      fill: transparent;
      transition: all ease-in-out .2s;

      &._active {
        cursor: pointer;
      }

      &._border {
        stroke: #d2b281;
        fill: #d2b281;
        animation: activeArea 2s infinite ease-in-out;
      }
    }

    &:hover,
    &._inModal {
      .polygon {
        stroke-opacity: 1;
        fill-opacity: 0.2;
        animation: none;
        stroke: #d2b281;
        fill: #d2b281;
      }
    }

    .cabin {
      pointer-events: none;
      transition: all ease-in-out .2s;

      &._active {
        cursor: pointer;
        pointer-events: all;
      }
    }

    text {
      &._active {
        cursor: pointer;
      }
    }

    &._inModal {
      .polygon {
        cursor: default;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .map {
    margin-top: -120px;
  }
}

@media only screen and (max-width: 800px) {
  .map {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 500px) {
  .map {
    margin-top: -45px;
  }
}

@keyframes activeArea {
  from {
    stroke-opacity: 0;
    fill-opacity: 0;
  }

  50% {
    stroke-opacity: 1;
    fill-opacity: 0.2;
  }

  to {
    stroke-opacity: 0;
    fill-opacity: 0;
  }
}
</style>