<template>
    <div class="arch-all" id="architecture">
        <div class="spacer">
            <div class="row right">
                <h1 data-aos="fade-up">Архитектура</h1>
            </div>

            <div class="row">
                <div class="column">
                    <div class="text">
                        <h4 data-aos="fade-up">Дома на любой вкус</h4>
                        <p>Спроектированы дома различный стилей - от изысканного стиля Райта, до современного Хайтека, учитывая различные вкусы и запросы. Зонирование по стилям сделано с учетом всех особенностей ландшафта и художественных аспектов.</p>                
                    </div>
                    <br><br>
                    <div class="row">
                        <div class="column item">
                            <img class="img" src="/assets/architecture/hitec.jpg">
                            <div class="row row-inner">
                                <img class="icon" src="/assets/icons/house.svg" width="60" height="60" data-aos="fade-up">
                                <div class="column">
                                    <h2>Стиль Райта</h2>
                                    <p>Изящество декоративных элементов, просторная планировка</p>
                                </div>
                            </div>
                        </div>
                        <div class="column item">
                            <img class="img" src="/assets/architecture/raita.jpg">
                            <div class="row row-inner">
                                <img class="icon" src="/assets/icons/plan.svg" width="60" height="60" data-aos="fade-up">
                                <div class="column">
                                    <h2>Хайтек</h2>
                                    <p>Простые линии, но выдержанный современный стиль</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br><br>
                    <div class="row text">
                        <div class="column">
                            <h4 data-aos="fade-up">Каждый дом готов для жизни</h4>
                            <p>Каждый дом готов для жизни с максимально комфортной планировкой!
                               <br>
                               Независимо от стиля застройки - ко всем домам подведены скрытые под землей коммуникации (газ, электричество вода центральная канализация, телекоммуникации), проложены дороги. Их стоимость уже включена в цену - никаких переплат. </p>
                        </div>
                    </div>

                </div>
            </div>
            <br>
            <div class="row">
                <img class="last-icons" data-aos="fade-up" src="/assets/icons/gas.svg" alt="">
                <img class="last-icons" data-aos="fade-up" src="/assets/icons/electricity.svg" alt="">
                <img class="last-icons" data-aos="fade-up" src="/assets/icons/water.svg" alt="">
                <img class="last-icons" data-aos="fade-up" src="/assets/icons/sewer.svg" alt="">
                <img class="last-icons" data-aos="fade-up" src="/assets/icons/wifi.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Architecture',
    }
</script>

<style lang="scss" scoped>
    .arch-all {
        background: rgba(14, 10, 6, 0.99);
        color: white;
        
        .spacer {
            max-width: 1312px;
            margin: auto;
            padding: 80px 20px;

            h1 {
                font-size: 120px;
                margin: 0 0 80px 0;
                word-break: break-word;
            }

            @media only screen and (max-width: 900px) {
                h1 {
                    font-size: 80px !important;
                    margin: 0 0 60px 0;
                }
            }

            @media only screen and (max-width: 650px) {
                h1 {
                    font-size: 45px !important;
                    margin: 0 0 20px 0;
                }
            }


            .text {
                h4 {
                    font-size: 40px;
                    font-weight: 500;
                    margin: 0 0 30px 0;
                }

                @media only screen and (max-width: 650px) {
                    h4 {
                        font-size: 25px !important;
                    }
                }
                
                p {
                    color: rgb(168, 168, 168);
                    font-size: 16px;
                    margin-bottom: 0;
                }

                h3 {
                    margin-top: 40px;
                    margin-bottom: 20px;
                    font-size: 24px;
                }
            } 

            .item {
                .img {
                    width: 100%;
                    height: auto;
                }

                .icon {
                    padding-bottom: 6px;
                    margin-right: 20px;
                }

                h2 {
                    color: rgb(237, 154, 37);
                    font-size: 24px;
                    font-weight: normal;
                    padding: 0;
                    margin: 0;
                    padding-bottom: 12px;
                }

                p {
                    padding: 0;
                    margin: 0;
                    // margin-right: 13px;
                }
            }
        }

        .last-icons {
            height: 50px;
            width: 50px;
            margin-right: 20px;
        }

        .row {
            display: flex;

            .column {

                margin-right: 20px;
                flex: 1;

                &:last-child {
                    margin-right: 0;
                }
            }

            .column2 {
                flex: 2;
            }
        }

        .row.right {
            justify-content: right;
        }

        .row-inner {
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 750px) {
        .row {
            display: block !important;
            .column {
                margin-top: 24px;
                margin-right: 0 !important;
            }
        }

        .row-inner {
            display: flex !important;

            .column {
                margin-top: 0 !important;
            }
        }
    }

</style>