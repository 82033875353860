export default {
  'comfort-write-new': {
    order: 1,
    label: 'Комфорт. Райт 3 очередь',
    type: 'comfort-write-new',
    color: '#55bb01',
    img: [
      '9/1.jpeg',
      '9/1.jpg',
      '9/2.jpg',
      '9/3.jpg',
      '9/4.jpg',
    ],
    info: [
      'Общая площадь — 95,00 м<sup>2</sup>',
      'Этажность — 1',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 12 700 000 ₽',
    ],
    infoInner: [
    ]
    },
/*   'earth': {
      order: 1,
      label: 'Участок от 600 кв.м.',
      type: 'earth',
      color: '#55bb01',
      img: [
        '13/1.jpg',
      ],
      info: [
        'Общая площадь — 600,00 м<sup>2</sup>',
        'Доступ ко всем коммуникациям',
        'Доступ ко всей инраструктуре',
        'Стоимость — от 7 500 000 ₽',
      ],
      infoInner: [
        { name: 'Вы очень просили? И мы сделали!'},
        { name: 'Эксклюзивное предложение: участок за 7 500 000 рублей'},
        { name: 'с доступом ко всем коммуникациям и инфраструктуре'},
        { name: 'Всего 15 участков от 600 м²'},
        { name: 'Построй дом мечты с поддержкой на каждом этапе:покупка, проектирование, строительство'},
        { name: 'Акционная цена до 31.12.2024.'},
      ]
      }, */
  'comfort-wright-style': {
    order: 12,
    label: 'Комфорт. Стиль Райта',
    type: 'comfort-wright-style',
    color: '#55bb01',
    visible: false,
    info: [
      'Общая площадь — 140 м<sup>2</sup>',
      'Этажность — 1',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 16 800 000 ₽',
    ],
    img: [
      '1/6.jpg',
      '1/7.jpg',
      '1/8.jpg',
      '1/9.jpg',
      '1/1.jpg',
      '1/2.jpg',
      '1/3.jpg',
      '1/4.jpg',
      '1/5.jpg',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Помещение свободной планировки', value:	'41,21'},
      { name: '2. С/у', value:	'2,18'},
      { name: '3. Жилая комната', value:	'19,19'},
      { name: '4. Жилая комната', value:	'12,56'},
      { name: '5. Жилая комната', value:	'14,57'},
      { name: '6. Ванная комната', value:	'6,3'},
      { name: '7. Кухня', value:	'6,48'},
      { name: '8. Терраса', value:	'37,51'},
      { name: '', value: '', empty: true },
      { name: '1 этаж', value: '140' },
      { name: 'Общая площадь', value: '140' },
    ],
  },
  'expencive-comfort-wright-style': {
    order: 7,
    label: 'Комфорт. Стиль Райта с гаражом на 2 машины',
    type: 'expencive-comfort-wright-style',
    color: '#013bdb',
    info: [
      'Общая площадь — 140,0 м<sup>2</sup>',
      'Этажность — 1',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 18 800 000 ₽',
    ],
    img: [
      '7/6.jpg',
      '7/7.jpg',
      '7/8.jpg',
      '7/9.jpg',
      '7/1.jpg',
      '7/2.jpg',
      '7/3.jpg',
      '7/4.jpg',
      '7/5.jpg',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Помещение свободной планировки', value:	'41,21'},
      { name: '2. С/у', value:	'2,18'},
      { name: '3. Жилая комната', value:	'19,19'},
      { name: '4. Жилая комната', value:	'12,56'},
      { name: '5. Жилая комната', value:	'14,57'},
      { name: '6. Ванная комната', value:	'6,3'},
      { name: '7. Кухня', value:	'6,48'},
      { name: '8. Терраса', value:	'37.51'},
      { name: '', value: '', empty: true },
      { name: '1 этаж', value: '140,0' },
      { name: 'Гараж 2 машиноместа', value: 'от 30 до 40' },
      { name: 'Общая площадь', value: '140,0' },
    ],
  },
  'business-wright-style': {
    order: 2,
    label: 'Бизнес. Стиль Райта',
    type: 'business-wright-style',
    color: '#DB9501',
    img: [
      '2/7.jpg',
      '2/8.jpg',
      '2/9.jpg',
      '2/10.jpg',
      '2/1.jpg',
      '2/2.jpg',
      '2/3.jpg',
      '2/4.jpg',
      '2/5.jpg',
      '2/6.jpg',
    ],
    info: [
      'Общая площадь — 241,0 м<sup>2</sup>',
      'Этажность — 2',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 21 000 000 ₽',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Прихожая',	value: '5,99'},
      { name: '2. Лестничная клетка 1 этаж',	value: '7,31'},
      { name: '3. Жилая комната',	value: '17,63'},
      { name: '4. Терраса', value: '28,5'},
      { name: '5. Коридор', value: '10,39'},
      { name: '6. Жилая комната', value: '12,56'},
      { name: '7. Жилая комната', value: '14,57'},
      { name: '8. Ванная комната', value: '6,3'},
      { name: '9. Кухня', value: '6,48'},
      { name: '10. Гостиная', value: '22,24'},
      { name: '11. Лестничная клетка 0 этаж', value: '7,88'},
      { name: '12. Помещение свободной планировки', value: '97,15'},
      { name: '13. С/у', value: '4'},
      { name: '', value: '', empty: true},
      { name: '1 этаж', value: '131,97'},
      { name: '0 этаж', value: '109,03'},
      { name: 'Общая площадь', value: '241,0'},
    ],
  },
  'expencive-business-wright-style': {
    order: 8,
    label: 'Бизнес. Стиль Райта с гаражом на 2 машины',
    type: 'expencive-business-wright-style',
    color: '#b301db',
    img: [
      '8/7.jpg',
      '8/1.jpg',
      '8/2.jpg',
      '8/3.jpg',
      '8/4.jpg',
      '8/5.jpg',
      '8/6.jpg',
    ],
    info: [
      'Общая площадь — 256,5 м<sup>2</sup>',
      'Этажность — 2',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 22 200 000 ₽',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Прихожая',	value: '5,99'},
      { name: '2. Лестничная клетка 1 этаж',	value: '7,31'},
      { name: '3. Жилая комната',	value: '17,63'},
      { name: '4. Терраса', value: '35,5'},
      { name: '5. Коридор', value: '10,39'},
      { name: '6. Жилая комната', value: '12,56'},
      { name: '7. Жилая комната', value: '14,57'},
      { name: '8. Ванная комната', value: '6,3'},
      { name: '9. Кухня', value: '6,48'},
      { name: '10. Гостиная', value: '22,24'},
      { name: '11. Лестничная клетка 0 этаж', value: '7,88'},
      { name: '12. Помещение свободной планировки', value: '97,15'},
      { name: '13. С/у', value: '4'},
      { name: '', value: '', empty: true},
      { name: '1 этаж', value: '140,70'},
      { name: '0 этаж', value: '109,03'},
      { name: 'Гараж 2 машиноместа', value: 'от 30' },
      { name: 'Общая площадь', value: '256,5'},
    ],
  },
  'premium-wright-style': {
    order: 3,
    label: 'Премиум. Стиль Райта',
    type: 'premium-wright-style',
    color: '#d22623',
    img: [
      '3/6.jpg',
      '3/7.jpg',
      '3/8.jpg',
      '3/9.jpg',
      '3/1.jpg',
      '3/2.jpg',
      '3/3.jpg',
      '3/4.jpg',
      '3/5.jpg',

    ],
    info: [
      'Общая площадь — 205 м<sup>2</sup>',
      'Этажность — 2',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 22 500 000 ₽',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Кухня', value: '12,7' },
      { name: '2. С/у', value: '5,89' },
      { name: '3. Техническое помещение', value: '3,47' },
      { name: '4. Кладовая', value: '4,41' },
      { name: '5. Гостиная', value: '30,54' },
      { name: '6. Жилая комната', value: '13,89' },
      { name: '7. Кладовая', value: '2,61' },
      { name: '8. Коридор', value: '9,63' },
      { name: '9. Терраса', value: '32,04' },
      { name: '10. Жилая комната', value: '20,27' },
      { name: '11. Жилая комната', value: '19,15' },
      { name: '12. С/у', value: '6,55' },
      { name: '13. Прихожая', value: '5,51' },
      { name: '14. Жилая комната', value: '13,61' },
      { name: '15. Коридор', value: '11,86' },
      { name: '16. Лестничная клетка', value: '8,6' },
      { name: '', value: '', empty: true},
      { name: '1 этаж', value: '115,43'},
      { name: '2 этаж', value: '85,55'},
      { name: 'Общая площадь', value: '201'},
    ],
  },
  /* 'comfort-hitech': {
    order: 4,
    label: 'Комфорт. Хайтек',
    type: 'comfort-hitech',
    color: '#55bb01',
    img: [
      '4/1.jpg',
      '4/2.jpg',
      '4/3.jpg',
      '4/4.jpg',
      '4/5.jpg',
    ],
    info: [
      'Общая площадь — 140,00 м<sup>2</sup>',
      'Этажность — 1',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 16 800 000 ₽',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Гостиная', value: '41,21'},
      { name: '2. С/у', value: '2,18'},
      { name: '3. Жилая комната', value: '19,19'},
      { name: '4. Жилая комната', value: '12,56'},
      { name: '5. Жилая комната', value: '14,57'},
      { name: '6. Ванная комната', value: '6,3'},
      { name: '7. Кухня', value: '6,48'},
      { name: '8. Терраса', value: '37,51'},
      { name: '', value: '', empty: true},
      { name: '1 этаж', value: '140,0'},
      { name: 'Общая площадь', value: '140,00'},
    ]
    }, */
  'premium-hitech': {
    order: 6,
    label: 'Премиум. Хайтек',
    type: 'premium-hitech',
    color: '#d22623',
    img: [
      '6/1.jpg',
      '6/2.jpg',
      '6/3.jpg',
      '6/4.jpg',
      '6/5.jpg',
    ],
    info: [
      'Общая площадь — 214,0 м<sup>2</sup>',
      'Этажность — 2',
      'Площадь участка — 600 м<sup>2</sup>',
      'Стоимость — от 25 500 000 ₽',
    ],
    infoInner: [
      { name: 'Эскизные планировки'},
      { name: '1. Кухня', value: '12,93'},
      { name: '2. С/у', value: '5,28'},
      { name: '3. Техническое помещение', value: '3,57'},
      { name: '4. Кладовая', value: '4,53'},
      { name: '5. Гостиная', value: '29,44'},
      { name: '6. Жилая комната', value: '14,32'},
      { name: '7. Кладовая', value: '3,04'},
      { name: '8. Коридор', value: '9,69'},
      { name: '9. Жилая комната', value: '20,49'},
      { name: '10. Жилая комната', value: '18,18'},
      { name: '11. С/у', value: '5,91'},
      { name: '12. Прихожая', value: '5,21'},
      { name: '13. Жилая комната', value: '14,09'},
      { name: '14. Коридор', value: '11,64'},
      { name: '15. Балкон', value: '13,8'},
      { name: '16. Лестничная клетка', value: '8,6'},
      { name: '17. Терраса', value: '33,28'},
      { name: '', value: '', empty: true},
      { name: '1 этаж', value: '116,08'},
      { name: '0 этаж', value: '97,92'},
      { name: 'Общая площадь', value: '214,0'},
    ],
    },
  'sold': {
    order: 9,
    label: 'Дом продан',
    type: 'sold',
    color: '#757575',
    img: [
      'sold/1.jpg',
    ],
    info: [
      'Дом продан',
    ],
    infoInner: [],
  },
}