<template>
  <modal :show="gallery" @close="$emit('close')">
    <template #title>
      {{ gallery.name }}
    </template>

    <div ref="container" id="container-gallery">
        <lightgallery 
          v-if="container && gallery"
          :settings="{
              plugins: plugins,
              closable: false,
              showMaximizeIcon: true,
              container,
              addClass: 'gallery-test',
              download: false,
              videojs: true,
              videojsOptions: {
                muted: false,
              },
            }"
          :onInit="onInit"
        >
          <template v-for="(item, index) in data"  :key="index + item">
            <a v-if="item.type == 'image'" :href="`/assets/galleries/${item.src}`" class="iii">
              <img :src="`/assets/resized/galleries/${item.src}`" alt=""/>
            </a>
            <a
              v-if="item.type == 'video'"
              :data-src="`//www.youtube.com/watch?v=${item.src}&mute=0`"
              :data-poster="`https://img.youtube.com/vi/${item.src}/maxresdefault.jpg`"
              data-lg-size="1280-720"
              class="iii"
            >
              <img :src="`https://img.youtube.com/vi/${item.src}/maxresdefault.jpg`" alt=""/>
            </a>
          </template>
          <!-- <a
            v-for="(v, index) in video"
            :data-src="`//www.youtube.com/watch?v=${v}&mute=0`"
            :data-poster="`https://img.youtube.com/vi/${v}/maxresdefault.jpg`"
            :key="index + v"
            class="iii"
          >
            <img :src="`https://img.youtube.com/vi/${v}/maxresdefault.jpg`" alt=""/>
          </a> -->

          <!-- <a
              data-lg-size="1280-720"
              data-src="//www.youtube.com/watch?v=egyIeygdS_E&mute=0"
              data-poster="https://img.youtube.com/vi/egyIeygdS_E/maxresdefault.jpg"
          >
              <img
                  width="300"
                  height="100"
                  class="img-responsive"
                  src="https://img.youtube.com/vi/egyIeygdS_E/maxresdefault.jpg"
              />
          </a> -->
        </lightgallery>

    </div>
  </modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import {computed, ref} from "vue";
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

export default {
  name: "GalleriesModal",
  components: {
    Modal,
    Lightgallery,
  },
  emits: ['close'],
  props: {
    gallery: {
      type: null,
      required: true
    }
  },
  setup(props) {
    const container = ref()
    const plugins = [lgThumbnail, lgZoom, lgVideo]
    const data = computed(() => [
      ...props.gallery?.data || [],
    ])

    const onInit = ({instance}) => {
      instance?.openGallery()
    }

    return {
      container,
      plugins,
      data,
      onInit,
    }
  }
}
</script>



<style scoped lang="scss">


    #container-gallery {
        width: 100%;
        height: 100%;
        overflow: hidden;

            // @include tablet_desktop {
            //   width: 60%;
            // }
    }

    // :deep(.lightgallery-vue) {
    //     width: 100%;
    //     padding-bottom: 65%;
    // }


    .iii {
        img {
        max-width: 100px;
        max-height: 100px;
        }
    }

    

    .gallery-test {
        #lg-backdrop-1 {
            background-color: #252729 !important;
        }

        #lg-components-1 {

            .lg-thumb-outer {
                background-color: #252729 !important;
            }

        }
    }

</style>