<template>
    <div class="outer" id="documents">
        <div class="inner">
            <div class="right">
                <h1 data-aos="fade-up">Документы</h1>
            </div>
            <div class="row">
                <a href="/assets/docs/dogovor-kupli-prodaji-zemelnogo-uchastka.docx" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/doc.svg" width="60" height="60" data-aos="fade-up">
                    <span> Договор купли-продажи земельного участка</span>
                </a>
                <a href="/assets/docs/dogovor-stroitelnogo-podrada.docx" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/doc.svg" width="60" height="60" data-aos="fade-up">
                    <span> Договор строительного подряда</span>
                </a>
<!--                 <a href="/assets/docs/merged.pdf" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/pdf.svg" width="60" height="60" data-aos="fade-up">
                    <span> Документы по построенным домам</span>
                </a> -->
            </div>
            <div class="row">
                <a href="/assets/docs/dogovor-na-obslujivaniye-upravlayushey-kompaniey.docx" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/doc.svg" width="60" height="60" data-aos="fade-up">
                    <span> Договор на обслуживание с управляющей компанией</span>
                </a>
                <a href="/assets/docs/Ozerniy_presentation.pdf" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/pdf.svg" width="60" height="60" data-aos="fade-up">
                    <span> Презентация</span>
<!--                 </a>
                <a href="/assets/docs/info.pdf" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/pdf.svg" width="60" height="60" data-aos="fade-up">
                    <span> Подрядчики</span> -->
                </a>
<!--                                 <a href="https://www.rncb.ru/ipoteka/kvartira-v-ipoteku-novostroika/?ONLINE_ANKETA=Y" target="_blank" class="column card document">
                    <img class="icon" src="/assets/icons/rncb.png" width="60" height="60" data-aos="fade-up">
                    <span> Ипотека</span>
                </a> -->
            </div>
            <!-- <div class="row">

                <div class="column"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Documents',
}
</script>


<style lang="scss" scoped>

    .outer {
        background: rgb(37, 39, 41);
        padding: 80px 20px;

        .inner {
            margin-top: 100px;
            max-width: 1312px;
            margin: auto;

            h1 {
                font-size: 120px;
                color: white;
                margin: 0 0 20px 0;                    
                word-break: break-word;
                margin: 0 0 60px 0;
            }

            @media only screen and (max-width: 1200px) {
                h1 {
                    font-size: 80px !important;
                    margin: 0 0 40px 0;
                }
            }

            @media only screen and (max-width: 800px) {
                h1 {
                    font-size: 45px !important;
                    margin: 0 0 20px 0;
                }
            }
        }
    }

    .card {
        background: white;
        font-size: 20px;

        h5 {
            color: #128737;
            font-size: 30px;
            font-weight: 500;
            margin: 10px 0 0 0;
        }
    }

    .document {
        display: flex !important;
        text-decoration: none;

        img {
            margin-right: 30px;
        }

        span {
            text-decoration: none;
            color: black;
            display: inline-block;
            align-self: center;
        }
    }

    .row {
        display: flex;
        margin-bottom: 20px;
        align-items: stretch;

        &:last-child {
            margin-bottom: 20px;
        }

        .column {
            margin-right: 20px;
            flex: 50%;
            padding: 20px 30px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
        

    @media only screen and (max-width: 650px) {
        .row {
            display: block !important;
            margin-bottom: 0 !important;
        }
        .column {
            display: block;
            margin-bottom: 20px !important;
            margin-right: 0 !important;
        }
    }
</style>