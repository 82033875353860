<template>
    <div class="outer" id="main">
        <div class="video-container">
            <video autoplay muted loop id="myVideo" poster="/assets/main/video-poster.jpg">
                <!-- <source src="/assets/main/video-background.mp4" type="video/mp4"> -->
                <source src="/assets/main/video-background.webm" type="video/webm">
            </video>
        </div>
        <div class="content">
            <div class="inner">
                <Popup v-if="showPopup" :TogglePopup="togglePopup">
                    <div class="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/eT8ZQQfmJto" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Popup>
                <br>
                <h3>Коттеджный поселок</h3>
                <h1>Озерный</h1>
                <p>Баланс между спокойной<br>территорией и благами цивилизации<br>в 3 км от Симферополя!</p>
                <div class="other">
                    <div class="phone-div">
                        <!-- <a class="phone" href="tel:+79787267662">+7 978 726 76 62</a> -->
                        <a class="phone" href="tel:+79781233377">+7 978 123 33 77</a>
                    </div>
                    <div class="play" @click="togglePopup">
                        <img src="/assets/play.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-logo">
        <a href="/">
            <img alt="Ozerniy logo" src="/assets/logo.png" width="60" height="48">
        </a>
        <a href="https://vladograd.com" target="_blank" class="volgograd">
            <!-- <img alt="Vladograd logo" src="/assets/vladograd_logo_horizontal.png" height="48">
            <span>Официальный партнер проекта</span> -->
        </a>
    </div>

    <div class="scroll-downs">
        <div class="mousey">
            <div class="scroller"></div>
        </div>
    </div>

    <div class="scroll-downs-phone">
        <div class="phone-phone">
            <div class="scroller-phone"></div>
        </div>
    </div>

</template>

<script>
    import Popup from './Popup.vue'

    export default {
        name: 'Main',
        components: {
            Popup,
        },
        data () {
          return {
            showModal: false,
            showPopup: false,
          }
        },
        methods: {
            toggleModal: function() {
                this.showModal = !this.showModal;
            },
            togglePopup: function() {
                this.showPopup = !this.showPopup;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .main-logo {
        position: absolute;
        top: 0;
        left: 0;
        padding: 6px 24px;
        
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .volgograd {
            display: flex;
            flex-direction: column;
            max-height: 48px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
            &:visited {
                text-decoration: none;
            }
                
            img {
                height: auto;
                width: 138px;
            }
            span {
                color: #bc2727;
                text-decoration: none;
                font-weight: 900;
                font-size: 8px;
            }
        }
    }
    .video-container {
        overflow:hidden;
        display:block;
        min-width: 100vw;
        min-height: 100vh;
        height: 100vh;
        width: 100vw;
    }
    #myVideo {
        min-width: 100%;
        min-height: 100vh;
    }
/* Add some content at the bottom of the video/page */
    .outer {
        position: relative;
    }
    .content {
        position: absolute;
        overflow: hidden;
        display: grid;
        align-content: center;
        min-width: 100%;
        min-height: 100vh;
        height: 100vh;
        width: 100%;
        top: 0;
        background: rgba(0,0,0,0.1);

        .inner {
            max-width: 1312px;
            width: 100%;
            margin: auto;
        }
        

        @media only screen and (max-width: 1312px) {
            .inner {
                padding: 0 24px;
            }
        }
    }

    h3 {
        color: white;
        font-size: 40px;
        font-weight: 500;
        margin: 0;
    }

    p {
        color: white;
        font-size: 20px;
        line-height: 32px;
    }

    h1 {
        color: white;
        font-size: 120px;
        font-weight: 500;
        margin: 0;
    }

    @media only screen and (max-width: 766px) {
        h1 {
            font-size: 72px;
        }
    }

    .other {
        display: flex;
        justify-content: space-between;
        margin: 12px 0;
        flex-direction: row;
        align-items: center;

        .phone-div {
            display: flex;
            flex-direction: column;

            .phone {
                color: #222222;
                background: #d2b281;
                padding: 16px 24px;
                margin: 12px 0;
                display: inline-block;
                text-decoration: none;
                font-size: 16px;
                font-weight: 600;
                line-height: 1;
            }
        }
        

        .play {
            position: absolute;
            top: calc(50% - 60px);
            right: 100px;
            padding-right: 65px;
            display: inline-block;
            transition: opacity 0.2s;
            opacity: 0.5;

            img {
                height: 120px;
                width: 120px;
            }
        }
    }



    .play:hover {
        transition: opacity 0.2s;
        opacity: 1;
    }

    
    .scroll-downs {
        position: absolute;
        bottom: 30px;
        left: calc(50% - 17px);
        margin: 0 auto;
        
        width: 34px;
        height: 55px;
    }
    .mousey {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid #fff;
        border-radius: 25px;
        opacity: 0.75;
        box-sizing: content-box;
    }
    .scroller {
        width: 3px;
        height: 10px;
        border-radius: 25%;
        background-color: #fff;
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(.15,.41,.69,.94);
        animation-iteration-count: infinite;
    }
    @keyframes scroll {
        0% { opacity: 0; }
        10% { transform: translateY(0); opacity: 1; }
        100% { transform: translateY(15px); opacity: 0;}
    }

    
    .scroll-downs-phone {
        display: none;
        position: absolute;
        bottom: 30px;
        left: calc(50% - 17px);
        margin: 0 auto;
        
        width: 34px;
        height: 55px;
    }
    .phone-phone {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid #fff;
        border-radius: 5px;
        opacity: 0.75;
        box-sizing: content-box;
    }
    .scroller-phone {
        left: 9px;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #fff;
        animation-name: scroll-phone;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(.15,.41,.69,.94);
        animation-iteration-count: infinite;
    }
    @keyframes scroll-phone {
        0% { transform: translateY(20px); opacity: 0; }
        10% { transform: translateY(20px); opacity: 1; }
        100% { transform: translateY(0px); opacity: 0;}
    }

    @media only screen and (max-width: 768px) {
        .scroll-downs {
            display: none !important;
        }

        .scroll-downs-phone {
            display: block !important;
        }
    }

    .video-container {
        overflow: hidden;
        position: relative;
        width:  100%;
    }

    .video-container::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 80%;
        height: 80%;
    }
    
    @media only screen and (max-width: 850px) {

        .play {
            position: relative !important;
            right: auto !important;

            img {
                height: 60px !important;
                width: 60px !important;
            }
        }
    }

</style>
